import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Loading from '../../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  DatabaseResponse,
  fetchPDF,
  getDepartments,
  loadDatabase,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import { handleCopy } from '../../../../../_global/common/Utils';
import { set } from 'lodash';
import { ViewportList } from 'react-viewport-list';
import MedicationSubItem from '../../../../../../data/model/MedicationSubItem';
import MedicationItem from '../../../../../../data/model/MedicationItem';
import { DataStore, Storage } from 'aws-amplify';
import { MedicationDose } from '../../../../../../models';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import { convertToNewDatabaseLogic } from '../../../../../../data/AmplifyActions';

type MedicationDoseType = {
  dose: MedicationDose;
  protocol: ProtocolItem;
  medication: MedicationItem;
};
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const MigrateDepartmentAudit = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);

  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    department: undefined as DepartmentItem | undefined,
    database: undefined as DatabaseResponse | undefined,
    list: [] as any[],
  });

  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      if (info.department) {
        let dep = info.department as DepartmentItem;
        if (dep.logoURL) {
          const response = await fetchPDF(dep.logoURL);
          if (response.type === ResponseType.Success) {
            console.log('Logo:', response.data);
            setLogo(response.data);
          }
        }
      }
      setLogo(null);
    };
    if (info.department) {
      loadLogo();
    }
  }, [info.department]);

  const handleBack = () => {
    navigate('/actions');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
    }
  };

  const loadData = async (department: DepartmentItem) => {
    await department.checkParentDep(false);
    let promises = [
      department.checkSubDeps(),
      loadDatabase(department, undefined, true),
    ];
    let [subDeps, response]: any = await Promise.all(promises);
    if (response.type === ResponseType.Success) {
      let db = response.data;
      let list = [];
      if (db.categories) {
        list.push({
          name: 'Categories',
          count: db.categories.length,
          data: db.categories,
        });
      }
      if (db.protocols) {
        list.push({
          name: 'Protocols',
          count: db.protocols.length,
          data: db.protocols,
        });
      }
      if (db.medications) {
        list.push({
          name: 'Medications',
          count: db.medications.length,
          data: db.medications,
        });
      }
      if (db.infusions) {
        list.push({
          name: 'Infusions',
          count: db.infusions.length,
          data: db.infusions,
        });
      }
      if (db.electrical) {
        list.push({
          name: 'Electrical',
          count: db.electrical.length,
          data: db.electrical,
        });
      }
      if (db.equipment) {
        list.push({
          name: 'Equipment',
          count: db.equipment.length,
          data: db.equipment,
        });
      }
      if (db.vitals) {
        list.push({
          name: 'Vitals',
          count: db.vitals.length,
          data: db.vitals,
        });
      }
      if (db.weightObjects) {
        list.push({
          name: 'Weight Objects',
          count: db.weightObjects.length,
          data: db.weightObjects,
        });
      }
      if (db.keychains) {
        list.push({
          name: 'Keychains',
          count: db.keychains.length,
          data: db.keychains,
        });
      }
      if (db.medcationDoses) {
        list.push({
          name: 'Medication Doses',
          count: db.medcationDoses.length,
          data: db.medcationDoses,
        });
      }
      if (db.infusionDoses) {
        list.push({
          name: 'Infusion Doses',
          count: db.infusionDoses.length,
          data: db.infusionDoses,
        });
      }
      if (db.electricalDoses) {
        list.push({
          name: 'Electrical Doses',
          count: db.electricalDoses.length,
          data: db.electricalDoses,
        });
      }
      if (db.contacts) {
        list.push({
          name: 'Contacts',
          count: db.contacts.length,
          data: db.contacts,
        });
      }
      if (db.logs) {
        list.push({
          name: 'Logs',
          count: db.logs.length,
          data: db.logs,
        });
      }
      if (db.checklists) {
        list.push({
          name: 'Checklists',
          count: db.checklists.length,
          data: db.checklists,
        });
      }

      setInfo({
        department: department,
        database: db,
        list: list,
      });
    }
    setIsLoading(null);
  };

  const handlePerformAudit = async () => {
    console.log('Perming Audit');
    console.log('Sub Deps', (info.department as DepartmentItem).subDeps);
    convertToNewDatabaseLogic(info.department)
      .then((response) => {
        console.log('Response:', response);
        if (response.type === ResponseType.Success) {
          let [
            medResp,
            infusResp,
            elecResp,
            concenResp,
            audittedIDs,
            audittedDepIDs,
          ] = response.data;
          let medDoses = medResp.data;
          setModalState({
            isVisible: true,
            title: 'Successfully Created Medication Doses',
            primaryBtnName: 'Okay',
            secondaryBtnName: 'Okay',
            primaryDescription:
              'Successfully created ' +
              medDoses.length +
              ' Medication Doses, ' +
              infusResp.data.length +
              ' Infusion Doses, ' +
              elecResp.data.length +
              ' Eletrical Shocks, ' +
              concenResp.data.length +
              ' Concentrations. Auditted ' +
              audittedIDs.data +
              ' Treatment References and ' +
              audittedDepIDs.data +
              ' User Privledges ' +
              ' for ' +
              info.department?.name,
          });
          // setInfo({
          //   ...info,
          //   newStyleMeds: medDoses,
          // });
          loadData(info.department);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isSaveValid = useMemo(() => {
    return info.department !== undefined && info.database !== undefined;
  }, [info]);

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          if (modalState.title === 'Audit Required') {
            setModalState({ ...modalState, isVisible: false });
          } else {
            setModalState({ ...modalState, isVisible: false });
            navigate('/database');
          }
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={'Audit/Migrate Department to the new Database Structure'}
        description={
          'This will loop through every Medication, Infuson, Electrical, and etc. and confirm they are in the new database structure'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Run"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit();
        }}
      />
      <label className="ketamine-general-label">
        Department: <span className="required-field">*</span>
      </label>
      <p className="sidebarText" style={{ marginLeft: '10px' }}>
        Select the department you want to perform the audit on.
      </p>
      <SearchableDropdown<DepartmentItem>
        id="searchDropdown"
        options={departments}
        labelField={(option) => option.name}
        valueField={(option) => option.name}
        keyField={(option) => option.id}
        onChange={(option: DepartmentItem) => {
          setIsLoading('Loading ' + option.name + ' Data...');
          loadData(option);
        }}
        onClear={() => {
          setInfo({
            department: undefined,
            database: undefined,
          });
        }}
        placeholder="Search department..."
      />

      {info.database && info.department && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {logo && (
              <img className="large-dep-logo" src={logo} alt="Agency Logo" />
            )}
            {info.department && (
              <div
                className="large-dep-name"
                style={{
                  marginLeft: '2rem',
                  marginRight: '6px',
                }}
              >
                {info.department.name}
              </div>
            )}
          </div>
          <div
            className="input-container roundBorder "
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
          >
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>ID:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.department.id}
                <span>
                  {isCopied && isCopied === info.department.id ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(info.department.id, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className="input-container roundBorder "
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '50%',
              }}
            >
              <h4
                style={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Database Items:{' '}
                <span style={{ color: '#636363' }}>{info.list.length}</span>
              </h4>
              <ViewportList items={info.list}>
                {(item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {item.name}
                      </div>
                      <div>{item.count}</div>
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MigrateDepartmentAudit;
