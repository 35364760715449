import { API, graphqlOperation } from 'aws-amplify';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  executeQuery,
  executeSingleQuery,
  findDepartmentLogo,
  mapItems,
  mapModelItems,
} from './AmplifyDB';
import {
  categoriesByDepartmentID,
  dripsByDepartmentID,
  electricalShocksByDepartmentID,
  equipmentByDepartmentID,
  getAmbulance,
  getCategory,
  getContact,
  getDrip,
  getElectrical,
  getElectricalShock,
  getEquipment,
  getForm,
  getInputForm,
  getKeychain,
  getMedication,
  getMedicShift,
  getPatientInteraction,
  getProtocol,
  getUser,
  getVitals,
  getWeightObject,
  keychainsByDepartmentID,
  listCategories,
  medicationsByDepartmentID,
  protocolsByCategoryID,
  protocolsByDepartmentID,
  usersByCognitoID,
  vitalsByDepartmentID,
} from '../graphql/queries';
import {
  Medication,
  UserType,
  Drip,
  Electrical,
  Category,
  Department,
  Keychain,
  Protocol,
  User,
  Equipment,
  CreateUserInput,
} from '../API';
import { getHashedPin, getSalt } from '../ui/_global/common/Encrypt';
import { createUser } from '../graphql/mutations';
import DepartmentItem from './model/DepartmentItem';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import { globals } from '../ui/_global/common/Utils';
import KeychainItem from './model/KeychainItem';
import MedicationItem from './model/MedicationItem';
import InfusionItem from './model/InfusionItem';
import ElectricalItem from './model/ElectricalItem';
import EquipmentItem from './model/EquipmentItem';
import ModelItem from './model/ModelItem';
import VitalItem from './model/VitalItem';
import {
  departmentsByUniquePublicURL,
  getDepartment,
  listDepartments,
} from './QueryTypes';

export const fetchDepartmentByName = async (
  departmentName: string,
  lazyLoadCallback?: () => void
): Promise<Response> => {
  try {
    let urlName = departmentName.toLowerCase().trim();
    let department: Department | null = await executeSingleQuery(
      departmentsByUniquePublicURL,
      {
        uniquePublicURL: urlName,
      }
    );

    if (department == null)
      return { type: ResponseType.Failure, data: 'Department not found' };

    if (!department.isPublic) {
      return {
        type: ResponseType.Failure,
        data: 'Department is not public.',
      };
    }

    let dep = new DepartmentItem(department as any);
    await dep.checkParentDep();

    findDepartmentLogo(dep).then((logoResult) => {
      if (logoResult.type === ResponseType.Success)
        dep.logoVerifiedUrl = logoResult.data;
      else console.error('Error fetching department logo:', logoResult.data);
    });

    if (dep.keychainID) {
      const keychainData: any = await executeSingleQuery(getKeychain, {
        id: department.keychainID,
      });
      let keychain = new KeychainItem(keychainData);
      dep.keychain = keychain;
      if (!keychain.isUnlocked) {
        return {
          type: ResponseType.Success,
          data: {
            department: dep,
            keychain: keychain,
          },
        };
      }
    }

    /* Get the sub departments & the last selected department */
    await dep.checkSubDeps(
      0,
      false,
      1,
      (deps: DepartmentItem[], isCompleted: boolean) => {
        lazyLoadCallback && lazyLoadCallback();
      }
    );
    // dep.checkSubDeps().then((deps) => {
    //   dep.subDeps = deps;
    //   dep.allSubDeps = dep.subDeps;
    // });
    // let deps = allDepartments
    //   .filter((d) => d.parentDepID === dep.id)
    //   .map((d) => new DepartmentItem(d as any));
    // for (let i = 0; i < deps.length; i++) {
    //   let depItem = deps[i];
    //   let logoResult = await findDepartmentLogo(depItem);
    //   if (logoResult.type === ResponseType.Success) {
    //     depItem.logoVerifiedUrl = logoResult.data;
    //   }
    // }
    // dep.subDeps = deps;
    // dep.allSubDeps = dep.subDeps;

    // for (let i = 0; i < allDepartments.length; i++) {
    //   let subDep = allDepartments.find((d) => d.id === dep.subDepIDs[i]);
    //   if (subDep && subDep.activeStatus === true && subDep.isPublic) {
    //     let subDepartment = new DepartmentItem(subDep as any);
    //     dep.addSubDep(subDepartment);
    // let logoResult = await findDepartmentLogo(subDepartment);
    // if (logoResult.type === ResponseType.Success) {
    //   subDepartment.logoVerifiedUrl = logoResult.data;
    // }
    //   }
    // }
    let id = localStorage.getItem('lastSelectedDepID');
    if (id && dep.subDeps && dep.subDeps?.find((d) => d.id === id)) {
      let lastSelectedDep = dep.subDeps?.find((d) => d.id === id);
      dep = lastSelectedDep ? lastSelectedDep : dep;
    }

    let topDepartment = dep.getTopLevelDep();
    if (
      !dep.isTopEnabled &&
      dep.subDeps &&
      dep.subDeps.length > 0 &&
      dep.id === topDepartment.id
    ) {
      dep = dep.subDeps[0];
    }

    let response = await loadPublicDepartmentDatabase(dep);
    if (response.type === ResponseType.Failure) return response;

    return {
      type: ResponseType.Success,
      data: {
        db: response.data,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Fetches all departments and the codes for each department.
 * @returns A list of all departments in the database and there respective codes.
 */
export const fetchDepartments = async () // isParentOnly: boolean = false
: Promise<Response> => {
  try {
    /* Make an API call to get all departments. Without being authenticated yet */
    let filter: any = {
      and: {
        _deleted: { ne: true },
        activeStatus: { ne: false },
      },
    };
    // if (isParentOnly)
    //   filter = {
    //     ...filter,
    //     parentDepID: { attributeExists: false },
    //   };

    const departmentsData: any = await executeQuery(
      listDepartments,
      {
        filter: filter,
      },
      undefined,
      true,
      undefined,
      false,
      false
    ).catch((error) => {
      console.error(
        'Error fetching departments ( fetchDepartments )  :',
        error
      );
    });
    let departments: Department[] = departmentsData;

    /* Make sure every department is a parent department. */
    let departmentList: any = [];
    for (let i = 0; i < departments.length; i++) {
      /* First check if the department is a parent department. */
      let ids = departments[i].subDepIDs
        ? (departments[i].subDepIDs as string[])
        : [];
      if (ids.length > 0) {
        /* If is a sub departmet filter out any of its sub departments. */
        for (let j = 0; j < ids.length; j++) {
          if (ids[j] == null || ids[j] === '') continue;
          let id = ids[j] as string;
          departmentList = departmentList.filter(
            (dep: Department) => dep.id !== id
          );
        }

        /* Add the parent department to the list. */
        departmentList.push(departments[i]);
      } else {
        /* If it is not a parent department, check if it is a sub department. */
        let id = departments[i].id;
        let found = false;
        for (let j = 0; j < departmentList.length; j++) {
          if (departmentList[j].subDepIDs?.includes(id)) {
            found = true;
            break;
          }
        }
        if (!found) departmentList.push(departments[i]);
      }
    }
    departmentList.sort((a: Department, b: Department) =>
      a.name.localeCompare(b.name)
    );
    departments.sort((a: Department, b: Department) =>
      a.name.localeCompare(b.name)
    );
    return {
      type: ResponseType.Success,
      data: [departmentList, departments], //, codes]
    };
  } catch (error) {
    console.error('Error fetching departments ( fetchDepartments )  :', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const loadPublicDepartmentDatabase = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    if (globals.debug)
      console.log('Parent Department:', department.name, department.id);

    let promises: any[] = [];
    promises.push(fetchCategoriesAndProtocols_v2(department, 'ACTIVE'));
    promises.push(fetchKeychains(department));
    let [catResp, keychainResp] = await Promise.all(promises);
    if (catResp.type === ResponseType.Failure) return catResp;
    if (keychainResp.type === ResponseType.Failure) return keychainResp;

    let db: DatabaseResponse = {
      department: department,
      cprModel: undefined,
      categories: catResp.data.categories,
      protocols: catResp.data.protocols,
      ambulances: [],
      users: [],
      oneWeights: [],
      medications: [],
      medicationDoses: [],
      infusions: [],
      infusionDoses: [],
      equipment: [],
      electrical: [],
      electricalDoses: [],
      checklists: [],
      vitals: [],
      logs: [],
      notifications: [],
      contacts: [],
      weightObjects: [],
      keychains: keychainResp.data,
      groups: [],
    };

    /* Map the protocols to the keychains */
    for (let i = 0; i < db.keychains.length; i++) {
      let keychain = db.keychains[i];
      let protocols: ProtocolItem[] = db.protocols.filter(
        (p: ProtocolItem) => p.keychainID === keychain.uid
      );
      let categories: CategoryItem[] = db.categories.filter(
        (c: CategoryItem) => c.keychainID === keychain.uid
      );
      keychain.categories = categories;
      keychain.protocols = protocols;
    }

    if (globals.debug) {
      console.log('Loaded Public Database:', department.name);
      console.log('Categories:', db.categories);
      console.log('Protocols:', db.protocols.length);
      console.log('Keychains:', db.keychains.length);
    }

    mapItems(db, [], [], []); //TODO MAP MED DOSES

    return {
      type: ResponseType.Success,
      data: db,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchCategoriesAndProtocols_v2 = async (
  department: DepartmentItem,
  _status: string = 'ACTIVE',
  fetchAll: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [department.id];
    if (department.parentDep) depIDs.push(department.parentDep.id);
    if (department.parentDep?.parentDep)
      depIDs.push(department.parentDep.parentDep.id);
    if (globals.debug) console.log('Department IDs:', depIDs);

    let categoryList: Category[] = [];
    let categoryPromises: Promise<any>[] = [];
    for (let departmentID of depIDs) {
      let filter: any = {
        and: [
          {
            or: [
              /* Did not need the departmentID because it is already filtered out in the query */
              {
                isRestrictive: { eq: false },
              },
              {
                and: [
                  {
                    isRestrictive: { eq: true },
                  },
                  {
                    pairedDepIDs: { contains: department.id },
                  },
                ],
              },
            ],
          },
          {
            status: { eq: _status ? _status : 'ACTIVE' },
            _deleted: { ne: true },
            isPublic: { eq: true },
          },
        ],
      };
      try {
        categoryPromises.push(
          executeQuery(
            categoriesByDepartmentID,
            {
              departmentID: departmentID,
              filter: filter,
              limit: 500,
            },
            globals.maxDatabaseDelayMS
          )
        );
        let results: any = await Promise.all(categoryPromises);
        for (let i = 0; i < results.length; i++)
          categoryList = categoryList.concat(results[i]);
      } catch (error) {
        console.log(error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      }
    }
    // let filter: any = {
    //   and: {
    //     status: { eq: _status ? _status : 'ACTIVE' },
    //     _deleted: { ne: true },
    //     isPublic: { eq: true },
    //   },
    // };

    // if (department.activeSubDep != null) {
    //   filter = {
    //     and: {
    //       pairedDepIDs: { contains: department.activeSubDep.id },
    //       _deleted: { ne: true },
    //       status: { eq: _status ? _status : 'ACTIVE' },
    //       isPublic: { eq: true },
    //     },
    //   };
    // }

    let promises = [];
    let categories: CategoryItem[] = [];
    for (let i = 0; i < categoryList.length; i++) {
      let data: any = categoryList[i];
      let category = new CategoryItem(data);
      mapModelItems(category, categories, category.status, department);
      // categories.push(category);
      promises.push(fetchProtocols_v2(category, department, _status, true));
    }

    let protocolResponses = await Promise.all(promises);
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolResponses.length; i++) {
      let data = protocolResponses[i].data as ProtocolItem[];
      // categories[i].protocols = data;
      protocols = protocols.concat(data);
    }

    /* Make an API call to get all departments. Without being authenticated yet */
    // let categories: CategoryItem[] = [];
    // let protocols: ProtocolItem[] = [];
    // for (let i = 0; i < categoryList.length; i++) {
    //   let data: any = categoryList[i];
    //   let category = new CategoryItem(data);

    //   if (category.status === 'DRAFT') {
    //     /* Take out the active version if there is one */
    //     if (category.activeID !== null)
    //       categories = categories.filter(
    //         (c: CategoryItem) => c.uid !== category.activeID
    //       );
    //   } else if (category.status === 'ACTIVE') {
    //     /* Make sure the draft version is not in the list */
    //     let isAlreadyDraft = categories.find(
    //       (c: CategoryItem) => c.activeID === category.uid
    //     );
    //     if (isAlreadyDraft) continue;
    //   }

    //   categories.push(category);

    //   /* Get the protocols for the category */
    //   let prots: Protocol[] = data.Protocols.items;
    //   let catProtocols: ProtocolItem[] = [];
    //   for (let j = 0; j < prots.length; j++) {
    //     let protocol = new ProtocolItem(prots[j], category);
    //     if (protocol.status === 'DRAFT') {
    //       /* Take out the active version if there is one */
    //       if (protocol.activeID != null)
    //         categories = categories.filter(
    //           (c: CategoryItem) => c.uid !== protocol.activeID
    //         );
    //     } else if (protocol.status === 'ACTIVE') {
    //       /* Make sure the draft version is not in the list */
    //       let isAlreadyDraft = categories.find(
    //         (c: CategoryItem) => c.activeID === protocol.uid
    //       );
    //       if (isAlreadyDraft) continue;
    //     }

    //     catProtocols.push(protocol);
    //   }
    //   catProtocols.sort((a: ProtocolItem, b: ProtocolItem) => {
    //     if (a.index === b.index) return a.name.localeCompare(b.name);
    //     return a.index - b.index;
    //   });
    //   category.protocols = catProtocols;
    //   protocols = protocols.concat(catProtocols);
    // }

    categories.sort((a: CategoryItem, b: CategoryItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.parent.index === b.parent.index)
        return a.name.localeCompare(b.name);
      return a.parent.index - b.parent.index;
    });

    return {
      type: ResponseType.Success,
      data: {
        categories: categories,
        protocols: protocols,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchProtocols_v2 = async (
  category: CategoryItem,
  department: DepartmentItem,
  _status: string = 'ACTIVE',
  isFetchAll: boolean = false
): Promise<Response> => {
  try {
    /* Make an API call to get all departments. Without being authenticated yet */
    let depIDs = [department.id];
    if (department.parentDep) depIDs.push(department.parentDep.id);
    if (department.parentDep?.parentDep)
      depIDs.push(department.parentDep.parentDep.id);
    if (globals.debug) console.log('Department IDs:', depIDs);

    let protocolList: Protocol[] = [];
    let protocolPromises: Promise<any>[] = [];
    for (let departmentID of depIDs) {
      let filter: any = {
        and: [
          {
            or: [
              {
                and: [
                  {
                    departmentID: { eq: departmentID },
                  },
                  {
                    isRestrictive: { eq: false },
                  },
                ],
              },
              {
                and: [
                  {
                    isRestrictive: { eq: true },
                  },
                  {
                    pairedDepIDs: { contains: department.id },
                  },
                ],
              },
            ],
          },
          {
            status: { eq: _status ? _status : 'ACTIVE' },
            _deleted: { ne: true },
            isPublic: { eq: true },
          },
        ],
      };
      try {
        protocolPromises.push(
          executeQuery(
            protocolsByCategoryID,
            {
              categoryID: category.uid,
              filter: filter,
              limit: 500,
            },
            globals.maxDatabaseDelayMS
          )
        );
        let results: any = await Promise.all(protocolPromises);
        for (let i = 0; i < results.length; i++)
          protocolList = protocolList.concat(results[i]);
      } catch (error) {
        console.log(error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      }
    }

    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolList.length; i++) {
      let data: any = protocolList[i];
      let protocol = new ProtocolItem(data, category);
      mapModelItems(protocol, protocols, protocol.status, department);
    }

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    category.setProtocols(protocols);

    return {
      type: ResponseType.Success,
      data: protocols,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchKeychains = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let keychains: KeychainItem[] = [];
    const keychainData: any = await executeQuery(keychainsByDepartmentID, {
      departmentID: department.id,
      filter: {
        _deleted: { ne: true },
      },
    });
    let keychainList: Keychain[] = keychainData;

    for (let i = 0; i < keychainList.length; i++)
      keychains.push(new KeychainItem(keychainList[i] as any));

    return {
      type: ResponseType.Success,
      data: keychains,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchItemFromAPI = async (
  department: DepartmentItem,
  type: 'Medications' | 'Drips' | 'Electrical' | 'Equipment' | 'Vitals',
  overrideID?: string
): Promise<Response> => {
  try {
    let query: any | undefined = undefined;
    switch (type) {
      case 'Medications':
        query = medicationsByDepartmentID;
        break;
      case 'Drips':
        query = dripsByDepartmentID;
        break;
      case 'Electrical':
        query = electricalShocksByDepartmentID;
        break;
      case 'Equipment':
        query = equipmentByDepartmentID;
        break;
      case 'Vitals':
        query = vitalsByDepartmentID;
        break;
      default:
        return {
          type: ResponseType.Failure,
          data: 'Invalid type',
        };
    }
    let items: ModelItem<any>[] = [];

    const data: any = await executeQuery(
      query,
      {
        departmentID: overrideID ? overrideID : department.id,
        filter: {
          _deleted: { ne: true },
          status: { eq: 'ACTIVE' },
        },
      },
      undefined,
      true,
      undefined,
      undefined,
      false
    );

    let list: any[] = data;

    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      switch (type) {
        case 'Medications':
          items.push(new MedicationItem(item as any));
          break;
        case 'Drips':
          items.push(new InfusionItem(item as any));
          break;
        case 'Electrical':
          items.push(new ElectricalItem(item as any));
          break;
        case 'Equipment':
          items.push(new EquipmentItem(item as any));
          break;
        case 'Vitals':
          items.push(new VitalItem(item as any));
          break;
        default:
          break;
      }
    }

    items.sort((a: ModelItem<any>, b: ModelItem<any>) =>
      a.name.localeCompare(b.name)
    );

    return {
      type: ResponseType.Success,
      data: items,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const validateDepartmentCode = async (
  departmentCode: string,
  code: string,
  departments: Department[]
): Promise<Response> => {
  try {
    const department = departments.find(
      (dept) => dept?.uniqueCode === departmentCode
    );

    if (!department) {
      return { type: ResponseType.Failure, data: 'Department not found' };
    }

    const hashedPin = getHashedPin(code, department.saltedPin);

    if (hashedPin === department.hashedPin) {
      return { type: ResponseType.Success, data: [department] };
    } else {
      return { type: ResponseType.Failure, data: 'Invalid PIN' };
    }
  } catch (error) {
    console.error(error);
    return {
      type: ResponseType.Failure,
      data: error || 'An error occurred',
    };
  }
};

export const getDepartmentCode = async (userID: string): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Make an API call to get all departments. Without being authenticated yet */
      const userData: any = await executeSingleQuery(getUser, {
        id: userID,
      });
      let user = userData;
      if (user == null)
        return resolve({
          type: ResponseType.Failure,
          data: 'User not found',
        });

      return resolve({
        type: ResponseType.Success,
        data: user.hashedPin,
      });
    } catch (error) {
      console.log(error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const createUserGraphQL = async (
  type: UserType,
  subID: string,
  firstName: string,
  lastName: string,
  pin: string,
  department: Department | DepartmentItem,
  pairedDepartments: Department[] | DepartmentItem[],
  depAdmins: DepartmentItem[]
): Promise<Response> => {
  try {
    let saltPin = getSalt();
    let hashedPin = getHashedPin(pin, saltPin);

    let pairedDepIDs: string[] = pairedDepartments.map(
      (d: Department | DepartmentItem) => d.id
    );
    let depAdminIDs: string[] = depAdmins.map((d: DepartmentItem) => d.id);

    let input = {
      firstName: firstName,
      lastName: lastName,
      type: type,
      cognitoID: subID.toLowerCase(),
      departmentID: department.id,
      hashedPin: hashedPin,
      saltPin: saltPin,
      pairedDepIDs: pairedDepIDs,
      oneDoseVersion: '',
      notificationTokens: [],
      status: 'ACTIVE',
      depAdmins: depAdminIDs,
    } as CreateUserInput;
    /* Make an API call to get all departments. Without being authenticated yet */
    const userData: any = await executeSingleQuery(createUser, {
      input: input,
    });

    let user = userData;

    return {
      type: ResponseType.Success,
      data: user,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getUserAndDepartment = async (
  username: string
): Promise<Response> => {
  try {
    /* Make an API call to get the user prior to syncing with the database. */
    const userData: any = await executeSingleQuery(usersByCognitoID, {
      cognitoID: username,
    });
    const user: User = userData;

    if (user == null) {
      return {
        type: ResponseType.Failure,
        data: 'User not found',
      };
    }

    /* Make an API call to get all departments. Without being authenticated yet */
    const departmentData: any = await executeSingleQuery(getDepartment, {
      id: user.departmentID,
    });
    const department = departmentData;

    const dep = new DepartmentItem(department);
    findDepartmentLogo(dep).then((resultLogo) => {
      if (resultLogo.type === ResponseType.Success)
        dep.logoVerifiedUrl = resultLogo.data;
      else console.log('Error fetching department logo:', resultLogo.data);
    });

    if (dep.isMultiDep && user.pairedDepIDs) {
      let promises: any[] = [];
      for (let i = 0; i < user.pairedDepIDs.length; i++) {
        promises.push(
          executeSingleQuery(getDepartment, {
            id: user.pairedDepIDs[i],
          })
        );
      }
      const resp = await Promise.all(promises);
      for (let i = 0; i < resp.length; i++) {
        const subDep = resp[i];
        if (subDep.activeStatus === false) continue;
        const subDepModel = new DepartmentItem(subDep);
        dep.addSubDep(subDepModel);
        if (i === 0) {
          dep.activeSubDep = subDepModel;
          findDepartmentLogo(subDepModel).then((resultLogo) => {
            if (resultLogo.type === ResponseType.Success && dep.activeSubDep)
              dep.activeSubDep.logoVerifiedUrl = resultLogo.data;
            else
              console.log('Error fetching department logo:', resultLogo.data);
          });
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: {
        user: user,
        department: dep,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get an item by its ID from the database
 * @param type ItemType of the item to get
 * @param id ID of the item to get
 * @returns A promise that resolves to a Response object
 */
export const getItemByID = (
  key: string,
  type: string,
  id: string
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let query: any;
      switch (type) {
        case 'Department':
          query = getDepartment;
          break;
        case 'Protocol':
          query = getProtocol;
          break;
        case 'Category':
          query = getCategory;
          break;
        case 'Medication':
          query = getMedication;
          break;
        case 'Drip':
          query = getDrip;
          break;
        case 'Equipment':
          query = getEquipment;
          break;
        case 'Form':
          query = getForm;
          break;
        case 'Electrical':
          query = getElectrical;
          break;
        case 'ElectricalShock':
          query = getElectricalShock;
          break;
        case 'Vitals':
          query = getVitals;
          break;
        case 'Checklist':
          query = getUser;
          break;
        case 'Ambulance':
          query = getAmbulance;
          break;
        case 'Contact':
          query = getContact;
          break;
        case 'InputForm':
          query = getInputForm;
          break;
        case 'User':
          query = getUser;
          break;
        case 'WeightObject':
          query = getWeightObject;
          break;
        case 'PatientInteraction':
          query = getPatientInteraction;
          break;
        case 'MedicShift':
          query = getMedicShift;
          break;
        default:
          console.log('Invalid type:', type, 'for key:', key, 'and id:', id);
          return resolve({
            type: ResponseType.Failure,
            data: 'Invalid type',
          });
      }

      const value = await executeSingleQuery(
        graphqlOperation(query, { id: id }),
        1500
      );
      // const value = result.data[`get${type}`];
      return resolve({
        type: ResponseType.Success,
        data: {
          value: value,
          id: id,
          key: key,
        },
      });
    } catch (error) {
      console.log(error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};
