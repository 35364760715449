import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConcentrationDialog from '../../../../components/Modal/ConcentrationDialogModal';
import AddRouteModal from '../../../../components/Modal/AddRouteModal';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConfirmWarningModal from '../../../../components/Modal/ConfirmWarningModal';
import { handleGetDepartment } from '../../../../../store/actions';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { HiPencil } from 'react-icons/hi';
import * as _ from 'lodash';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { globals, toTitleCase } from '../../../../_global/common/Utils';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import MedicationItem, {
  getConcentrationString,
} from '../../../../../data/model/MedicationItem';
import { Concentration, User } from '../../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import { Button, Col, Row } from 'react-bootstrap';
import { IoArrowBack, IoEllipsisVertical } from 'react-icons/io5';
import {
  MedicationJSON,
  createParentMedication,
  propagateMedicationConcentrations,
} from '../../../../../data/functions/MedicationDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import { ProgressStatus } from '../../../../../API';
import { BiRename } from 'react-icons/bi';

interface MedicationProtocolCreatePageProps {
  stateData?: any;
}

/* 10-17-23 Arul: Created ProtocolCreatePage component globally */
const MedicationProtocolCreatePage: React.FC<
  MedicationProtocolCreatePageProps
> = ({ stateData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedProtocol, value, subValue, type, editType, editMode, page } =
    stateData;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const user: User = useSelector((state: any) => state?.user);

  // const protocolState = useSelector((state: any) => state.protocol);
  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [concentrationDialogVisible, setConcentrationDialogVisible] =
    useState(false);
  const [isAddRouteVisible, setIsAddRouteVisible] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const [deleteData, setDeleteData] = useState<any>(null);

  const [selectedConcentration, setSelectedConcentration] =
    useState<Concentration | null>(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      concentration: [],
      routes: [],
      rangeLow: '',
      rangeHigh: '',

      contraindication: '',
      note: '',
      warning: '',
      medClass: '',
      action: '',
      indication: '',
      interaction: '',
      onset: '',
      duration: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      concentration: Yup.array().min(
        1,
        'At least one concentration is required'
      ),
      routes: Yup.array().min(1, 'At least one route is required'),
      rangeLow: Yup.number(),
      rangeHigh: Yup.number(),
      contraindication: Yup.string(),
      note: Yup.string(),
      warning: Yup.string(),
      medClass: Yup.string(),
      action: Yup.string(),
      indication: Yup.string(),
      interaction: Yup.string(),
      onset: Yup.string(),
      duration: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault(); // Prevent the default browser action (e.g., saving the webpage)
        console.log('Save the form');
        // Add your custom logic here
        if (
          formik.dirty &&
          formik.isValid &&
          formik.values.concentration.length > 0 &&
          formik.values.routes.length > 0
        ) {
          handleCreateMedication();
        }
      } else if (event.key === 'Escape') {
        event.preventDefault(); // Prevent the default browser action (e.g., saving the webpage)
        console.log('Cancel the form');
        // Add your custom logic here
        handleCancel();
      } else if (event.ctrlKey && event.key === 'b') {
        event.preventDefault();
        handleCancel();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik, formik.values, formik.dirty, formik.isValid]);

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /**
   * Create the draft version of the first medication
   */
  const handleCreateMedication = async () => {
    try {
      /* If the form is valid, then create the medication */
      if (
        formik.isValid &&
        formik.values.concentration.length > 0 &&
        formik.values.routes.length > 0
      ) {
        if (globals.debug)
          console.log('Form is valid, creating medication -> ', formik.values);

        const newMedication: MedicationJSON = {
          name: formik.values.name,
          concentration: formik.values.concentration,
          routes: formik.values.routes,
          rangeLow: !isNaN(Number(formik.values.rangeLow))
            ? Number(formik.values.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(formik.values.rangeHigh))
            ? Number(formik.values.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          protocolOptions: [],

          contraindication: formik.values.contraindication,
          note: formik.values.note,
          warning: formik.values.warning,
          medClass: formik.values.medClass,
          action: formik.values.action,
          indication: formik.values.indication,
          interaction: formik.values.interaction,
          onset: formik.values.onset,
          duration: formik.values.duration,
          createdBy: user.id,

          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
        };

        let results: Response = await createParentMedication(
          department,
          newMedication
        );
        if (results.type === ResponseType.Success) {
          let newMedication: MedicationItem = results.data;
          if (department.allSubDeps) {
            if (globals.debug) console.log('Department has sub departments');
            /* This will propagate the medication concentrations to all the sub departments */
            propagateMedicationConcentrations(
              department,
              newMedication,
              newMedication.concentrations
            )
              .then((resp: Response) => {
                if (resp.type === ResponseType.Success) {
                  if (globals.debug)
                    console.log('Successfully propagated concentrations');
                } else {
                  if (globals.debug)
                    console.error(
                      'Error propagating concentrations -> ',
                      resp.data
                    );
                }
              })
              .catch((error) => {
                if (globals.debug)
                  console.error('Error propagating concentrations -> ', error);
              });
          }
          if (globals.debug)
            console.log('Successfully created medication', results.data);
          let isLoaded: boolean = await reloadDatabase();
          if (isLoaded) {
            if (globals.debug) console.log('Successfully reloaded database');
            const medication: MedicationItem = results.data;
            if (protocol) {
              navigate(`/${protocol.nickname}/protocol-detail`, {
                state: { selectedProtocol: protocol, editMode: true },
              });
            } else
              navigate(`/database/list-medications`, {
                state: {
                  department: department,
                  data: database.medications,
                  database: database,
                },
              });
          }
        }
      } else {
        /* If the form is invalid, then show the error message */
        if (globals.debug)
          console.log('Form is invalid, showing error message');
        if (formik.values.concentration.length === 0)
          formik.setFieldTouched('concentration', true);
      }
    } catch (error) {
      if (globals.debug) console.log('Error creating medication -> ', error);
    }
  };

  const handleCancel = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty && !isWarningModal) {
      setIsWarningModal(true);
    } else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-medications`, {
        state: {
          department: department,
          data: database.medications,
          database: database,
        },
      });
    }
  };

  /* 11-03-23 Praveen: To remove concentration values to show confirm popup*/
  const handleRemoveConcentration = (e: any, concen: Concentration) => {
    e.stopPropagation();
    setModalType('Concentration');
    setIsConfirmModal(true);
    setDeleteData(concen);
  };

  /* 11-03-23 Praveen: To remove route values to show confirm popup*/
  const handleRemoveRoute = (e: any, route: string) => {
    e.stopPropagation();
    setModalType('Route');
    setIsConfirmModal(true);
    setDeleteData(route);
  };

  /* 11-01-23 Praveen: To add new administration route*/
  const handleNewRouteSet = async (route: string) => {
    setIsAddRouteVisible(false);
    const routes: string[] = [...formik.values.routes, route];
    formik.setFieldValue('routes', routes);
  };

  /* 11-03-23 Praveen: To set concentration popup value */
  const handleConcentrationSet = async (
    amount1: number,
    amount2: number | undefined,
    unit1: string,
    unit2: string | undefined
  ) => {
    setConcentrationDialogVisible(false);
    const concen = new Concentration({
      departmentID: department.id,
      medicationID: '',
      firstAmnt: amount1,
      secAmnt: amount2,
      firstUnit: unit1,
      secUnit: unit2,
      status: 'DRAFT',
    });
    formik.setFieldValue('concentration', [
      ...formik.values.concentration,
      concen,
    ]);
  };

  /* 11-03-23 Praveen: To delete concentartion and route values */
  const handleConfirmDelete = async (type: any) => {
    setIsConfirmModal(false);
    if (type === 'Route') {
      const routes = formik.values.routes.filter(
        (item: any) => item !== deleteData
      );
      formik.setFieldValue('routes', routes);
    } else if (type === 'Concentration') {
      const concentrations = formik.values.concentration.filter(
        (item: any) => item !== deleteData
      );
      formik.setFieldValue('concentration', concentrations);
    }
  };

  const handleConcentrationAdd = (event: any) => {
    if (formik.errors.concentration != null)
      formik.setFieldTouched('concentration', false);
    setSelectedConcentration(null);
    setConcentrationDialogVisible(true);
  };

  const handleEditConcentration = (e: any, item: Concentration) => {
    /* Remove the selected concentration from the list */
    e.stopPropagation();
    const concentrations: Concentration[] = formik.values.concentration.filter(
      (con: Concentration) => con !== item
    );
    formik.setFieldValue('concentration', concentrations);

    setSelectedConcentration(item);
    setConcentrationDialogVisible(true);
  };

  const MedicationHeader = () => {
    return (
      <div style={{ margin: '0px 20px' }}>
        <Row>
          <Col sm={6} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={`titleStyling`}>
              <h6 className={`backTextLight hoverText`} onClick={handleCancel}>
                <span>
                  <IoArrowBack size="1.15rem" style={{ marginRight: '5px' }} />
                </span>
                {toTitleCase(protocol ? protocol.name : 'Medications')}
              </h6>
              <div className="titleText headerText">Create New Medication</div>
              {/* {date && < h6 className={`headerTextLight  ${homeScreen ? "titleText" : ''}`}>{date}</h6>} */}
            </div>
          </Col>
          <Col
            sm={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="headerButtonContainer">
              <Button
                data-testid="isCreateButton"
                className="primary-button btn-rightMargin"
                onClick={handleCreateMedication}
                disabled={
                  !(
                    formik.dirty &&
                    formik.isValid &&
                    formik.values.concentration.length > 0 &&
                    formik.values.routes.length > 0
                  )
                }
              >
                <span>
                  <AiOutlinePlus className="icon-normal" />
                </span>{' '}
                Create
              </Button>
              <span style={{ display: 'flex' }}>
                <IoEllipsisVertical
                  data-testid="isDotButton"
                  className="dots-icon"
                />
              </span>
            </div>
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  const Modals = () => {
    return (
      <>
        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={() => {
              handleCancel();
            }}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication.  Click cancel to return to Medication details.  To continue without saving changes, select Abandon.`}
          />
        )}

        <ConcentrationDialog
          dialogVisible={concentrationDialogVisible}
          handleSet={handleConcentrationSet}
          value={selectedConcentration ? selectedConcentration : undefined}
          concentrations={formik.values.concentration}
          setDialogVisible={setConcentrationDialogVisible}
          headerText={
            selectedConcentration ? 'Edit Concentration' : 'Add Concentration'
          }
        />

        <AddRouteModal
          dialogVisible={isAddRouteVisible}
          handleSet={handleNewRouteSet}
          onNewRouteCancel={() => {
            setIsAddRouteVisible(false);
          }}
          headerText="New Administration Route"
        />

        <ConfirmWarningModal
          isVisible={isConfirmModal}
          modalType={modalType}
          type={modalType}
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        {/* <RouteDialog 
                visible={routeDialogVisible} 
                handleSet={handleEditRouteSet} 
                data={formik.values.routes} 
                onClose={() => { setRouteDialogVisible(false) }}
                handleNewRoute={() => { setIsAddRouteVisible(true) }} /> */}
      </>
    );
  };

  return (
    <div className="screen-container">
      <Modals />
      <div className="fixedHeader">
        <MedicationHeader />
        {/* <ProtocolHeader 
                    name={'New Medication'} 
                    type={'protocol'} 
                    protocolDetail={protocol} 
                    isCreateButton={true} 
                    isDotButton={true} 
                    handleCancel={handleCancel} 
                    handleCreate={handleCreateProtocol} /> */}
      </div>
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <div className="container overflow-info-first overflow-info-container">
            <h5 className="ketmine-header-text">Required Information</h5>
            <label
              htmlFor="name"
              className={`notification-css-title`}
              style={{ fontSize: '16px' }}
            >
              Name
              <span className="required-field ">
                *
                <BiRename
                  className="refresh-icon"
                  onClick={() => {
                    formik.setFieldValue(
                      'name',
                      toTitleCase(formik.values.name)
                    );
                  }}
                  size="1.25rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="name"
                name="name"
                data-testid="name"
                autoFocus
                value={formik.values.name}
                onChange={(e: any) => {
                  formik.setFieldValue('name', e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              <div className="input-border"></div>
            </div>
            {formik.touched.name && formik.errors.name ? (
              <span className="errorText">{formik.errors.name}</span>
            ) : null}
            <label htmlFor="Concentration" className="ketamine-general-label">
              Concentration <span className="required-field">*</span>
            </label>
            <div
              data-testid="outsideConcentration"
              onClick={handleConcentrationAdd}
              className={`cursorPointer selectionArea-hover ${
                formik.values.concentration.length !== 0 ? '' : 'centerContent'
              }`}
            >
              {formik.values.concentration.length !== 0 && (
                <ViewportList items={formik.values.concentration}>
                  {(item: Concentration, index: any) => {
                    return (
                      <div
                        key={index + item}
                        className="selectedGreybox cursorPointerArea"
                      >
                        <div onClick={(e) => handleEditConcentration(e, item)}>
                          {getConcentrationString(item)}
                        </div>
                        <span
                          className="close_icon_color"
                          onClick={(e) => handleRemoveConcentration(e, item)}
                        >
                          <AiOutlineCloseCircle
                            data-testid={`deleteConcentration ${index}`}
                            className="icon-normal"
                          />
                        </span>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
              {formik.values.concentration.length !== 0 && (
                <HiPencil
                  data-testid="editConcentaionData"
                  className="icon-black rightIcon cursorPointer"
                  onClick={() => {
                    setConcentrationDialogVisible(true);
                  }}
                />
              )}
              {formik.values.concentration.length === 0 && (
                <div
                  onClick={() => {
                    setConcentrationDialogVisible(!concentrationDialogVisible);
                  }}
                >
                  <span className="formAnchorText">+Add Concentration</span>
                </div>
              )}
            </div>
            {formik.touched.concentration && formik.errors.concentration ? (
              <span className="errorText">{formik.errors.concentration}</span>
            ) : null}
            <label htmlFor="Concentration" className="ketamine-general-label">
              Routes <span className="required-field">*</span>
            </label>
            <div
              data-testid="outsideRoute"
              onClick={() => {
                if (formik.errors.routes != null)
                  formik.setFieldTouched('routes', false);
                setIsAddRouteVisible(true);
              }}
              className={`cursorPointer selectionArea-hover ${
                formik.values.routes.length !== 0 ? '' : 'centerContent'
              }`}
            >
              {formik.values.routes.length >= 1 && (
                <ViewportList items={formik.values.routes}>
                  {(item: any, index: any) => {
                    return (
                      <div
                        key={index + item}
                        className="selectedGreybox cursorPointerArea"
                      >
                        <div>{item}</div>
                        <span className="close_icon_color">
                          <AiOutlineCloseCircle
                            data-testid={`deleteRoute ${index}`}
                            className="cursorPointer icon-normal"
                            onClick={(e) => {
                              handleRemoveRoute(e, item);
                            }}
                          />
                        </span>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
              {formik.values.routes.length >= 1 && (
                <HiPencil
                  data-testid="editRoutesData"
                  className="cursorPointer icon-black rightIcon"
                />
              )}
              {
                !formik.values.routes.length && (
                  // <div onClick={() => { setIsAddRouteVisible(true); }}>
                  <span className="cursorPointer formAnchorText">
                    +Add Route
                  </span>
                )
                // </div>
              }
            </div>
            {formik.touched.routes && formik.errors.routes ? (
              <span className="errorText">{formik.errors.routes}</span>
            ) : null}
            <Row>
              <Col sm={6}>
                <label htmlFor="rangeLow" className="ketamine-general-label">
                  Range Low (kg)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="name"
                    name="rangeLow"
                    data-testid="rangeLow"
                    value={formik.values.rangeLow}
                    // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                    placeholder="Min"
                    onChange={(e: any) => {
                      /* Check for "Min" */
                      let n = Number(e.target.value);
                      if (isNaN(n))
                        formik.setFieldError('rangeLow', 'Invalid input');
                      else formik.setFieldValue('rangeLow', e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <div className="input-border"></div>
                </div>
                {formik.touched.rangeLow && formik.errors.rangeLow ? (
                  <span className="errorText">{formik.errors.rangeLow}</span>
                ) : null}
              </Col>
              <Col sm={6}>
                <label htmlFor="rangeHigh" className="ketamine-general-label">
                  Range High (kg)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="rangeHigh"
                    name="rangeHigh"
                    data-testid="rangeHigh"
                    value={formik.values.rangeHigh}
                    placeholder="Max"
                    onChange={(e: any) => {
                      let n = Number(e.target.value);
                      if (isNaN(n))
                        formik.setFieldError('rangeHigh', 'Invalid input');
                      else formik.setFieldValue('rangeHigh', e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <div className="input-border"></div>
                </div>
                {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                  <span className="errorText">{formik.errors.rangeHigh}</span>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
        <div className="KetamineGeneral">
          <div className="container overflow-info-second overflow-info-container">
            <h5 className="ketmine-header-text">Additional Information</h5>
            <label
              htmlFor="Contraindication"
              className="ketamine-general-label"
            >
              Contraindication
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="contraindication"
                data-testid="contraindication"
                name="contraindication"
                value={formik.values.contraindication}
                onChange={(e: any) => {
                  formik.setFieldValue('contraindication', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Warning" className="ketamine-general-label">
              Warning
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="warning"
                data-testid="warning"
                name="warning"
                value={formik.values.warning}
                onChange={(e: any) => {
                  formik.setFieldValue('warning', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Indication" className="ketamine-general-label">
              Indication
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="indication"
                data-testid="indication"
                name="indication"
                value={formik.values.indication}
                onChange={(e: any) => {
                  formik.setFieldValue('indication', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="DrugInteraction" className="ketamine-general-label">
              Drug Interaction
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="interaction"
                data-testid="interaction"
                name="interaction"
                value={formik.values.interaction}
                onChange={(e: any) => {
                  formik.setFieldValue('interaction', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Onset" className="ketamine-general-label">
              Onset
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="onset"
                data-testid="onset"
                name="onset"
                value={formik.values.onset}
                onChange={(e: any) => {
                  formik.setFieldValue('onset', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Duration" className="ketamine-general-label">
              Duration
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="duration"
                data-testid="duration"
                name="duration"
                value={formik.values.duration}
                onChange={(e: any) => {
                  formik.setFieldValue('duration', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Note" className="ketamine-general-label">
              Note
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="notes"
                data-testid="notes"
                name="notes"
                value={formik.values.note}
                onChange={(e: any) => {
                  formik.setFieldValue('note', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Class" className="ketamine-general-label">
              Class
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="class"
                data-testid="class"
                name="class"
                value={formik.values.medClass}
                onChange={(e: any) => {
                  formik.setFieldValue('medClass', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="Action" className="ketamine-general-label">
              Action
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="action"
                data-testid="action"
                name="action"
                value={formik.values.action}
                onChange={(e: any) => {
                  formik.setFieldValue('action', e.target.value);
                }}
              />
              <div className="input-border"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicationProtocolCreatePage;
