import ProgressBar from '@asyrafhussin/react-progress-bar';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa6';
import { GoChecklist } from 'react-icons/go';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoChevronForward, IoClose, IoSearch } from 'react-icons/io5';
import { TbBellRinging } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { getFormattedDate } from '../../../_global/common/Utils';
import {
  filterOption,
  filterOptionDateRange,
} from '../../../_global/constants/Protocol_constants';
import ConfirmWarningModal from '../../../components/Modal/ConfirmWarningModal';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import {
  handleGetDepartment,
  handleNotificationCache,
} from '../../../../store/actions';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import { User } from '../../../../models';
import NotificationItem from '../../../../data/model/NotificationItem';
import { NotificationType } from '../../../../API';
import { DataStore } from 'aws-amplify';
import SearchBar from '../../../components/Search/SearchBar';
import NotificationFeatures from './NotificationFeatures';
import {
  NotificationAcknowledgeUsers,
  fetchNotifications,
} from '../../../../data/functions/NotificationDB';

/* 11-27-23 Arul: Created common Component for Notification Main Screen*/
const NotificationMainPage = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );

  const notificationList: NotificationItem[] = useSelector(
    (state: any) => state.notification.notificationList
  );

  const [notifications, setNotificactions] = useState(notificationList || []);

  const [searchQuery, setSearchQuery] = useState('');

  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isFilterBtn, setIsFilterBtn] = useState(false);
  const [isDateBtn, setIsDateBtn] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<any>('');
  const [progressData, setProgressData] = useState({});
  const newButtonRef: any = useRef(null);
  const dateButtonRef: any = useRef(null);

  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  const reloadNotifications = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await fetchNotifications(
      database.department,
      (resp: any) => {
        let nots = [...resp];
        setNotificactions(nots);
      }
    );
    if (resp.type === ResponseType.Success) {
      // const newDB: DatabaseResponse = resp.data;
      // setDatabase(newDB);
      // dispatch<any>(handleGetDepartment(newDB));
      dispatch<any>(handleNotificationCache(resp.data));
      setNotificactions(resp.data);
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const progressResults: any = {};
      for (const [index, item] of notifications.entries()) {
        const { amount, percentage, validAckIds, validViewIds, totalUsers } =
          await handleGetProgress(item);
        progressResults[index] = {
          amount,
          percentage,
          validAckIds,
          validViewIds,
          totalUsers,
        };
      }

      setProgressData(progressResults);
    };

    fetchData();
  }, [notifications]);

  useEffect(() => {
    if (database) {
      //   const subscription = DataStore.observe(Notification).subscribe((msg) => {
      //     if (
      //       msg.opType === 'UPDATE' ||
      //       msg.opType === 'INSERT' ||
      //       msg.opType === 'DELETE'
      //     ) {
      //       reloadNotifications();
      //     }
      //   });
      reloadNotifications();
      //   return () => subscription.unsubscribe();
    }
  }, [database]);

  useEffect(() => {
    if (location?.state?.isCreate) {
      setIsSuccessModal(true);
    }
  }, [location?.state]);

  /* 11-27-23 Arul: function for create new notification*/
  const handleCreate = () => {
    navigate('/create/notification');
  };
  //onrow click to navigate view page
  const handleNotificationTitleClick = (item: any, index: any) => () => {
    const progress = (progressData as any)[index] || {
      amount: 0,
      percentage: 0,
      validAckIds: [],
      validViewIds: [],
      totalUsers: [],
    };
    const value = { ...item, index: index, ...progress };
    navigate('/view/notification', { state: value });
  };

  const NotificationIcon = ({ _type }: any) => {
    let type = _type as NotificationType;
    return (
      <>
        {type == NotificationType.GENERAL && <TbBellRinging size={24} />}
        {type == NotificationType.ACKNOWLEDGE && (
          <IoMdCheckmarkCircleOutline size={24} />
        )}
        {type == NotificationType.TRAINING && <GoChecklist size={24} />}
      </>
    );
  };

  const filterData = useCallback(
    (selectedOption: any) => {
      const currentDate = new Date(); // Current date
      const parseDate = (dateStr: any) => new Date(dateStr);

      switch (selectedOption) {
        case '3 Days':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              3
          );
        case '1 Week':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              7
          );
        case '2 Weeks':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              14
          );
        case '1 Month':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              30
          );
        case '3 Months':
          return notifications.filter(
            (item: NotificationItem) =>
              (currentDate.getTime() - parseDate(item.timestamp).getTime()) /
                (1000 * 60 * 60 * 24) <=
              90
          );
        case '2023 - Dates back to the furthest date':
          const furthestDate = new Date('01/01/2023');
          return notifications.filter(
            (item: NotificationItem) =>
              parseDate(item.timestamp) >= furthestDate
          );
        default:
          return notifications;
      }
    },
    [notifications]
  );

  const handleSearchChange = (text: string) => {
    setSearchQuery(text);
    handleFilterData(selectedOptions, selectedDateRange, text);
  };

  //progress value
  const handleGetProgress = async (notification: NotificationItem) => {
    // let viewedResponse = await NotificationViewedUsers(notification); //get all viewed users
    // let uniqueViewIds = new Set<string>();
    // if (viewedResponse.type === ResponseType.Success && viewedResponse.data) {
    //   uniqueViewIds = new Set(viewedResponse.data);
    // }
    let uniqueViewIds = new Set<string>();
    if (notification.viewed)
      uniqueViewIds = new Set(notification.viewed.map((view) => view.userID));
    // Fetch acknowledged users
    // const ackResponse = await NotificationAcknowledgeUsers(notification);
    let uniqueAckIds = new Set<string>();
    if (notification.acknowledged)
      uniqueAckIds = new Set(
        notification.acknowledged.map((ack) => ack.userID)
      );
    // if (ackResponse.type === ResponseType.Success && ackResponse.data) {
    //   uniqueAckIds = new Set(ackResponse.data);
    // }

    const validViewIds = [...uniqueViewIds].filter((id) =>
      database.users.some((user) => user.id === id)
    );
    const validAckIds = [...uniqueAckIds].filter((id) =>
      database.users.some((user) => user.id === id)
    );

    let relevantIds =
      notification.type === 'GENERAL' ? validViewIds : validAckIds;

    // const totalGroupsResponse = await getGroupsFromNotification(notification);

    const totalUsers: any = [];
    if (
      notification &&
      notification.groups &&
      notification.groups?.length > 0
    ) {
      // const groupItems = totalGroupsResponse.data;
      for (const group of notification.groups) {
        let users = group.pairedUserIDs;
        let uniqueUsers = new Set(users);
        totalUsers.push(...uniqueUsers);
      }
    } else {
      let departmentUsers = database.users.filter(
        (user) => user.departmentID === notification.depID
      );
      if (departmentUsers) {
        const userIds = departmentUsers.map((user: User) => user.id);
        const uniqueUsers = new Set(userIds);
        totalUsers.push(...uniqueUsers);
      }
    }

    let percentage =
      totalUsers.length === 0
        ? 0
        : Math.round((relevantIds.length / totalUsers.length) * 100);
    return {
      amount: relevantIds.length,
      percentage: percentage,
      validAckIds: validAckIds,
      validViewIds: validViewIds,
      totalUsers: totalUsers,
    };
  };

  const handleFilterData = (
    options: any,
    dateRange: any,
    searchQuery: string
  ) => {
    let filteredNotifications = notificationList ? [...notificationList] : [];

    // Convert selectedOptions to uppercase for case-insensitive comparison
    const upperCaseOptions = options.map((option: any) => option.toUpperCase());

    // Filter by selected notification type
    if (upperCaseOptions.length > 0) {
      filteredNotifications = filteredNotifications.filter((notification) =>
        upperCaseOptions.includes(notification.type.toUpperCase())
      );
    }

    // Filter by selected date range
    if (dateRange && dateRange !== '') {
      const filteredByDate = filterData(dateRange);
      filteredNotifications = filteredNotifications.filter((notification) =>
        filteredByDate.includes(notification)
      );
    }

    if (searchQuery) {
      filteredNotifications = filteredNotifications.filter(
        (notification: NotificationItem) =>
          notification.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setNotificactions(filteredNotifications);
  };

  const handeleDateChange = (e: any) => {
    setSelectedDateRange(e);
    setIsDateBtn(false);
    handleFilterData(selectedOptions, e, searchQuery);
  };

  /* 10-16-2023 Arul: Removing the row selection while clicking apart from the table Row*/
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isFilterBtn, isDateBtn]);

  /* 10-16-2023 Arul: function for clicking outside to remove selection and update rename*/
  const handleDocumentClick = (e: any) => {
    if (
      !newButtonRef?.current ||
      !newButtonRef?.current.contains(e.target as Node)
    ) {
      setIsFilterBtn(false);
    }
    if (
      !dateButtonRef?.current ||
      !dateButtonRef?.current.contains(e.target as Node)
    ) {
      setIsDateBtn(false);
    }
  };

  const handleCheckboxChange = (option: any, protocol: any) => {
    let newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item: any) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    handleFilterData(newSelectedOptions, selectedDateRange, searchQuery);
  };

  useEffect(() => {
    handleFilterData(selectedOptions, selectedDateRange, searchQuery);
  }, [selectedOptions, selectedDateRange, searchQuery, notificationList]);

  return (
    <div className="screen-container">
      <ConfirmWarningModal
        isVisible={isSuccessModal}
        modalType={'success'}
        handleClose={() => {
          setIsSuccessModal(false);
        }}
        handleDelete={() => {
          setIsSuccessModal(false);
        }}
      />

      <div>
        <div className="fixedHeader fixedHeaderPad">
          <ProtocolHeader
            homeScreen={true}
            type={'protocol'}
            page={'Notification'}
            isCreateButton={adminLevel > 2}
            isCreateActive={true}
            handleCreate={handleCreate}
          />
        </div>
        <div>
          <div>
            <Row>
              <Col sm={6}>
                <SearchBar
                  containerStyle={{ width: '100%' }}
                  value={searchQuery}
                  onChange={(searchTerm: string) => {
                    handleSearchChange(searchTerm);
                  }}
                  onSubmit={(searchTerm: string) => {}}
                  placeholder={'Search Notifications...'}
                />
                {/* <div className="search-bar">
                <Form className="d-none d-sm-inline-block">
                  <InputGroup
                    style={{ zIndex: 0 }}
                    className="input-group-navbar"
                  >
                    <Button variant="">
                      <IoSearch size="1.5rem" />
                    </Button>
                    <Form.Control
                      placeholder="Search Notification..."
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="searchInput"
                    />
                    {searchQuery && (
                      <Button
                        variant=""
                        onClick={() => {
                          setSearchQuery("");
                          handleFilterData(
                            selectedOptions,
                            selectedDateRange,
                            ""
                          );
                        }}
                      >
                        <IoClose size="1.5rem" />
                      </Button>
                    )}
                  </InputGroup>
                </Form>
              </div> */}
              </Col>
              <Col sm={6}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div
                    ref={dateButtonRef}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <div>
                      <Button
                        onClick={() => {
                          setIsDateBtn(!isDateBtn);
                          setIsFilterBtn(false);
                        }}
                        style={{ width: 'auto', minWidth: '100px' }}
                        className="filtBtn filtCloseIcon secondary-button-small-border btn-rightMargin"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            padding: '0px 10px',
                          }}
                        >
                          <span>Date Range:</span>
                          {selectedDateRange && (
                            <div className="filtCloseIcon">
                              {selectedDateRange}
                              <span className="filtCloseIcon close_icon_color">
                                <AiOutlineCloseCircle
                                  onClick={() => {
                                    setSelectedDateRange('');
                                    setIsDateBtn(false);
                                    handleFilterData(
                                      selectedOptions,
                                      '',
                                      searchQuery
                                    );
                                  }}
                                  className="cursorPointer filtCloseIcon pairedProtocol-cancel-icon"
                                />
                              </span>
                            </div>
                          )}
                        </div>
                      </Button>
                      {isDateBtn && (
                        <div className="subBtnBorder">
                          <ViewportList items={filterOptionDateRange}>
                            {(item: any, index: any) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handeleDateChange(item.value);
                                }}
                                className={`radioBtnSelectedColor listhover cursorPointer item contentHeading ${
                                  selectedDateRange === item.value
                                    ? 'list_column_selected_Checklist'
                                    : 'column_unselected1'
                                } ${
                                  filterOptionDateRange.length !== index + 1
                                    ? 'contentUnderline'
                                    : ''
                                }`}
                              >
                                <div className="radioBtnSelectedColor">
                                  {item.value}
                                </div>
                              </div>
                            )}
                          </ViewportList>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    ref={newButtonRef}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <div>
                      <Button
                        onClick={() => {
                          setIsFilterBtn(!isFilterBtn);
                          setIsDateBtn(false);
                        }}
                        style={{ width: 'auto', minWidth: '100px' }}
                        className="filtBtn filtCloseIcon secondary-button-small-border btn-rightMargin"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            padding: '0px 10px',
                          }}
                        >
                          <span>Filter </span>
                          {selectedOptions?.length >= 1 && (
                            <div style={{ marginLeft: '5px' }}>
                              ( {selectedOptions?.length} )
                              <span className="close_icon_color">
                                <AiOutlineCloseCircle
                                  onClick={() => {
                                    setSelectedOptions([]);
                                    handleFilterData(
                                      [],
                                      selectedDateRange,
                                      searchQuery
                                    );
                                  }}
                                  className="cursorPointer filtCloseIcon pairedProtocol-cancel-icon"
                                />
                              </span>
                            </div>
                          )}
                          <span>
                            <FaChevronDown
                              className="icon-normal"
                              size="1rem"
                            />
                          </span>
                        </div>
                      </Button>
                      {isFilterBtn && (
                        <div className="subBtnBorder" style={{ right: '15px' }}>
                          <ViewportList items={filterOption}>
                            {(item: any, index: any) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handleCheckboxChange(item.name, item);
                                }}
                                className={`radioBtnSelectedColor listhover cursorPointer item contentHeading ${
                                  selectedOptions.includes(item.name)
                                    ? 'list_column_selected_Checklist'
                                    : 'column_unselected1'
                                } ${
                                  filterOption.length !== index + 1
                                    ? 'contentUnderline'
                                    : ''
                                }`}
                              >
                                <input
                                  id="pairedProtocol"
                                  type="checkbox"
                                  className="custom-radio-button-input"
                                  value={item.name}
                                  checked={selectedOptions.includes(item.name)}
                                />
                                {item.name}
                              </div>
                            )}
                          </ViewportList>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="lightText"
            style={{ marginBottom: '5px', fontWeight: 'bold' }}
          >
            Search the notification
          </div>
          <div className="contentBorder" style={{ height: '100%' }}>
            <ViewportList items={notifications}>
              {(item: NotificationItem, index: number) => {
                const {
                  amount,
                  percentage,
                  validAckIds,
                  validViewIds,
                  totalUsers,
                } = (progressData as any)[index] || {
                  amount: 0,
                  percentage: 0,
                  validAckIds: [],
                  validViewIds: [],
                  totalUsers: [],
                };

                const notificationUser = database.users.find((user) =>
                  item.dbNotification.modifiedBy
                    ? item.dbNotification.modifiedBy === user.id
                    : item.dbNotification.createdBy === user.id
                );

                return (
                  <div
                    data-testid={`viewNotification-${index}`}
                    key={index}
                    className={`cursorPointer hoverItem`}
                    style={{ borderRadius: '10px' }}
                    onClick={handleNotificationTitleClick(item, index)}
                  >
                    <div
                      style={{
                        padding: '2.5px 10px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* Main Notification UI */}
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '10px',
                          width: '70%',
                          alignItems: 'center',
                        }}
                      >
                        <NotificationIcon _type={item.type} />
                        <span className={'a1Content'} style={{ width: '100%' }}>
                          {/* Notification Timestamp */}
                          <div
                            className="notification-css-grey"
                            style={{ fontSize: '14px' }}
                          >
                            {getFormattedDate(item.timestamp, false)}
                          </div>

                          {/* Notification Title */}

                          <div className="contentTitle">
                            {item.title}
                            {notificationUser && (
                              <div className="notification-css-modifiedBy">
                                {notificationUser.firstName +
                                  ' ' +
                                  notificationUser.lastName}
                              </div>
                            )}
                          </div>

                          {/* Notification Message */}
                          <div
                            style={{
                              justifyContent: 'flex-start',
                              fontSize: '14px',
                              color: '#212121',
                            }}
                            className="notification-css-grey"
                          >
                            {item.message}
                          </div>
                        </span>
                      </div>

                      {/* Progress bar title */}
                      <div
                        className="lightText"
                        style={{
                          width: '10%',
                          marginBottom: '5px',
                          fontWeight: '400',
                          justifyContent: 'flex-end',
                          marginRight: '5px',
                        }}
                      >
                        <div>
                          {item.type === 'GENERAL' ? 'Viewed' : 'Acknowledge'}
                        </div>
                      </div>

                      {/* Progress Bar */}
                      <div style={{ width: '15%', marginTop: '-30px' }}>
                        <div
                          className="lightText"
                          style={{ justifyContent: 'center' }}
                        >
                          {percentage}% ({amount} / {totalUsers.length})
                        </div>
                        <ProgressBar
                          baseBgColor={'#D0D0D0'}
                          completed={percentage}
                          height={'10px'}
                          width={'100%'}
                          bgColor={'#00534C'}
                          isLabelVisible={false}
                        />
                      </div>

                      {/* Arrow Icon */}
                      <div
                        className=""
                        style={{ position: 'absolute', right: '30px' }}
                      >
                        <IoChevronForward
                          size={20}
                          style={{ marginRight: '5px' }}
                        />
                      </div>
                    </div>

                    {/* Bottom border */}
                    {index !== notifications.length - 1 && (
                      <div
                        style={{
                          width: '100%',
                          height: '1px',
                          background: '#E0E0E0',
                        }}
                      ></div>
                    )}
                  </div>
                );
              }}
            </ViewportList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationMainPage;
