import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { Ambulance, User } from '../../../../../models';

import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import ProtocolHeader from '../../ProtocolHeader';
import { InputText } from 'primereact/inputtext';
import { set } from 'lodash';
import { ViewportList } from 'react-viewport-list';
import { handleGetDepartment } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { getFormattedDate, globals } from '../../../../_global/common/Utils';
import { UserType } from '../../../../../models';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  createAmbulance,
  deleteAmbulance,
  editAmbulance,
} from '../../../../../data/functions/AmbulanceDB';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const CreateEditAmbulance = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [list, setList] = useState<Ambulance[]>(database.ambulances);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const department: DepartmentItem = database.department;
  const ambulances: Ambulance[] = database.ambulances;
  const [parmAmbulance, setParmAmbulance] = useState<Ambulance>(state.value);
  const [isDelete, setIsDelete] = useState(false);
  const availableDeps = useMemo(() => {
    return department.isTopEnabled || user.type === 'ADMIN'
      ? [department, ...(department.allSubDeps || [])]
      : department.allSubDeps || [];
  }, [department, user]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'b') handleBack();
      else if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('AMBULANCE', parmAmbulance);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [parmAmbulance]);

  const [ambulance, setAmbulance] = useState<any>({
    id: parmAmbulance ? parmAmbulance.id : '',
    name: parmAmbulance ? parmAmbulance.name : '',
    department: parmAmbulance
      ? findDepartment(parmAmbulance.departmentID)
      : department.isMultiDep
        ? ''
        : department,
  });

  function findDepartment(id: string): DepartmentItem | undefined {
    let dep = availableDeps.find((d) => d.id === id);
    return dep ? dep : department;
  }

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  useEffect(() => {
    let l = [...database.ambulances];
    setList(l);
  }, [parmAmbulance]);

  // Update the ambulance state when the parmAmbulance state changes
  useEffect(() => {
    if (state.value) {
      setParmAmbulance(state.value);
      setAmbulance({
        id: state.value ? state.value.id : '',
        name: state.value ? state.value.name : '',
        department: state.value
          ? findDepartment(state.value.departmentID)
          : department.isMultiDep
            ? ''
            : department,
      });
    }
  }, [state.value]);

  const handleBack = () => {
    navigate(`/database/list-ambulances`, {
      state: { department: department, data: ambulances, database },
    });
  };

  const handleSubmit = async () => {
    if (ambulance.name === '') {
      alert('Please fill out all fields');
      return;
    }
    if (globals.debug) console.log('Ambulance', ambulance);
    let result;
    if (parmAmbulance == null) result = await createAmbulance(ambulance);
    else result = await editAmbulance(ambulance);
    if (result.type === ResponseType.Success) {
      if (globals.debug)
        console.log(
          'Ambulance',
          parmAmbulance ? ' editted' : ' created',
          result.data
        );
      let resultAmbulance = result.data as Ambulance;

      await reloadDatabase();

      navigate(`/database/list-ambulances`, {
        state: { department: department, database },
      });
    } else {
      if (globals.debug)
        console.log('Create/Edit ambulance failed', result.data);
    }
  };

  const RenderAmbulance = ({ item, index }: any) => {
    return (
      <div key={index}>
        <div
          className="departmentItem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            padding: '10px 10px',
            borderBottom:
              index !== list.length - 1 ? '1px solid #E0E0E0' : 'none',
            cursor: 'pointer',
          }}
        >
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0 }}
          >
            {index + 1}: {item.name}
          </div>
        </div>
      </div>
    );
  };

  const handleDelete = async () => {
    let result = await deleteAmbulance(parmAmbulance);
    if (result.type === ResponseType.Success) {
      await reloadDatabase();
      navigate(`/database/list-ambulances`, {
        state: { department: department, database },
      });
    } else {
      if (globals.debug) console.log('Delete ambulance failed', result.data);
    }
  };
  return (
    <div className="screen-container">
      {parmAmbulance && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Contact?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDelete}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want delete ambulance: ' +
            parmAmbulance.name +
            '?'
          }
        />
      )}
      <div className="fixedHeader">
        <ProtocolHeader
          name={parmAmbulance ? 'Edit Ambulance' : 'New Ambulance'}
          type={'protocol'}
          page={'Ambulances'}
          isCreateButton={parmAmbulance ? false : true}
          isSaveButton={parmAmbulance ? true : false}
          isDeleteButton={parmAmbulance ? true : false}
          handleDelete={() => {
            setIsDelete(true);
          }}
          isSaveActive={
            ambulance.name !== '' &&
            ambulance.name !== parmAmbulance?.name &&
            ambulance.department !== undefined
          }
          isBackButton={true}
          isDotButton={true}
          isCreateActive={ambulance.name !== ''}
          handleCancel={handleBack}
          handleCreate={handleSubmit}
          handleSave={handleSubmit}
          handleCancelEdit={handleBack}
        />
      </div>
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <div className="container  overflow-info-first overflow-info-container ">
            <h5 className="ketmine-header-text">Ambulance Information</h5>
            {user.type === UserType.ADMIN && (
              <div className="input-container roundBorder">
                {parmAmbulance && parmAmbulance?.updatedAt && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Last Updated:</div>
                    <div style={{ fontWeight: '500' }}>
                      {getFormattedDate(parmAmbulance.updatedAt, true)}
                    </div>
                  </div>
                )}
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {parmAmbulance?.id}
                    <span>
                      {isCopied && isCopied === parmAmbulance.id ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(parmAmbulance.id as string, e)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <label htmlFor="Name" className="ketamine-general-label">
              Name <span className="required-field">*</span>
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="Name"
                name="name"
                data-testid="name"
                value={ambulance.name}
                onChange={(e: any) => {
                  setAmbulance({ ...ambulance, name: e.target.value });
                }}
              />
              <div className="input-border"></div>
            </div>
            {availableDeps && availableDeps.length > 0 && (
              <div
                style={{
                  marginTop: '20px',
                }}
              >
                <label htmlFor="Name" className="ketamine-general-label">
                  Department <span className="required-field">*</span>
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontSize: '13px', marginLeft: '10px' }}
                >
                  Select the department that this ambulance belongs to.
                </span>
                <SearchableDropdown<DepartmentItem>
                  id="searchDropdown"
                  options={availableDeps}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.id}
                  value={ambulance.department}
                  multiSelect={false}
                  onChange={(option: DepartmentItem) =>
                    setAmbulance({ ...ambulance, department: option })
                  }
                  onClear={() => {
                    setAmbulance({ ...ambulance, department: undefined });
                  }}
                  placeholder="Search department..."
                />
              </div>
            )}
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">{department.name} Ambulances</h5>
          <div className="container  overflow-info-second overflow-info-container ">
            {list.length === 0 && (
              <div className="container">
                <div className="text" style={{ color: '#616161' }}>
                  No Ambulances Created...
                </div>
              </div>
            )}
            <div className="list-container">
              <ViewportList items={list}>
                {(item: any, index: any) => (
                  <div key={index} style={{ cursor: 'pointer' }}>
                    <RenderAmbulance item={item} index={index} />
                  </div>
                )}
              </ViewportList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEditAmbulance;
