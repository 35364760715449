import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaCloudArrowUp, FaDatabase } from 'react-icons/fa6';
import * as Io5 from 'react-icons/io5';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IoBook, IoNotifications, IoArchive } from 'react-icons/io5';
import { User } from '../../../models';
import { UserType } from '../../../models';
import { TbPlugConnected } from 'react-icons/tb';
import { globals } from '../../_global/common/Utils';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { ViewportList, ViewportListRef } from 'react-viewport-list';
import {
  handleDepartmentCache,
  handleGetDepartment,
} from '../../../store/actions';
import { useDispatch } from 'react-redux';
import { loadPublicDepartmentDatabase } from '../../../data/GraphQL_API';
import { getHashedPin } from '../../_global/common/Encrypt';
import SearchBar from '../../components/Search/SearchBar';

/* 09-29-23 Arul: Created the  sidebar component globally  */
const PublicSidebar = ({ show }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const cache = useSelector((state: any) => state.departmentCache) as Map<
    string,
    DatabaseResponse
  >;
  const dbState = useSelector((state: any) => state.protocol);
  const fullyLoadedPublic = useSelector(
    (state: any) => state.fullyLoadedPublic
  );

  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );

  const department = useMemo(() => {
    return dbState.departmentItem.department as DepartmentItem;
  }, [dbState]);

  const topDepartment = useMemo(() => {
    return department?.getTopLevelDep();
  }, [department]);

  const [selectedDepartment, setSelectedDepartment] = useState<
    DepartmentItem | undefined
  >(undefined);
  const viewportRef = useRef<ViewportListRef>(null);

  useEffect(() => {
    if (selectedDepartment) return;
    if (topDepartment && department && topDepartment.id === department.id) {
      setSelectedDepartment(department);
      viewportRef.current?.scrollToIndex({ index: 0 });
    } else {
      let deps = topDepartment
        ? topDepartment.isTopEnabled
          ? [topDepartment, ...(topDepartment.subDeps ?? [])]
          : (topDepartment.subDeps ?? [])
        : [];
      let findIndex = deps.findIndex(
        (dep: DepartmentItem) => dep.id === department.id
      );
      if (findIndex !== -1) {
        viewportRef.current?.scrollToIndex({
          index: findIndex,
          delay: 100,
        });
        setSelectedDepartment(deps[findIndex]);
      }
    }
  }, [fullyLoadedPublic, topDepartment, department, selectedDepartment]);

  const filteredSubDeps = useMemo(() => {
    let deps = topDepartment
      ? topDepartment.isTopEnabled
        ? [topDepartment, ...(topDepartment.subDeps ?? [])]
        : (topDepartment.subDeps ?? [])
      : [];
    if (searchTerm) {
      deps = deps.filter((dep: DepartmentItem) =>
        dep.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return deps;
  }, [topDepartment, searchTerm, fullyLoadedPublic]);

  const handleSelect = async (subDepartment: DepartmentItem) => {
    if (department) {
      localStorage.setItem('lastSelectedDepID', subDepartment.id);
      setSelectedDepartment(subDepartment);

      if (!checkCache(subDepartment)) {
        let result: Response =
          await loadPublicDepartmentDatabase(subDepartment);
        if (result.type === ResponseType.Success) {
          let db: DatabaseResponse = result.data;
          setDatabase(db);
          dispatch<any>(handleGetDepartment(db));
          dispatch<any>(handleDepartmentCache(db));
        } else if (globals.debug)
          console.log('Error loading database', result.data);
      }
      // dispatch<any>(handlePublicSelectedItem(subDepartment));
    }
  };

  const checkCache = (subDepartment: DepartmentItem) => {
    if (cache.has(subDepartment.id)) {
      let db: DatabaseResponse = cache.get(
        subDepartment.id
      ) as DatabaseResponse;
      let keychains = confirmKeychains(db);
      let newDB = {
        ...db!,
        keychains: keychains,
      };

      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    }
    return false;
  };

  const confirmKeychains = (db: DatabaseResponse) => {
    let keychains = [];
    for (let keychain of db.keychains) {
      keychain.isUnlocked = false;

      let value = localStorage.getItem(keychain.uid);
      if (value) {
        let json: any = JSON.parse(value);
        const expiration = Number(json.expiration);
        const currentTime = new Date().getTime();

        /* If unexpired validate signature */
        if (expiration > currentTime) {
          let signature = getHashedPin(
            keychain.hash,
            keychain.salt + expiration.toLocaleString(),
            keychain.type
          );
          if (signature === json.signature) keychain.isUnlocked = true;
        } else localStorage.removeItem(keychain.uid);
      }
      keychains.push(keychain);
    }
    return keychains;
  };

  return (
    <div className={show ? 'public-sidebar active' : 'public-sidebar'}>
      <div
        className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          padding: '0.25rem',
          gap: '0.25rem',
          borderRadius: '0.5rem',
        }}
      >
        {topDepartment && topDepartment.logoVerifiedUrl && (
          <img
            className="department-logo-public"
            src={topDepartment.logoVerifiedUrl}
            alt="Agency Logo"
          />
        )}
        {topDepartment && (
          <div style={{ flexDirection: 'column' }}>
            <div
              className="titleText headerText"
              style={{
                marginLeft: '6px',
                marginRight: '6px',
                marginTop: '0px',
                marginBottom: '0px',
                padding: '0px',
              }}
            >
              {topDepartment.name}
            </div>
          </div>
        )}
      </div>
      <hr></hr>
      <SearchBar
        value={searchTerm}
        onChange={(searchTerm: string) => setSearchTerm(searchTerm)}
        containerStyle={{
          width: '100%',
          // marginLeft: '1rem',
          alignSelf: 'center',
        }}
      />
      <div
        className="publicListRow noBorder"
        style={
          {
            // marginTop: '0.25rem',
          }
        }
      >
        <ViewportList ref={viewportRef} items={filteredSubDeps}>
          {(subDepartment: DepartmentItem, index: number) => {
            let isSelected = selectedDepartment?.id === subDepartment.id;
            return (
              <div
                onClick={(e) => {
                  handleSelect(subDepartment);
                }}
                key={index}
                className={`listItemContainer ${isSelected ? 'selected' : ''}`}
                style={{
                  margin: '0.25rem 0',
                }}
              >
                <div
                  className=""
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    padding: '0.35rem 0.25rem',
                    gap: '0.25rem',
                    borderRadius: '0.5rem',
                  }}
                >
                  {subDepartment && subDepartment.logoVerifiedUrl && (
                    <img
                      className="department-logo"
                      src={subDepartment.logoVerifiedUrl}
                      alt="Agency Logo"
                    />
                  )}
                  {subDepartment && (
                    <div style={{ flexDirection: 'column' }}>
                      <div
                        className="departmentName no-select"
                        style={{
                          marginLeft: '6px',
                          marginRight: '6px',
                          marginTop: '0px',
                          marginBottom: '0px',
                          padding: '0px',
                          color: isSelected ? '#FFFFFF' : '#000000',
                        }}
                      >
                        {subDepartment.name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        </ViewportList>
      </div>
      {/* {isLoggedIn &&
      user &&
      (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN) ? (
        <ul className="sidebar-ul">
          <li>
            <div
              style={
                selectedLink === '/protocol' || selectedLink === '/'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/protocol" onClick={() => setSelectedLink('/protocol')}>
                <Io5.IoLayers />
                Protocol
              </Link>
            </div>
          </li>
          {/* <li >
                        <div style={selectedLink === '/snapshot' ? { backgroundColor: "#003935", borderRadius: 10 } : {}} >
                            <Link to='/snapshot' onClick={() => setSelectedLink('/snapshot')}><FaCloudArrowUp />Snapshots</Link>
                        </div>
                    </li>
          <li>
            <div
              style={
                selectedLink === '/database'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/database" onClick={() => setSelectedLink('/database')}>
                <FaDatabase />
                Database
              </Link>
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/notification'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link
                to="/notification"
                onClick={() => setSelectedLink('/notification')}
              >
                <IoNotifications />
                Notifications
              </Link>
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/workbook'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/workbook" onClick={() => setSelectedLink('/workbook')}>
                <IoBook />
                Workbooks
              </Link>
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/archive'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/archive" onClick={() => setSelectedLink('/archive')}>
                <IoArchive />
                Archive
              </Link>
            </div>
          </li>
          {user.type === 'ADMIN' && (
            <li>
              <div
                style={
                  selectedLink === '/actions'
                    ? { backgroundColor: '#003935', borderRadius: 10 }
                    : {}
                }
              >
                <Link to="/actions" onClick={() => setSelectedLink('/actions')}>
                  <TbPlugConnected />
                  Actions
                </Link>
              </div>
            </li>
          )}
          {/* <li>
                    <Link to='/'><FaIcons.FaHome />Home</Link>
                </li>
                <li>
                    <Link to='/assembly'><SiIcons.SiAssemblyscript />Assembly</Link>
                </li>
                <li>
                    <Link to='/devices'><MdIcons.MdDeviceHub />Devices</Link>
                </li>
                <li>
                    <Link to='/inventory'><MdIcons.MdOutlineInventory />Inventory</Link>
                </li>
                <li>
                    <Link to='/product'><FaIcons.FaProductHunt />Product</Link>
                </li>
                <li>
                    <Link to='/qms'><HiIcons.HiOutlineDocumentDuplicate />QMS</Link>
                </li>
                <li>
                    <Link to='/shipments'><FaIcons.FaShippingFast />Shipments</Link>
                </li>
                <li>
                    <Link to='/serviceability'><RiIcons.RiCustomerService2Fill />Serviceability</Link>
                </li> 
        </ul>
      ) : (
        <ul className="sidebar-ul">
          <li>
            <div
              style={
                selectedLink === '/protocol' || selectedLink === '/'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/protocol" onClick={() => setSelectedLink('/protocol')}>
                <Io5.IoLayers />
                Protocol
              </Link>
            </div>
          </li>
        </ul>
      )} */}
      <div
        className="sidebar-footer"
        style={{
          color: 'black',
        }}
      >
        <p style={{ padding: 0, margin: 0, fontSize: '12px' }}>
          OneDose Admin {globals.VERSION}
        </p>
        <p style={{ padding: 0, margin: '0px 0px 10px 0px', fontSize: '12px' }}>
          © Hinckley Medical, Inc
        </p>
      </div>
    </div>
  );
};

export default PublicSidebar;
