import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import React, { useEffect, useMemo, useState } from 'react';
import KeychainItem from '../../../data/model/KeychainItem';
import DepartmentItem from '../../../data/model/DepartmentItem';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import Dropdown from '../Dropdown/Dropdown';
import SearchableList from '../Search/SearchableList';
import { FaKey, FaTrash } from 'react-icons/fa6';
import SearchableDropdown from '../SearchableDropdown';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { updateCategoryAccess } from '../../../data/functions/CategoryDB';
import { Response, ResponseType } from '../../../data/AmplifyDB';
import { updateProtocolAccess } from '../../../data/functions/ProtocolDB';
import ConfirmModal from './ConfirmModal';
import { globals } from '../../_global/common/Utils';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getHashedPin, getSalt } from '../../_global/common/Encrypt';
import { User } from '../../../models';
import { useSelector } from 'react-redux';
import { createKeychain, KeychainDB } from '../../../data/functions/KeychainDB';
import {
  cloneDepartment,
  updateDepartment,
  updateDepartmentAccess,
} from '../../../data/functions/DepartmentDB';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Tooltip } from '@mui/material';

interface UnlockKeychainModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (
    updatedItem: DepartmentItem | CategoryItem | ProtocolItem,
    metaData: any
  ) => void;
  department: DepartmentItem;
  keychains: KeychainItem[];
  item: DepartmentItem | CategoryItem | ProtocolItem;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const EditAccessModal: React.FC<UnlockKeychainModalProps> = (
  props: UnlockKeychainModalProps
) => {
  const { isVisible, handleClose, handleSubmit, department, keychains, item } =
    props;
  const navigate = useNavigate();
  const user: User = useSelector((state: any) => state?.user);
  const [state, setState] = React.useState<'Public' | 'Private' | 'Protected'>(
    !item.isPublic ? 'Private' : item.keychainID ? 'Protected' : 'Public'
  );

  const [selectedKeychain, setSelectedKeychain] =
    React.useState<KeychainItem>();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [folderStatus, setFolderStatus] = React.useState<any>({
    isPublic: undefined as boolean | undefined,
    keychainID: undefined as string | undefined,
    folder: undefined as CategoryItem | undefined,
  });

  const [confirm, setConfirm] = useState({
    title: '',
    description: '',
    errorCode: '',
  });

  useEffect(() => {
    setState(
      !item.isPublic ? 'Private' : item.keychainID ? 'Protected' : 'Public'
    );
    if (item.keychainID) {
      setSelectedKeychain(keychains.find((k) => k.uid === item.keychainID));
    }
  }, [item]);

  const formik = useFormik({
    initialValues: {
      uid: '',
      name: '',
      password: '',
      createdBy: '',
      modifiedBy: '',
      version: 'v1.0.0',
      departmentID: department.id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      password: Yup.string().required('Password is required').min(4),
    }),
    onSubmit: async (values) => {
      /* This only works for DepartmentItem */

      /* Validate the values */
      let salt = getSalt();
      let json: KeychainDB = {
        name: item.name + ' Protocol Set Keychain',
        hashedPin: getHashedPin(values.password, salt, 'SHA256'),
        saltPin: salt,
        hashType: 'SHA256',
        version: 'v1.0.0',
        departmentID: (item as DepartmentItem).id,
        createdBy: user.id,
      };

      let result = await createKeychain(json);
      if (result.type === ResponseType.Success) {
        let keychain = result.data as KeychainItem;
        if (globals.debug) console.log('Created keychain:', keychain);
        let resp: Response = await updateDepartmentAccess(
          department,
          true,
          keychain
        );
        if (resp.type === ResponseType.Success) {
          let dep = resp.data as DepartmentItem;
          console.log('Updated department keychain:', dep);
          handleSubmit(dep, {});
        } else {
          if (resp.data === 'Department already has a keychain') {
            setConfirm({
              title: 'Error creating Keychain',
              description: 'Department already has a keychain',
              errorCode: '',
            });
          }
          console.error('Error updating department keychain:', resp.data);
        }
      }
      // result = await createWeightObject(values, user, pairedDeps);
      // else result = await editWeightObject(values, user, pairedDeps);
      // if (result.type === ResponseType.Success) {
      //   let obj = result.data as WeightObject;
      //   console.log(
      //     'WeightObject',
      //     parmKeychain ? ' editted' : ' created',
      //     obj
      //   );

      //   await reloadDatabase();
      else {
        setConfirm({
          title: 'Error Creating Keychain',

          description: 'Error: ', //+ result.data,
          errorCode: '',
        });
      }
    },
  });

  const handleButton = () => {
    if (!isVisible) return;
    if (
      state === 'Protected' &&
      ((item instanceof DepartmentItem && formik.values.password.length < 4) ||
        (!(item instanceof DepartmentItem) && !selectedKeychain))
    )
      return;

    if ((item as DepartmentItem).logoURL) {
      console.log('DepartmentItem');
      if (state === 'Protected') formik.submitForm();
      else {
        console.log('Updating department access', state, item);
        let clone = cloneDepartment(item as DepartmentItem);
        clone.keychainID = null;
        clone.isPublic = state !== 'Private';
        updateDepartment(clone, item as DepartmentItem)
          .then((resp) => {
            if (resp.type === ResponseType.Success) {
              const newDep = resp.data as DepartmentItem;
              handleSubmit(newDep, {
                keychain: null,
                isPublic: state !== 'Private',
              });
              console.log('Successfully');
            } else {
              console.log(resp.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else if ((item as CategoryItem).subCategories) {
      if (
        (item as CategoryItem).subCategories.length > 0 ||
        (item as CategoryItem).protocols.length > 0
      ) {
        setFolderStatus({
          isPublic: state !== 'Private',
          keychainID: state === 'Protected' ? selectedKeychain?.uid : null,
          folder: item as CategoryItem,
        });
      } else updateCategory(false);
    } else if ((item as ProtocolItem).parent) {
      updateProtocolAccess(
        item as ProtocolItem,
        state !== 'Private',
        state === 'Protected' ? selectedKeychain : undefined
      )
        .then((resp) => {
          if (resp.type === ResponseType.Success) {
            item.keychainID =
              state === 'Protected' ? selectedKeychain?.uid : null;
            item.isPublic = state !== 'Private';
            handleSubmit(item, {
              keychain: selectedKeychain,
              isPublic: state !== 'Private',
            });
            console.log('Successfully');
          } else {
            console.log(resp.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const updateCategory = (isUpdateAll: boolean) => {
    let promises: Promise<any>[] = [];
    promises.push(
      updateCategoryAccess(
        item as CategoryItem,
        state !== 'Private',
        state === 'Protected' ? selectedKeychain : undefined
      )
    );
    if (isUpdateAll) {
      (item as CategoryItem).subCategories.forEach((sub) => {
        promises.push(
          updateCategoryAccess(
            sub,
            state !== 'Private',
            state === 'Protected' ? selectedKeychain : undefined
          )
        );
      });
      (item as CategoryItem).protocols.forEach((protocol) => {
        promises.push(
          updateProtocolAccess(
            protocol,
            state !== 'Private',
            state === 'Protected' ? selectedKeychain : undefined
          )
        );
      });
    }
    Promise.all(promises)
      .then((resp) => {
        if (globals.debug) console.log('Updated all', resp.length);
        if (resp[0].type === ResponseType.Success) {
          item.keychainID =
            state === 'Protected' ? selectedKeychain?.uid : null;
          item.isPublic = state !== 'Private';
          handleSubmit(item, {
            keychain: selectedKeychain,
            isPublic: state !== 'Private',
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // .then((resp) => {
    //   if (resp.type === ResponseType.Success) {
    //     item.keychainID =
    //       state === 'Protected' ? selectedKeychain?.uid : null;
    //     item.isPublic = state !== 'Private';

    //     handleSubmit(item, {
    //       keychain: selectedKeychain,
    //       isPublic: state !== 'Private',
    //     });
    //   }
    // })
    // .catch((e) => {
    //   console.log(e);
    // });
  };

  const message = useMemo(() => {
    if (item instanceof DepartmentItem) {
      if (state === 'Public')
        return `Public level access means that ANYONE can access ${item.name}'s protocol set without a key on your departments public site.`;
      else if (state === 'Private')
        return `Private level means that ${item.name}'s protocol set will be HIDDEN from the public site and only your agency users can access this item.`;
      return `Protected level access means that a pin is required to be enterred to access ${item.name}'s protocol set.`;
    } else {
      if (state === 'Public')
        return 'Public level access means that ANYONE can access this item without a key on your departments public site.';
      else if (state === 'Private')
        return 'Private level means that it will be HIDDEN from the public site and only your department users can access this item.';
      return 'Protected level access means that it is on the public website but requires a PASSWORD to access it.';
    }
  }, [state, item]);

  const isSaveValid = useMemo(() => {
    let parmState = !item.isPublic
      ? 'Private'
      : item.keychainID
        ? 'Protected'
        : 'Public';
    if (state === parmState) return false;
    else if (state === 'Protected') {
      if (item instanceof DepartmentItem)
        return formik.values.password.length > 3;
      return selectedKeychain !== undefined;
    }
    return true;
  }, [state, item, formik.values.password, selectedKeychain]);

  return (
    <div>
      {folderStatus.folder && (
        <ConfirmModal
          isVisible={folderStatus.folder !== undefined}
          title={'Update ' + folderStatus.folder.name + ' Access Levels?'}
          handleClose={() => {
            setFolderStatus({
              isPublic: undefined,
              keychainID: undefined,
              folder: undefined,
            });
            updateCategory(false);
          }}
          handleSubmit={() => {
            setFolderStatus({
              isPublic: undefined,
              keychainID: undefined,
              folder: undefined,
            });
            updateCategory(true);
          }}
          isSingleBtn={false}
          primaryBtnName="Dismiss"
          secondaryBtnName="Update"
          primaryDescription={
            'Do you want to update the access level of the folders ' +
            (folderStatus.folder.subCategories.length > 0
              ? folderStatus.folder.subCategories.length + ' folders'
              : '') +
            (folderStatus.folder.subCategories.length > 0 &&
            folderStatus.folder.protocols.length > 0
              ? ' and '
              : '') +
            (folderStatus.folder.protocols.length > 0
              ? folderStatus.folder.protocols.length + ' protocols'
              : '') +
            ' to ' +
            (!folderStatus.isPublic
              ? 'Private'
              : folderStatus.keychainID
                ? 'Protected'
                : 'Public') +
            ' access?'
          }
        />
      )}
      <ConfirmModal
        isVisible={confirm.title !== ''}
        title={confirm.title}
        handleClose={() => {
          setConfirm({ title: '', description: '', errorCode: '' });
        }}
        handleSubmit={() => {
          setConfirm({ title: '', description: '', errorCode: '' });
        }}
        isSingleBtn={true}
        secondaryBtnName="Okay"
        primaryDescription={confirm.description}
        secondaryDescription={confirm.errorCode}
      />
      <Dialog
        visible={isVisible}
        style={{
          // width: '50vw',
          // maxWidth: '800px',
          // flex: 1,
          // maxWidth
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '500px',
        }}
        onHide={() => {
          handleClose();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '3rem' }}
        >
          <h4
            className="keychain-title"
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <span
              style={{
                marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              {item instanceof DepartmentItem
                ? 'Change Agency Access'
                : 'Change Access'}
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={handleClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <form
            className="keychain-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleButton();
            }}
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              // alignItems: 'center',
              background: 'transparent',
              border: 'none',
              gap: '2rem',
            }}
          >
            {item instanceof DepartmentItem && (
              <>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    // marginBottom: '10px',
                  }}
                >
                  {department.logoVerifiedUrl && (
                    <img
                      className="department-logo-public"
                      style={{
                        height: '4rem',
                        width: '4rem',
                        marginRight: '10px',
                      }}
                      src={department.logoVerifiedUrl}
                      alt="Agency Logo"
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      // width: '100%',
                    }}
                  >
                    <label
                      className='"ketamine-general-label'
                      htmlFor="password"
                      style={{
                        fontWeight: '600',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      {department.name}
                    </label>
                  </div>
                </div>
                <p
                  className="departmentItemText"
                  style={{
                    fontSize: '0.9rem',
                    color: 'black',
                    // maxWidth: '50%',
                  }}
                >
                  This will change the access to the public URL of your agency.
                </p>
              </>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <label
                htmlFor="index"
                className={`notification-css-title`}
                style={{
                  marginTop: '10px',
                  fontSize: '1rem',
                }}
              >
                Access Level:
              </label>
              <Dropdown
                value={state}
                options={[
                  { label: 'Public', value: 'Public' },
                  { label: 'Private', value: 'Private' },
                  { label: 'Protected', value: 'Protected' },
                ]}
                onChange={(e: any) => {
                  setState(e);
                }}
                style={{
                  // marginTop: '-13px',
                  marginRight: '16px',
                  padding: 0,
                }}
                buttonColor={
                  state === 'Public'
                    ? '#C3DBB0'
                    : state === 'Private'
                      ? '#D3D3D3'
                      : '#ADD8E6'
                }
                textColor={
                  state === 'Public'
                    ? '#037F02'
                    : state === 'Private'
                      ? '#3d3d3d'
                      : '#0d4d8e'
                }
              />
            </div>
            {state === 'Protected' && (
              <>
                {item instanceof DepartmentItem ? (
                  <div
                    style={{
                      // display: 'flex',
                      // justifyContent: 'space-between',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <label
                      htmlFor="index"
                      className={`notification-css-title`}
                      style={{
                        fontSize: '16px',
                        flexShrink: 0,
                        textDecoration: 'underline',
                      }}
                    >
                      {item.keychainID ? 'Keychain' : 'Create New Keychain'}
                    </label>
                    <span
                      className="contentText greyText"
                      style={{ fontSize: '13px', marginLeft: '0px' }}
                    >
                      {item.keychainID
                        ? `${item.name} is being protect from the keychain.`
                        : `Protect ${item.name} protocol set with a password.`}
                    </span>
                    {selectedKeychain ? (
                      <label
                        htmlFor="index"
                        className={`notification-css-title`}
                        style={{
                          fontSize: '14px',
                          flexShrink: 0,
                        }}
                      >
                        {selectedKeychain.name}
                      </label>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <label
                          htmlFor="index"
                          className={`notification-css-title`}
                          style={{
                            fontSize: '16px',
                            flexShrink: 0,
                          }}
                        >
                          Password:
                        </label>

                        <div
                          className="input-container"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // width: '100%',
                            marginLeft: '10px',
                            marginTop: '0px',
                            flexGrow: 1,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <InputText
                              type={isPasswordVisible ? 'text' : 'password'}
                              className="form-control-general"
                              id="password"
                              name="password"
                              data-testid="password"
                              value={formik.values.password}
                              onChange={(e: any) => {
                                formik.setFieldValue(
                                  'password',
                                  e.target.value
                                );
                              }}
                            />
                            {isPasswordVisible ? (
                              <BsEye
                                className="form-icon-eye"
                                size={'2rem'}
                                onClick={() => {
                                  setIsPasswordVisible(!isPasswordVisible);
                                }}
                              />
                            ) : (
                              <BsEyeSlash
                                className="form-icon-eye"
                                size={'2rem'}
                                onClick={() => {
                                  setIsPasswordVisible(!isPasswordVisible);
                                }}
                              />
                            )}
                          </div>

                          <div className="input-border"></div>
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className="errorText">
                                {formik.errors.password}
                              </div>
                            )}
                        </div>
                      </div>
                    )}

                    {/* 
                <h6
                  className="hoverableText"
                  style={{
                    padding: '0px 0px',
                    marginTop: '1rem',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    const state = {
                      selectedProtocol: null,
                      value: null,
                      subValue: null,
                      type: 'KeychainItem',
                      editType: 'new',
                      editMode: false,
                      page: 'listKeychainsPage',
                    };
                    navigate(`/database/new/keychains`, { state });
                  }}
                >
                  <span className="" style={{ marginRight: '8px' }}>
                    <BiPlus className="header-icon" data-testid="isBackBtn" />
                  </span>
                  {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}

                    {/* </span> 
                </h6> */}
                  </div>
                ) : (
                  <div
                    style={{
                      // display: 'flex',
                      // justifyContent: 'space-between',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <label
                        htmlFor="index"
                        className={`notification-css-title`}
                        style={{
                          fontSize: '1rem',
                        }}
                      >
                        Access Keychain:
                      </label>
                      <SearchableDropdown<KeychainItem>
                        id="searchDropdown"
                        options={keychains}
                        value={selectedKeychain}
                        labelField={(option) => option.name}
                        valueField={(option) => option.name}
                        keyField={(option) => option.uid}
                        onChange={(option: KeychainItem) => {
                          setSelectedKeychain(option);
                        }}
                        onClear={() => {
                          setSelectedKeychain(undefined);
                        }}
                        // multiSelect={true}
                        placeholder="Search keychains..."
                        containerStyle={{
                          // width: '90%',
                          flex: 1,
                          marginLeft: '16px',
                        }}
                        // notFoundText="No keychains found..."
                        // itemClassName="search-item"
                      />
                    </div>
                    <h6
                      className="hoverableText"
                      style={{
                        padding: '0px 0px',
                        marginTop: '1rem',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        const state = {
                          selectedProtocol: null,
                          value: null,
                          subValue: null,
                          type: 'KeychainItem',
                          editType: 'new',
                          editMode: false,
                          page: 'listKeychainsPage',
                        };
                        navigate(`/database/new/keychains`, { state });
                      }}
                    >
                      <span className="" style={{ marginRight: '8px' }}>
                        <BiPlus
                          className="header-icon"
                          data-testid="isBackBtn"
                        />
                      </span>
                      {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}
                      Create Keychain
                      {/* </span> */}
                    </h6>
                  </div>
                )}
              </>
            )}
            <p
              className="departmentItemText"
              style={{
                fontSize: '0.9rem',
                color: 'black',
                // maxWidth: '50%',
              }}
            >
              {message}
            </p>

            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              disabled={!isSaveValid}
              style={
                {
                  // marginTop: '3rem',
                }
              }
              onClick={handleButton}
            >
              Change
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default EditAccessModal;
