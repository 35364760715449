import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchCategoriesWithProtocols,
  fetchProtocols,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  getFormattedDateTime,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import {
  FaCaretDown,
  FaCaretUp,
  FaChevronRight,
  FaLock,
} from 'react-icons/fa6';
import { ProgressStatus, User } from '../../../../models';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import SearchBar from '../../../components/Search/SearchBar';
import CategoryItem from '../../../../data/model/CategoryItem';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown/MultiSelectDropdown';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { filter, set } from 'lodash';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { deleteProtocol } from '../../../../data/functions/ProtocolDB';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../store/actions';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import HMCheckbox from '../../../components/general/HMCheckbox';
import HMSwitch from '../../../components/general/HMSwitch';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListProtocols = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [protocols, setProtocols] = useState<ProtocolItem[]>(
    database.protocols
  );
  const categories: CategoryItem[] = database.categories;
  const user: User = useSelector((state: any) => state?.user);
  const scrollPosition = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [sort, setSort] = useState('name_asc');

  if (globals.debug) console.log('State search:', state.search);

  const filterOptions = [
    'Medications',
    'Infusions',
    'Equipment',
    'Electrical',
    'Paired Protocols',
    'Forms',
  ];

  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<ProtocolItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    ProtocolItem[]
  >([]);
  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < protocols.length; i++) {
      let owner = findDepartmentOwner(department, protocols[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [protocols, department]);

  const reloadDatabase = async () => {
    const response = await loadDatabase(department, undefined, true);
    if (response.type === ResponseType.Success) {
      let protocols = response.data.protocols;
      setProtocols(protocols);
      handleFilterChange(protocols);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          protocols: protocols,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = protocols.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchQuery) ||
          item.nickname.toLowerCase().includes(searchQuery) ||
          item.uid.toLowerCase().includes(searchQuery)
        );
      });
    }
    if (filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        //Check if the Filters (Department ID)
        return filters.some((filter) => {
          return item.departmentID === filter.id;
        });
      });
    }
    if (categoriesFilter.length > 0) {
      filteredList = filteredList.filter((item) => {
        return categoriesFilter.some(
          (category) => item.parent.uid === category.uid
        );
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a, b) => {
      if (a.parent.index === b.parent.index) {
        if (a.index === b.index) return a.name.localeCompare(b.name);
        return a.index - b.index;
      }
      return a.parent.index - b.parent.index;
    });

    filteredList = filteredList.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'modified_asc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return bDate.getTime() - aDate.getTime();
      } else if (sort === 'file_size_asc') return a.getSum() - b.getSum();
      else if (sort === 'file_size_desc') return b.getSum() - a.getSum();
      else return a.name.localeCompare(b.name);
    });

    setList(filteredList);
  };

  const handleSave = async () => {
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        reloadDatabase().then(() => {
          setItemDeactivationStatus([]);
        });
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    }
  };

  useEffect(() => {
    handleFilterChange(protocols);
  }, [searchQuery, filters, categoriesFilter, sort, protocols]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (protocol: ProtocolItem) => {
    const state = {
      selectedProtocol: protocol,
      editMode: false,
      backPage: 'list-protocols',
      search: { searchQuery, filters, categoriesFilter },
    };
    const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
    navigate(`/${formattedString}/protocol-detail`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  /* 10-09-23 Arul: function for handling the draft confirm modal make new button functionality*/
  const handleMakeNew = async () => {
    navigate(`/protocol/edit`);
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      else navigate(`/protocol/edit`);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  const columns = useMemo<GridColDef[]>(() => {
    let cols: GridColDef[] = [
      {
        field: 'input',
        flex: 0.25,
        disableColumnMenu: true,
        headerAlign: 'center',
        renderHeader: () => (
          <div style={{ marginRight: '10px' }}>
            <HMCheckbox
              checked={allCheckedBtn}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                setAllCheckedBtn(checked);
                if (checked) {
                  setSelectedItems([
                    ...protocols.filter(
                      (item) =>
                        item.status !== 'DEACTIVATED' &&
                        department.id === item.departmentID
                    ),
                  ]);
                } else {
                  setSelectedItems([]);
                }
              }}
            />
          </div>
        ),
        renderCell: (params) => {
          let item = list.find((item) => item.uid === params.value.uid);
          if (!item) return <div></div>;
          const departmentOwner = findDepartmentOwner(department, item);
          const adminAccess = hasAdminUserAccess(department, user, item);
          const isCheckbox =
            departmentOwner?.id === department.id &&
            item.status !== ProgressStatus.DEACTIVATED;

          const isChecked = selectedItems.find(
            (selectedItem) => selectedItem.uid === item.uid
          );

          let isSwitchChecked = item.status !== ProgressStatus.DEACTIVATED;
          if (
            itemDeactivationStatus.find((newItem) => newItem.uid === item.uid)
          )
            isSwitchChecked = !isSwitchChecked;
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                alignItems: 'center',
                height: '100%',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {isCheckbox ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <HMCheckbox
                    checked={isChecked != null}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      handleSelectionChange(item);
                    }}
                  />
                  <div
                    style={{
                      marginLeft: '2px',
                      border: '1px solid #E0E0E0',
                      height: '50%',
                      width: '1px',
                    }}
                  />
                </div>
              ) : (
                <>
                  {adminAccess && (
                    <HMSwitch
                      checked={isSwitchChecked}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        handleToggleEnabledUI(item as ProtocolItem);
                        // const updatedList = list.map((item) => {
                        //   if (item.uid === params.row.uid) {
                        //     return { ...item, checked }; // Update checked status for the row
                        //   }
                        //   return item;
                        // });
                        // setList(updatedList); // Update list with the checked status
                      }}
                    />
                  )}

                  {!(adminAccess && item.status !== 'DEACTIVATED') && (
                    <div>
                      <FaLock className="table-icon" color="#A3A3A3" />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        filterOperators: [],
      },
      {
        field: 'name',
        flex: 2,
        renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
        renderCell: (params) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {params.value.name}
            {params.value.status !== 'ACTIVE' && (
              <Status
                status={params.value.status}
                style={{ marginLeft: '8px' }}
              />
            )}
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
        filterable: false,
      },

      {
        field: 'modified_date',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Modified Date</div>
        ),
        filterable: false,
      },
      {
        field: 'modified_by',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Modified By</div>
        ),
        renderCell: (params) => {
          let user = params.value?.modifiedBy;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {user ? user.firstName + ' ' + user.lastName : 'Hinckley Medical'}
            </div>
          );
        },
        sortComparator: (v1, v2) => {
          const user1 = v1.modifiedBy;
          const user2 = v2.modifiedBy;
          return user1?.firstName.localeCompare(user2?.firstName || '') || 0;
        },
        filterable: false,
      },
    ];

    if (department.allSubDeps && department.allSubDeps.length > 0) {
      cols.push({
        field: 'department_subs',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Department Subs</div>
        ),
        renderCell: (params) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>{params.value.count + '/' + params.value.total}</span>
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.count - v2.count,
        filterable: false,
      });
    }
    cols.push({
      field: 'owner',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Owner</div>,
      renderCell: (params) => {
        if (department) {
          const departmentOwner = findDepartmentOwner(department, params.value);
          return (
            <div
              style={{
                display: 'flex',
                // justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {departmentOwner && (
                <OwnerImage
                  owner={departmentOwner}
                  // isLocked={!adminAccess}
                  size={32}
                />
              )}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
      sortable: true,
      sortComparator: (v1, v2) => {
        const owner1 = findDepartmentOwner(department, v1);
        const owner2 = findDepartmentOwner(department, v2);
        return owner1?.name.localeCompare(owner2?.name || '') || 0;
      },
      filterable: false,
    });
    cols.push({
      field: 'file_size',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>File Size</div>,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{params.value.sum}</span>
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.sum - v2.sum,
      filterable: false,
    });

    return cols;
  }, [
    department,
    database.users,
    list,
    itemDeactivationStatus,
    selectedItems,
    allCheckedBtn,
  ]);

  const rows = useMemo(() => {
    return list.map((item: ProtocolItem) => {
      const itemSubs = department.allSubDeps
        ? !item.isRestrictive
          ? department.allSubDeps.length + 1
          : item.pairedDepIDs?.length
        : -1;
      return {
        id: item.uid,
        input: item,
        name: {
          name: item.name,
          status: item.status,
        },
        modified_date: getFormattedDate(
          item?.model?.updatedAt ? item?.model?.updatedAt : new Date(),
          true
        ),
        modified_by: item,
        department_subs: {
          count: department.allSubDeps && itemSubs,
          total: department.allSubDeps && department.allSubDeps.length + 1,
        },
        owner: item,
        file_size: {
          sum: item.getSum(),
        },
      };
    });
  }, [list, department]);

  const handleSelectionChange = (protocol: ProtocolItem) => {
    if (selectedItems.find((item) => item.uid === protocol.uid)) {
      setSelectedItems((prevItems: ProtocolItem[]) => {
        const updatedItems = prevItems.filter(
          (item: ProtocolItem) => item.uid !== protocol.uid
        );
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, protocol];
      let available = protocols.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setAllCheckedBtn(true);
      } else if (allCheckedBtn) {
        setAllCheckedBtn(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication using deleteProtocol
      let response = await deleteProtocol(
        department,
        currentMedication,
        user,
        undefined,
        true,
        true
      );

      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted protocol: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: ProtocolItem[]) => {
          const updatedItems = prevItems.filter(
            (item: ProtocolItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage(
          `Failed to delete protocol: ${currentMedication.name}`
        );
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database after each deletion
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete protocol.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          // Use deleteProtocol for each deletion
          let response = await deleteProtocol(
            department,
            parmMedication,
            user,
            true,
            true
          );

          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(`Successfully deleted ${successCount} protocols.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} protocols.`);
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete protocols.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function for single deletion
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleToggleEnabledUI = async (item: ProtocolItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  const getRowClassName = useCallback(
    (params: any) => {
      const rowItem = list.find((item) => item.uid === params.id);
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      const adminAccess = hasAdminUserAccess(department, user, rowItem);

      // If the row is selected, give it the selected class.
      if (isSelected) {
        return 'selectedRow';
      }
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactiveRow';
      }

      // Default return for other cases
      return 'white';
    },
    [department, list, selectedItems, user]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Medication?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} protocols?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            navigate(`/protocol/edit`);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Protocols: ' + list.length + ' items'
            : 'Protocols: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        page={department.name}
        rightSideBtn={'edit'}
        isEditIcon={true}
        handleSave={() => handleSave()}
        handleCancelEdit={() => {
          console.log('Cancel Edit');
          setItemDeactivationStatus([]);
          setProtocols([...protocols]);
          handleFilterChange([...protocols]);
        }}
        handleEdit={() => {
          handleCheckIsDraft();
        }}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <Row
        style={{
          marginBottom: '10px',
        }}
      >
        <Col sm={6}>
          <SearchBar
            value={searchQuery}
            containerStyle={{ width: '100%' }}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Protocols...'}
          />
        </Col>
        <Col sm={6}>
          {allDepartmentOwners.length > 1 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '0px 10px',
              }}
            >
              <ToggleButtonGroup
                value={viewing}
                exclusive
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newAlignment: string | null
                ) => setViewing(newAlignment)}
                aria-label="owner"
                sx={{
                  padding: '0px',
                }}
              >
                <Tooltip title={'View All Protocols'} placement="bottom" arrow>
                  <ToggleButton
                    value="all"
                    aria-label="all"
                    autoCapitalize="none"
                    sx={{
                      padding: '0px 8px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                    onChange={() => {
                      setViewing('all');
                      setFilters([]);
                    }}
                  >
                    All
                  </ToggleButton>
                </Tooltip>
                {allDepartmentOwners.map((owner: DepartmentItem) => (
                  <ToggleButton
                    value={owner.id}
                    aria-label={owner.name}
                    autoCapitalize="none"
                    onChange={() => {
                      setViewing(owner.id);
                      setFilters([owner]);
                    }}
                    sx={{
                      padding: '4px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                  >
                    <Tooltip title={owner.name} placement="bottom" arrow>
                      <img
                        className="no-select owner-logo"
                        src={owner.logoVerifiedUrl ?? ''}
                        alt="Agency Logo"
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                    </Tooltip>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          )}

          {/* <MultiSelectDropdown<CategoryItem>
            title={'Categories'}
            options={categories}
            initialSelectedItems={categoriesFilter}
            labelField={(option: CategoryItem) => option.name}
            keyField={(option: CategoryItem) => option.uid}
            onSelected={(selected: CategoryItem[]) => {
              setCategoriesFilter(selected);
            }}
          />
          <MultiSelectDropdown<string>
            style={{ margin: '0px 10px' }}
            title={'Filters'}
            options={filterOptions}
            initialSelectedItems={filters}
            labelField={(option: string) => option}
            keyField={(option: string) => option}
            onSelected={(selected: string[]) => {
              setFilters(selected);
            }}
          /> */}
        </Col>
      </Row>

      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[25, 50, 100]}
          // checkboxSelection
          onRowClick={(params: any) =>
            //CHeck if the row is a checkbox
            // console.log(params)
            handleItemClick(list.find((item) => item.uid === params.id))
          }
          // onRowSelectionModelChange={handleSelectionChange}
          // rowSelectionModel={selectedItems.map(
          //   (item: ProtocolItem) => item.uid
          // )}
          getRowClassName={getRowClassName}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              margin: 0,
            },
            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .selectedRow': {
              backgroundColor: '#E0EADD !important',
            },

            '& .inactiveRow': {
              backgroundColor: '#f2f2f2',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListProtocols;
