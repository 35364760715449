import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Contact, User, UserStatus } from '../../../../../models';

import DepartmentItem, {
  LIMITED_ADMIN,
  RESTRICTED_ADMIN,
} from '../../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import ProtocolHeader from '../../ProtocolHeader';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { handleGetDepartment } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { UserType } from '../../../../../models';
import { Button, Col, Row } from 'react-bootstrap';
import {
  generateTemporaryPassword,
  getFormattedDateTime,
  globals,
  hasAdminUserAccess,
} from '../../../../_global/common/Utils';
import { Dropdown } from 'primereact/dropdown';
import {
  adminUserTypes,
  genUserTypes,
} from '../../../../_global/constants/Protocol_constants';
import UserStatusUI from '../../../../components/UserStatus/UserStatus';
import Loading from '../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  editUser,
  fetchUsers,
  unsubscribeUser,
  updateUserStatus,
} from '../../../../../data/functions/UserDB';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { FaChevronDown } from 'react-icons/fa6';
import ActionsModal from '../../../settings/details/ActionsModal';
import { Log } from '../../../../../API';
const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});

const cognito = new AWS.CognitoIdentityServiceProvider();
const lambda = new AWS.Lambda();

export const getEmailByCognitoId = async (cognitoId: string) => {
  try {
    const userData = await cognito
      .adminGetUser({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: cognitoId,
      })
      .promise();
    const email = userData.UserAttributes.find(
      (attr: any) => attr.Name === 'email'
    ).Value;

    return email || null;
  } catch (error) {
    if (globals.debug) console.error('Error fetching user data:', error);
    return null;
  }
};

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const CreateEditUser = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const searchState = state.searchState;
  const currentUser: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [list, setList] = useState<Log[]>([]);
  const user: User = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database.department;
  const parmUser: User = state.value;
  const availableDeps = useMemo(() => {
    if (department.isTopEnabled || user.type === UserType.ADMIN)
      return [department, ...(department.allSubDeps || [])];
    return department.allSubDeps || [];
  }, [department, user]);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [isPermanentlyDelete, setIsPermanentlyDelete] = useState(
    parmUser ? parmUser.status === UserStatus.SUSPENDED : false
  );
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [depAdmins, setDepAdmins] = useState<DepartmentItem[]>([]);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [isWarningModal, setIsWarningModal] = useState(false);

  const newUserEmailRef = useRef('');
  const resetPasswordRef = useRef('');
  const newPasswordRef = useRef('');

  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('reset');
  const [isActionsModal, setIsActionsModal] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    if (isEmailSent) {
      resetPasswordRef.current = '';
      newPasswordRef.current = '';
      newUserEmailRef.current = '';
      setIsEmailSent(false); // Reset the state so the effect doesn’t run again unintentionally
    }
  }, [isEmailSent]);

  const formik = useFormik({
    initialValues: {
      id: parmUser ? parmUser.id : '',
      firstName: parmUser ? parmUser.firstName : '',
      lastName: parmUser ? parmUser.lastName : '',
      type: parmUser ? parmUser.type : UserType.USER,
      username: parmUser ? parmUser.cognitoID : '',
      departmentID: parmUser ? parmUser.departmentID : department.id,
      depAdminsIDs: parmUser && parmUser.depAdmins ? parmUser.depAdmins : [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      username: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      pairedDeps: Yup.array(),
      departmentID: Yup.string().required('Required'),
      depAdminsIDs: Yup.array(),
    }),
    onSubmit: async (values) => {
      let result;
      if (parmUser == null)
        result = {
          type: ResponseType.Failure,
          data: 'TODO CANNOT CREATE USER YET',
        };
      else result = await editUser(values, pairedDeps, depAdmins, department);

      if (result.type === ResponseType.Success) {
        let user = result.data as User;
        if (globals.debug)
          console.log('User', parmUser ? ' editted' : ' created', user);
        //  check if previous user type was different from the new user type
        handleUserTypeChange(parmUser, user);
        await reloadDatabase();

        navigate(`/database/list-users`, {
          state: { department: department, database, searchState },
        });
      } else {
        if (globals.debug) console.log('Create/Edit user failed', result.data);
      }
    },
  });

  const handleUserTypeChange = async (oldUser: User, newUser: User) => {
    try {
      if (oldUser && oldUser.type !== newUser.type) {
        if (globals.debug)
          console.log(
            'User type changed from',
            oldUser.type,
            'to',
            newUser.type
          );

        const payload = {
          isUpdateType: true,
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          userName: newUser.cognitoID,
          newType: newUser.type,
          previousType: oldUser.type,
        };

        const params = {
          FunctionName: 'update-user-type',
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify(payload),
        };

        try {
          const result = await lambda.invoke(params).promise();
          if (globals.debug)
            console.log('Lambda invoked successfully:', result);
        } catch (error) {
          setIsWarningModal(true);
          console.error('Failed to invoke Lambda:', error);
        }
      }
    } catch (error) {
      setIsWarningModal(true);
      console.error('Failed to change user type:', error);
    }
  };

  useEffect(() => {
    if (parmUser.pairedDepIDs && availableDeps) {
      let ids = parmUser.pairedDepIDs;
      let l = [...availableDeps];
      l = l.filter((dep) => {
        return ids.some((d: string) => d === dep.id);
      });
      setPairedDeps(l);
    }
    if (parmUser.depAdmins) {
      let ids = parmUser.depAdmins;
      let l = [...availableDeps];
      l = l.filter((dep) => {
        return ids.some((d: string) => d === dep.id);
      });
      setDepAdmins(l);
    }
  }, [parmUser, availableDeps]);

  useEffect(() => {
    if (availableDeps) {
      let l = [...availableDeps];
      if (department.isTopEnabled) l = [department, ...l];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [availableDeps, pairedDeps]);

  const reloadDatabase = async (): Promise<DatabaseResponse | null> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await fetchUsers(database.department);
    if (resp.type === ResponseType.Success) {
      const users: User[] = resp.data as User[];
      dispatch<any>(
        handleGetDepartment({
          ...database,
          users: users,
        })
      );
      return { ...database, users: users } as DatabaseResponse;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return null;
    }
  };

  // useEffect(() => {
  //     let l = [...database.users];
  //     setList(l);
  // }
  // , [parmUser, database]);

  const handleBack = () => {
    navigate(`/database/list-users`, {
      state: { department: department, database, searchState },
    });
  };

  const RenderUser = ({ item, index }: any) => {
    let isThis = parmUser && item.uid === parmUser.id;
    let user = item as User;
    return (
      <div key={index}>
        <div
          className="departmentItem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            padding: '10px 10px',
            borderBottom:
              index !== list.length - 1 ? '1px solid #E0E0E0' : 'none',
            cursor: 'pointer',
          }}
        >
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0 }}
          >
            {index + 1}: {user.firstName + ' ' + user.lastName}
            {isThis ? ' (This)' : ''}
          </div>
          {/* <div className="headerTextLight" style={{fontSize: '14px', margin: 0, fontWeight: '500'}}>{user.number}</div> */}
        </div>
      </div>
    );
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    if (pairedDeps.length < 30) {
      let l = [...pairedDeps, option];
      l.sort((a, b) => a.name.localeCompare(b.name));
      setPairedDeps(l);
    }
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const handleAddDepAdmin = (option: DepartmentItem) => {
    let l = [...depAdmins, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setDepAdmins(l);
  };

  const handleRemoveDepAdmin = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = depAdmins.filter((d: DepartmentItem) => d.id !== option.id);
    setDepAdmins(l);
  };

  const handleClearDepAdmins = () => {
    setDepAdmins([]);
  };

  const handleUnsubscribe = async () => {
    let result = await unsubscribeUser(department, parmUser);
    if (result.type === ResponseType.Success) {
      console.log('User unsubscribed');
      handleBack();
    } else {
      console.error('Error unsubscribing user:', result.data);
    }
  };

  const handleUpdateUser = async (status: UserStatus) => {
    let promises: Promise<Response>[] = [];
    setIsLoading(true);
    promises.push(updateUserStatus(parmUser, status));
    promises.push(updateCognitoUser(parmUser.cognitoID, status));

    let results = await Promise.all(promises);
    if (results[0] && results[0].type === ResponseType.Success) {
      if (globals.debug) console.log('User status updated');
    } else {
      console.error('Error updating user status:', results[0].data);
    }

    if (results[1] && results[1].type === ResponseType.Success) {
      if (globals.debug) console.log('User deleted');
    } else {
      console.error('Error deleting user:', results[1].data);
    }
    setIsLoading(false);

    let newDB = await reloadDatabase();
    navigate(`/database/list-users`, {
      state: {
        department: department,
        database: newDB ? newDB : database,
        searchState,
      },
    });
  };

  const updateCognitoUser = async (
    username: string,
    status: UserStatus
  ): Promise<Response> => {
    try {
      const url =
        process.env.REACT_APP_API_GATEWAY_URL_USER ??
        (function () {
          throw new Error('API URL is not defined');
        })();
      if (globals.debug) console.log('API URL:', url);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          action:
            status === UserStatus.ACTIVE
              ? 'enable'
              : status === UserStatus.SUSPENDED
                ? 'disable'
                : 'delete',
        }),
      });

      if (!response.ok) {
        throw new Error('API call failed with status ' + response.status);
      }
      if (globals.debug) console.log('API call successful', response);
      if (globals.debug) console.log('Response Bdy:', response.body);
      const data = await response.json();
      if (globals.debug) console.log('Response data:', data);
      if (globals.debug) console.log('Response body:', data.body);
      if (globals.debug) console.log('Response body', data.message);
      return {
        type: ResponseType.Success,
        data: data.message,
      };
    } catch (error) {
      console.error('Error calling the API:', error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  const isTopDepartment = useMemo(() => {
    return department.parentDep == null;
  }, [department]);

  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, user);
  }, [department, user]);

  const isSaveActive = useMemo(() => {
    let changed = false;
    let prevSubsIDs = parmUser.pairedDepIDs || [];
    let prevDepIDs = parmUser.depAdmins || [];

    if (pairedDeps.length > globals.MAX_SUB_DEPARTMENTS) return false;

    //Check if every ID is in the pairedDeps
    if (isTopDepartment && pairedDeps && prevSubsIDs) {
      if (pairedDeps.length !== prevSubsIDs.length) changed = true;
      else {
        let ids = prevSubsIDs;
        if (ids) {
          ids.forEach((id: string) => {
            if (!pairedDeps.some((d: DepartmentItem) => d.id === id)) {
              changed = true;
            }
          });
        }
      }
    }
    //Check if every ID is in the depAdmins
    if (isTopDepartment && depAdmins && prevDepIDs) {
      if (depAdmins.length !== prevDepIDs.length) changed = true;
      else {
        let ids = prevDepIDs;
        if (ids) {
          ids.forEach((id: string) => {
            if (!depAdmins.some((d: DepartmentItem) => d.id === id)) {
              changed = true;
            }
          });
        }
      }
    }
    return changed || (formik.dirty && formik.isValid);
  }, [
    isTopDepartment,
    pairedDeps,
    formik.dirty,
    formik.isValid,
    parmUser?.pairedDepIDs,
    depAdmins,
    parmUser?.depAdmins,
  ]);

  const isDeleteButton = useMemo(() => {
    return (
      parmUser && parmUser.status !== UserStatus.DELETED && isTopDepartment
    );
  }, [parmUser, isTopDepartment]);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const handleSetUserPassword = async (isPermanent: boolean) => {
    try {
      setIsLoading(true);
      const cognitoParams = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: formik.values.username,
        Password: isPermanent
          ? newPasswordRef.current
          : resetPasswordRef.current,
        Permanent: isPermanent,
      };

      const setPasswordPromise = new Promise((resolve, reject) => {
        cognito.adminSetUserPassword(cognitoParams, (err: any, data: any) => {
          if (err) {
            if (globals.debug) console.error('Error setting password:', err);
            reject(err);
          } else {
            if (globals.debug) console.log('Password set successfully:', data);
            resolve(data);
          }
        });
      });

      await setPasswordPromise;
      await handleSendEmail(isPermanent ? 'newPassword' : 'reset');
      setIsEmailSent(true);
      setIsSuccessModal(true); // Show success modal only if both actions succeed
    } catch (error) {
      console.error('Error setting password or sending email:', error);
      setIsWarningModal(true); // Show warning modal if any error occurs
    } finally {
      setIsLoading(false);
    }
  };

  // Function to reset password to a temporary one
  const handleResetPassword = async () => {
    const tempPassword = generateTemporaryPassword();
    resetPasswordRef.current = tempPassword;
    handleSetUserPassword(false);
  };

  // Function to create a permanent new password
  const handleCreateNewPassword = async (password: string) => {
    newPasswordRef.current = password;
    handleSetUserPassword(true);
  };

  //  Change the user email address, this is a reverse 2 step process. First we send an email to the user with the new email address, then we update the email address in cognito
  const handleChangeEmail = async (newEmail: string) => {
    try {
      setIsLoading(true);

      newUserEmailRef.current = newEmail;
      // Step 1: Send email to user previous email with new email address
      await handleSendEmail('changeEmail');
      // Step 2: Update email address in cognito
      const cognitoParams = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Username: formik.values.username,
        UserAttributes: [
          {
            Name: 'email',
            Value: newUserEmailRef.current,
          },
          {
            Name: 'email_verified',
            Value: 'true', // Mark email as verified to udpate email address in cognito
          },
        ],
      };

      const updateUserAttributesPromise = new Promise((resolve, reject) => {
        cognito.adminUpdateUserAttributes(
          cognitoParams,
          (err: any, data: any) => {
            if (err) {
              if (globals.debug) console.error('Error changing email:', err);
              reject(err);
            } else {
              if (globals.debug)
                console.log('Email changed successfully:', data);
              resolve(data);
            }
          }
        );
      });

      await updateUserAttributesPromise;
      // Step 3: Send email to user new email with new email address
      await handleSendEmail('changeEmail');
      setIsEmailSent(true);
      setIsSuccessModal(true); // Show success modal only if both actions succeed
    } catch (error) {
      if (globals.debug) console.error('Error changing email:', error);
      setIsWarningModal(true); // Show warning modal if any error occurs
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmail = async (type: string) => {
    let userEmail = await getEmailByCognitoId(parmUser?.cognitoID);
    if (parmUser && userEmail) {
      let payload = {};
      if (type === 'newUser') {
        payload = {
          email: userEmail,
          actionType: 'USER_CREATION',
          username: parmUser.cognitoID,
          fullName: parmUser.firstName + ' ' + parmUser.lastName,
          departmentName: department.name,
          tempPassword: resetPasswordRef.current,
        };
      } else if (type === 'reset') {
        payload = {
          email: userEmail,
          actionType: 'PASSWORD_RESET',
          username: parmUser.cognitoID,
          fullName: parmUser.firstName + ' ' + parmUser.lastName,
          departmentName: department.name,
          tempPassword: resetPasswordRef.current,
        };
      } else if (type === 'newPassword') {
        payload = {
          email: userEmail,
          actionType: 'PASSWORD_CHANGE',
          username: parmUser.cognitoID,
          fullName: parmUser.firstName + ' ' + parmUser.lastName,
          departmentName: department.name,
          newPassword: newPasswordRef.current,
        };
      } else if (type === 'changeEmail') {
        payload = {
          email: userEmail,
          actionType: 'EMAIL_CHANGE',
          username: parmUser.cognitoID,
          fullName: parmUser.firstName + ' ' + parmUser.lastName,
          departmentName: department.name,
          newEmail: newUserEmailRef.current,
        };
      }

      const params = {
        FunctionName: 'sendEmailToUsers', // Ensure this matches your Lambda's actual name
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify(payload),
      };

      try {
        const result = await lambda.invoke(params).promise();
        const parsedResult = JSON.parse(result.Payload);

        if (parsedResult && parsedResult.statusCode === 200) {
          if (globals.debug) console.log('Email sent successfully');
        } else {
          throw new Error('Error invoking Lambda function');
        }
      } catch (error) {
        throw new Error('Error invoking Lambda function');
      }
    }
  };
  const availableDepAdmins = useMemo(() => {
    return availableDeps.filter((d: DepartmentItem) => !depAdmins.includes(d));
  }, [availableDeps, depAdmins]);

  if (!isLoggedIn) {
    navigate('/login');
    return <div></div>;
  }

  return (
    <div className="screen-container">
      <ActionsModal
        isVisible={isActionsModal}
        department={department}
        handleClose={() => {
          setIsActionsModal(false);
        }}
        handleSubmit={(response: string | boolean, type: string) => {
          if (type === 'reset') {
            handleResetPassword();
          } else if (type === 'newPassword') {
            handleCreateNewPassword(response as string);
          } else if (type === 'changeEmail') {
            handleChangeEmail(response as string);
          }
          setIsActionsModal(false);
        }}
        heading={
          selectedAction === 'reset'
            ? 'Reset Password'
            : selectedAction === 'newPassword'
              ? 'Create New Password'
              : 'Change Email Address'
        }
        primaryDescription={
          selectedAction === 'reset'
            ? 'This will reset the user password to a temporary one. The user will be required to change it upon next login.'
            : selectedAction === 'newPassword'
              ? 'This will create a new permanent password for the user. The user will not be required to change it upon next login.'
              : 'This will change the user email address.'
        }
        buttonTextHeading={
          selectedAction === 'reset'
            ? 'Reset Password'
            : selectedAction === 'newPassword'
              ? 'New Password'
              : 'Change Email Address'
        }
        isButtonTextHeading={selectedAction !== 'reset'}
        value={newPasswordRef.current}
        type={selectedAction}
      />

      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Error: User Update Failed"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          isDeleteBtn={true}
          handleSubmit={() => setIsWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Please try again later or contact Hinckley Medical Representative at support@hinckleymed.com."
        />
      )}

      {isSuccessModal && (
        <ConfirmModal
          isVisible={isSuccessModal}
          title="Success: User Update Successful"
          handleClose={() => {
            setIsSuccessModal(false);
          }}
          isSingleBtn={true}
          handleSubmit={() => setIsSuccessModal(false)}
          secondaryBtnName="OK"
          primaryDescription="The user's information has been successfully updated."
        />
      )}

      {isLoading && <Loading type="bubbles" />}
      {parmUser && (
        <ConfirmModal
          isVisible={isDelete}
          title={isPermanentlyDelete ? 'Delete User?' : 'Disable User?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={() => {
            handleUpdateUser(
              isPermanentlyDelete ? UserStatus.DELETED : UserStatus.SUSPENDED
            );
            setIsEnable(false);
          }}
          handleCheck={(checked: boolean) => {
            setIsPermanentlyDelete(checked);
          }}
          isDeleteBtn={true}
          isPermanentlyDeleteBtn={parmUser.status !== UserStatus.SUSPENDED}
          primaryBtnName="Cancel"
          secondaryBtnName={isPermanentlyDelete ? 'Delete' : 'Disable'}
          primaryDescription={
            isPermanentlyDelete
              ? 'Are you sure you want to DELETE ' +
                parmUser.firstName +
                ' ' +
                parmUser.lastName +
                '?'
              : 'Are you sure you want to disable ' +
                parmUser.firstName +
                ' ' +
                parmUser.lastName +
                ''
          }
          secondaryDescription={
            isPermanentlyDelete
              ? 'This is a PERMANENT action and cannot be undone. Deleting user will remove all access to the OneDose App and OneDose Admin Console.'
              : 'Disabling user will make it so he cannot access the OneDose App or OneDose Admin Console. You can re-enable the user in the future.'
          }
        />
      )}
      {parmUser && (
        <ConfirmModal
          isVisible={isUnsubscribe}
          title={'Unsubscribe User from ' + department.name + '?'}
          handleClose={() => {
            setIsUnsubscribe(false);
          }}
          handleSubmit={() => {
            handleUnsubscribe();
            setIsEnable(false);
          }}
          primaryBtnName="Cancel"
          isDeleteBtn={true}
          secondaryBtnName={'Unsubscribe'}
          primaryDescription={
            'Are you sure you want to unsubscribe ' +
            parmUser.firstName +
            ' ' +
            parmUser.lastName +
            ' from ' +
            department.name +
            '? This will remove the users access to this agency.'
          }
          secondaryDescription={
            'If they are subscribed to other agencies, they will still have access to those. If this is the last agency on their account, their account will move to suspended.'
          }
        />
      )}
      {parmUser && (
        <ConfirmModal
          isVisible={isEnable}
          title={'Re-Enable User?'}
          handleClose={() => {
            setIsEnable(false);
          }}
          handleSubmit={() => {
            handleUpdateUser(UserStatus.ACTIVE);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName={'Enable'}
          primaryDescription={
            'Do you want to re-enable ' +
            parmUser.firstName +
            ' ' +
            parmUser.lastName +
            '?'
          }
          secondaryDescription={
            'This will give this user access again to the OneDose App and OneDose Admin Console.'
          }
        />
      )}
      <div className="fixedHeader">
        <ProtocolHeader
          name={parmUser ? 'Edit User' : 'New User'}
          type={'protocol'}
          page={'Users'}
          isCreateButton={parmUser ? false : true}
          isSaveButton={
            adminAccess &&
            adminLevel > LIMITED_ADMIN &&
            parmUser &&
            parmUser.status !== UserStatus.DELETED
              ? true
              : false
          }
          isUnsubscribeIcon={!isTopDepartment}
          handleUnsubscribe={() => {
            setIsUnsubscribe(true);
          }}
          isUnsubscribeActive={true}
          isDeleteButton={isDeleteButton}
          handleDelete={() => {
            setIsDelete(true);
          }}
          isSaveActive={isSaveActive}
          isBackButton={true}
          isDotButton={true}
          isCreateActive={formik.dirty && formik.isValid}
          handleCancel={handleBack}
          handleCreate={() => formik.handleSubmit()}
          handleSave={() => formik.handleSubmit()}
          handleCancelEdit={handleBack}
          isEnableButton={parmUser.status === UserStatus.SUSPENDED}
          handleEnable={() => {
            setIsEnable(true);
          }}
        />
      </div>
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">User Information</h5>
          <div className="input-container roundBorder">
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
              }}
              className="ketamine-general-label"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Status:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  <UserStatusUI
                    status={parmUser.status}
                    style={{ fontSize: '14px' }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Username:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {parmUser.cognitoID}
                </div>
              </div>
              {parmUser.updatedAt && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedDateTime(parmUser.updatedAt, false)}
                  </div>
                </div>
              )}
              {parmUser.createdAt && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Created Date:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedDateTime(parmUser.createdAt, false)}
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {parmUser.id}
                    <span>
                      {isCopied && isCopied === parmUser.id ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(parmUser.id as string, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Dep Admin:</div>
                  <div
                    style={{
                      marginRight: '10px',
                      fontWeight: '500',
                      paddingBottom: '10px',
                    }}
                  >
                    {parmUser.depAdmins ? parmUser.depAdmins.length : '0'}
                  </div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {parmUser.depAdmins &&
                    parmUser.depAdmins.includes(department.id)
                      ? 'True'
                      : 'false'}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="FirstName" className="ketamine-general-label">
                First Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="FirstName"
                  name="FirstName"
                  data-testid="FirstName"
                  value={formik.values.firstName}
                  onChange={(e: any) => {
                    formik.setFieldValue('firstName', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="LastName" className="ketamine-general-label">
                Last Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="LastName"
                  name="LastName"
                  data-testid="LastName"
                  value={formik.values.lastName}
                  onChange={(e: any) => {
                    formik.setFieldValue('lastName', e.target.value);
                  }}
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="type" className={`notification-css-title`}>
            User Role
          </label>
          {currentUser.type !== UserType.ADMIN &&
            currentUser.type !== UserType.DEPT_ADMIN && (
              <p
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '0px', margin: 0 }}
              >
                Only Admins can change the user role.
              </p>
            )}
          <div className="input-container-we cursorPointer">
            <Dropdown
              id="type"
              name="type"
              data-testid="type"
              value={formik.values.type}
              options={
                currentUser.type === UserType.ADMIN
                  ? adminUserTypes
                  : genUserTypes
              }
              disabled={
                currentUser.type !== UserType.ADMIN &&
                currentUser.type !== UserType.DEPT_ADMIN
              }
              onChange={(e) => {
                formik.setFieldValue('type', e.value);
              }}
              style={{ width: '100%', borderRadius: '10px', margin: 0 }}
              className="dropDownIconWidth input-container-we"
            />
          </div>

          {/* Advanced Items */}
          <Accordion
            style={{
              borderRadius: '5px',
              boxShadow: 'none',
              alignContent: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              marginTop: '20px',
              backgroundColor: 'transparent',
              border: '1px solid #ccc',
            }}
          >
            <AccordionSummary
              expandIcon={<FaChevronDown />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Advanced Settings
            </AccordionSummary>
            <AccordionDetails>
              <label htmlFor="" className="notification-css-title">
                Actions:
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '6px' }}
              >
                Select an action from the dropdown below. You can reset the user
                password, create a new password, or change the user's email
                address.
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                  flexDirection: 'column',
                }}
              >
                {/* Material UI Select dropdown */}
                <Select
                  value={selectedAction}
                  onChange={(e) => setSelectedAction(e.target.value)}
                  style={{
                    marginBottom: '20px',
                  }}
                  sx={{
                    width: '100%',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #E0EADD',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 0, // Remove default border
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0, // Remove border on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0, // Remove border when focused
                    },
                    fontSize: '14px',
                  }}
                >
                  <MenuItem
                    className="menuItemFontSizeMedium"
                    value="reset"
                    sx={{
                      '&:hover': {
                        backgroundColor: '#E0EADD',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#E0EADD',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#E0EADD',
                      },
                      my: '2px',
                    }}
                  >
                    Reset Password
                  </MenuItem>
                  <MenuItem
                    className="menuItemFontSizeMedium"
                    value="newPassword"
                    sx={{
                      '&:hover': {
                        backgroundColor: '#E0EADD',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#E0EADD',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#E0EADD',
                      },
                      my: '2px',
                    }}
                  >
                    Create New Password
                  </MenuItem>
                  <MenuItem
                    className="menuItemFontSizeMedium"
                    value="changeEmail"
                    sx={{
                      '&:hover': {
                        backgroundColor: '#E0EADD',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#E0EADD',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#E0EADD',
                      },
                      my: '2px',
                    }}
                  >
                    Change Email Address
                  </MenuItem>
                </Select>

                {/* Conditional rendering based on selected action */}
                {selectedAction === 'reset' && (
                  <Button
                    variant="contained"
                    className="primary-button"
                    style={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      setIsActionsModal(true);
                    }}
                  >
                    Reset Password
                  </Button>
                )}

                {selectedAction === 'newPassword' && (
                  <>
                    <Button
                      variant="contained"
                      className="primary-button"
                      style={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onClick={() => {
                        setIsActionsModal(true);
                      }}
                    >
                      Create New Password
                    </Button>
                  </>
                )}

                {selectedAction === 'changeEmail' && (
                  <>
                    <Button
                      variant="contained"
                      className="primary-button"
                      style={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onClick={() => {
                        setIsActionsModal(true);
                      }}
                    >
                      Change Email Address
                    </Button>
                  </>
                )}
              </div>

              {user.type === UserType.ADMIN && (
                <div>
                  <label
                    htmlFor=""
                    className="ketamine-general-label"
                    style={{
                      marginTop: '20px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Developer Settings
                  </label>
                  <p
                    className="contentText greyText"
                    style={{
                      fontSize: '14px',
                      marginBottom: '10px',
                      marginLeft: '10px',
                    }}
                  >
                    Developer Only Section
                  </p>
                  <label
                    htmlFor="departmentID"
                    className="ketamine-general-label"
                  >
                    Department ID: <span className="required-field">*</span>
                    <span>
                      {isCopied && isCopied === formik.values.departmentID ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(formik.values.departmentID as string, e)
                          }
                        />
                      )}
                    </span>
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="departmentID"
                      name="departmentID"
                      data-testid="departmentID"
                      value={formik.values.departmentID}
                      onChange={(e: any) => {
                        formik.setFieldValue('departmentID', e.target.value);
                      }}
                    />
                    <div className="input-border"></div>
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="KetamineGeneral">
          {(formik.values.type === UserType.ADMIN ||
            formik.values.type === UserType.DEPT_ADMIN) &&
            availableDeps &&
            availableDeps.length > 0 && (
              <>
                <label htmlFor="" className={`notification-css-title`}>
                  <span
                    className="headerTextMargin"
                    style={{ fontSize: '16px', marginTop: '10px' }}
                  >
                    Department Admins: {depAdmins.length} /{' '}
                    {availableDeps.length}
                    <span
                      onClick={() =>
                        setDepAdmins(availableDeps as DepartmentItem[])
                      }
                    >
                      <div className="clickableText">Add All</div>
                    </span>
                  </span>
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontSize: '13px', marginLeft: '10px' }}
                >
                  This is a list of every department that will subscribe to the
                  protocol.
                </span>
                <SearchableDropdown<DepartmentItem>
                  id="searchDropdown"
                  options={availableDepAdmins}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.id}
                  multiSelect={true}
                  onChange={(option: DepartmentItem) =>
                    handleAddDepAdmin(option)
                  }
                  onClear={handleClearDepAdmins}
                  placeholder="Search department..."
                />
                {depAdmins.length === 0 && (
                  <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                    No paired departments...
                  </h6>
                )}
                <div
                  style={{
                    overflowY: 'auto',
                    maxHeight: '200px',
                    border: depAdmins.length === 0 ? '0px' : '1px solid #ccc',
                    borderRadius: '5px',
                    marginBottom: '20px',
                    marginTop: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ViewportList items={depAdmins}>
                    {(item: DepartmentItem, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '16fr 1fr',
                          padding: '6px 10px',
                          alignItems: 'center',
                          borderBottom:
                            index === pairedDeps.length - 1
                              ? ''
                              : '1px solid #ccc',
                          // borderTopLeftRadius: '6px',
                          // borderTopRightRadius: '6px',
                          // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                          // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        }}
                        className="listItem"
                      >
                        <div className="contentText">{item.name}</div>
                        <FaTimes
                          className="icon-cancel"
                          size={16}
                          onClick={(e) => handleRemoveDepAdmin(item, e)}
                        />
                      </div>
                    )}
                  </ViewportList>
                </div>
              </>
            )}

          {availableDeps && availableDeps.length > 1 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {pairedDeps.length} /{' '}
                  {Math.min(globals.MAX_SUB_DEPARTMENTS, availableDeps.length)}
                  {availableDeps.length <= globals.MAX_SUB_DEPARTMENTS ? (
                    <span
                      onClick={() =>
                        setPairedDeps(availableDeps as DepartmentItem[])
                      }
                    >
                      <div className="clickableText">Add All</div>
                    </span>
                  ) : (
                    <Tooltip
                      title={`You can only subscribe up to ${globals.MAX_SUB_DEPARTMENTS} departments. This is because OneDose requires to locally cache and sync every departments data.`}
                    >
                      <span
                        style={{
                          marginLeft: '6px',
                          color: '#616161',
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        ({availableDeps.length} Available)
                        <FaInfoCircle
                          size={12}
                          style={{ marginLeft: '4px', cursor: 'pointer' }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                disabled={pairedDeps.length >= globals.MAX_SUB_DEPARTMENTS}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
                showItems={10}
              />
              {pairedDeps.length >= globals.MAX_SUB_DEPARTMENTS && (
                <span
                  className="contentText"
                  style={{
                    fontSize: '13px',
                    marginLeft: '10px',
                    marginTop: '3px',
                    color: '#880808',
                    fontWeight: 'bold',
                  }}
                >
                  NOTE: Max {globals.MAX_SUB_DEPARTMENTS} departments reached
                </span>
              )}
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: '200px',
                  border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => handleRemoveDepartment(item, e)}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
          <h5 className="ketmine-header-text">
            {parmUser.firstName + ' ' + parmUser.lastName}'s Logs
          </h5>
          {list.length === 0 && (
            <div className="container">
              <div className="text" style={{ color: '#616161' }}>
                User has no logs...
              </div>
            </div>
          )}
          {list.length > 0 && (
            <div className="list-container">
              <ViewportList items={list}>
                {(item: Log, index: any) => {
                  return (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      {/* <RenderContact item={item} index={index} /> */}
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateEditUser;
