import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  DatabaseResponse,
  ResponseType,
  getDepartments,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { copyChecklistsFromDeptoDep } from '../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { getHashedPin } from '../../../../_global/common/Encrypt';
import { IoLockClosed } from 'react-icons/io5';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import './Audit.scss';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { ViewportList } from 'react-viewport-list';
import MedicationItem from '../../../../../data/model/MedicationItem';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import { globals, toTitleCase } from '../../../../_global/common/Utils';
import MedicationViewModal from './MedicationViewModal';
import { performAudit } from './PerformAudit';
import Loading from '../../../../components/Loading/Loading';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../../store/actions';
import {
  ElectricalShockOption,
  Medication,
  MedicationProtocol,
} from '../../../../../models';
import InfusionItem from '../../../../../data/model/InfusionItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { Storage } from 'aws-amplify';
import HMSwitch from '../../../../components/general/HMSwitch';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const AuditPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<
    MedicationItem | InfusionItem | ElectricalItem | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    department: undefined as DepartmentItem | undefined,
    database: undefined as DatabaseResponse | undefined,
    subDepartment: undefined as DepartmentItem | undefined,
    auditAllSubDepartments: false,
  });

  const reloadDatabase = async (department: DepartmentItem) => {
    const response = await loadDatabase(department, undefined, true);
    if (response.type === ResponseType.Success) {
      console.log('Database reloaded', response.data);
      const database = response.data;
      if (info.subDepartment == null) {
        dispatch<any>(handleGetDepartment(database));
      }
    }
  };

  const handleBack = () => {
    navigate('/actions');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments(false);
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadData = async (
    department: DepartmentItem,
    isSubDepartment: boolean = false
  ) => {
    const response = await loadDatabase(department, undefined, true);
    if (response.type === ResponseType.Success) {
      let db = response.data;
      console.log('Database loaded', db);
      if (isSubDepartment) {
        setInfo({
          ...info,
          database: db,
          subDepartment: department,
        });
      } else {
        setInfo({
          department: department,
          database: db,
          subDepartment: undefined,
        });
      }
    }
    setIsLoading(null);
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const handlePerformAudit = async (isUpdate: boolean) => {
    const department = info.subDepartment
      ? info.subDepartment
      : info.department;
    setIsLoading('Performing Audit on ' + department.name + '...');
    try {
      let response = await performAudit(
        department,
        info.database,
        isUpdate,
        info.auditAllSubDepartments
      );
      setIsLoading(null);
      if (response.type === ResponseType.Success) {
        if (globals.debug) console.log('Results: ', response.data);
        if (isUpdate) {
          await reloadDatabase(department);
          setModalState({
            isVisible: true,
            title: 'Audit Completed',
            primaryBtnName: 'Okay',
            primaryDescription: 'The audit has been completed successfully.',
            secondaryBtnName: 'Okay',
            secondaryDescription:
              'There was ' + response.data.length + ' items audited.',
          });
        } else if (response.data.length > 0) {
          setModalState({
            isVisible: true,
            title: 'Audit Required',
            primaryBtnName: 'Cancel',
            primaryDescription:
              'There has been detected issues with the audit. Would you like to continue with these changes?',
            secondaryBtnName: 'Continue',
            secondaryDescription:
              'There was ' + response.data.length + ' items audited.',
          });
        } else {
          setModalState({
            isVisible: true,
            title: 'Audit Results',
            primaryBtnName: 'Okay',
            primaryDescription: 'There was no changes required to change',
            secondaryBtnName: 'Okay',
            secondaryDescription:
              'There was ' + response.data.length + ' items audited.',
          });
        }
      } else {
        alert('Audit Failed');
      }
    } catch (error) {
      console.error('Error: ', error);
      alert('Audit Failed');
    }
  };

  const isSaveValid = useMemo(() => {
    return info.department !== undefined && info.database !== undefined;
  }, [info]);

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(
          item: MedicationItem | InfusionItem | ElectricalItem,
          index: number
        ) => {
          const lastIndex = data.length === index + 1;
          const items = item.subItems;

          let dbItems: any[] = [];
          if (item instanceof MedicationItem) {
            let med = item as MedicationItem;
            let dbMed = med.model;
            for (let i = 0; i < dbMed.protocolOptions.length; i++) {
              let protocol = dbMed.protocolOptions[i];
              for (let j = 0; j < protocol.options.length; j++) {
                dbItems.push(protocol.options[j]);
              }
            }
          } else if (item instanceof InfusionItem) {
            let drip = item as InfusionItem;
            let dbDrip = drip.model;
            for (let i = 0; i < dbDrip.dripOptions.length; i++) {
              let protocol = dbDrip.dripOptions[i];
              for (let j = 0; j < protocol.options.length; j++) {
                dbItems.push(protocol.options[j]);
              }
            }
          } else {
            let elec = item as ElectricalItem;
            for (let i = 0; i < elec.model.options.length; i++) {
              let option = elec.model.options[i];
              for (let range in option.ranges) {
                dbItems.push(option.ranges[range]);
              }
            }
          }

          return (
            <div
              className="result-list-item"
              key={index}
              style={{
                borderBottom: lastIndex ? 'none' : '1px solid #e0e0e0',
              }}
              onClick={() => {
                if (item instanceof MedicationItem) {
                  setSelectedItem(item);
                }
              }}
            >
              <span
                className="ketamine-general-label"
                style={{ flex: 1, fontWeight: '500' }}
              >
                {item.name}
              </span>
              <span className="ketamine-general-label" style={{}}>
                {items.length} / {dbItems.length} mapped
              </span>
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ListRenderProtocol = ({ data }: { data: ProtocolItem[] }) => {
    return (
      <ViewportList items={data}>
        {(item: ProtocolItem, index: number) => {
          const isPDFValid = (protocol: ProtocolItem) => {
            if (protocol.pdfUrl) {
              return Storage.get(protocol.pdfUrl, {
                level: 'public',
              });
            } else {
              return false;
            }
          };
          const lastIndex = data.length === index + 1;
          let result = isPDFValid(item);
          return (
            <div
              className="result-list-item"
              key={index}
              style={{
                borderBottom: lastIndex ? 'none' : '1px solid #e0e0e0',
              }}
              onClick={() => {
                if (item instanceof MedicationItem) {
                  setSelectedItem(item);
                }
              }}
            >
              <span
                className="ketamine-general-label"
                style={{ flex: 1, fontWeight: '500' }}
              >
                {item.name}
              </span>
              <span className="ketamine-general-label" style={{}}>
                {result != null ? 'valid' : 'INVALID'}
              </span>
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const TreatmentItem = ({ list, type }: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            expanded.includes(type) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(type)) {
              setExpanded(expanded.filter((item) => item !== type));
            } else {
              setExpanded([...expanded, type]);
            }
          }}
        >
          {expanded.includes(type) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <span className="ketamine-general-label" style={{ flex: 1 }}>
            {toTitleCase(type)}
          </span>
          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {list.length} items
          </span>
        </div>
        {type !== 'protocol' && expanded.includes(type) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={list} />
          </div>
        )}
        {type === 'protocol' && expanded.includes(type) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRenderProtocol data={list} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          if (modalState.title === 'Audit Required') {
            setModalState({ ...modalState, isVisible: false });
          } else {
            setModalState({ ...modalState, isVisible: false });
            // navigate('/database');
          }
        }}
        handleSubmit={() => {
          if (modalState.title === 'Audit Required') {
            handlePerformAudit(true);
          } else {
            setModalState({ ...modalState, isVisible: false });
            // navigate('/database');
          }
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={'Audit Page'}
        description={
          'This will review all of the pairings to confirm they are correct and remove any unused pairings.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Audit"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit(false);
        }}
      />
      <MedicationViewModal
        visible={selectedItem !== undefined}
        onClose={() => setSelectedItem(undefined)}
        medication={selectedItem as MedicationItem}
        db={info.database}
        displayProtID={true}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <label className="ketamine-general-label">
            Department: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to perform the audit on.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={list}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              setIsLoading('Loading ' + option.name + ' Data...');
              loadData(option);
            }}
            onClear={() => {
              setInfo({
                department: undefined,
                database: undefined,
              });
              setExpanded([]);
            }}
            placeholder="Search department..."
          />
        </div>
        {info.department && info.department.subDeps?.length > 0 && (
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {info.auditAllSubDepartments ? (
              <>
                <label className="ketamine-general-label">
                  Auditting {info.department.name} sub-departments:{' '}
                  {info.department.subDeps.length}
                </label>
                <p className="sidebarText" style={{ marginLeft: '10px' }}>
                  This will audit all sub-departments of the selected
                  department.
                </p>
              </>
            ) : (
              <>
                <label className="ketamine-general-label">
                  Optional Sub-Department:{' '}
                  <span className="required-field">*</span>
                </label>
                <p className="sidebarText" style={{ marginLeft: '10px' }}>
                  Select the sub-department you want to perform the audit on.
                </p>
                <SearchableDropdown<DepartmentItem>
                  id="searchDropdown"
                  options={info.department.subDeps.sort(
                    (a: DepartmentItem, b: DepartmentItem) =>
                      a.name.localeCompare(b.name)
                  )}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.id}
                  onChange={(option: DepartmentItem) => {
                    setIsLoading(
                      'Loading Sub-Department: ' + option.name + ' Data...'
                    );
                    loadData(option, true);
                  }}
                  onClear={() => {
                    setIsLoading(
                      'Loading ' + info.department.name + ' Data...'
                    );
                    loadData(info.department);
                    setExpanded([]);
                  }}
                  placeholder="Search sub-department..."
                />
              </>
            )}

            <label
              className="ketamine-general-label"
              style={{ marginTop: '1rem' }}
            >
              Audit All:{' '}
              <div style={{ marginTop: '-10px' }}>
                <HMSwitch
                  checked={info.auditAllSubDepartments}
                  onChange={(e, checked) =>
                    setInfo({
                      ...info,
                      auditAllSubDepartments: checked,
                    })
                  }
                  tooltipText="If checked, the audit will be performed on all sub-departments of the selected department."
                />
              </div>
            </label>
          </div>
        )}
      </div>

      {info.database && info.department && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {(info.department || info.subDepartment) &&
              (info.department?.logoVerifiedUrl ||
                info.subDepartment?.logoVerifiedUrl) && (
                <img
                  className="large-dep-logo"
                  src={
                    info.subDepartment
                      ? info.subDepartment.logoVerifiedUrl
                      : info.department.logoVerifiedUrl
                  }
                  alt="Agency Logo"
                />
              )}
            {(info.department || info.subDepartment) && (
              <div
                className="large-dep-name"
                style={{
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              >
                {info.subDepartment
                  ? info.subDepartment.name
                  : info.department.name}
              </div>
            )}
          </div>
          <div
            className="input-container roundBorder "
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
            }}
          >
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>ID:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.subDepartment
                  ? info.subDepartment.id
                  : info.department.id}
                <span>
                  {isCopied &&
                  (isCopied === info.subDepartment?.id ||
                    isCopied === info.department.id) ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(
                          info.subDepartment
                            ? info.subDepartment.id
                            : info.department.id,
                          e
                        )
                      }
                    />
                  )}
                </span>
              </div>
            </div>
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>Location:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.subDepartment
                  ? info.subDepartment.location
                  : info.department.location}
              </div>
            </div>
          </div>
          <TreatmentItem list={info.database.protocols} type="protocol" />
          <TreatmentItem list={info.database.medications} type="medication" />
          <TreatmentItem list={info.database.infusions} type="infusions" />
          <TreatmentItem list={info.database.electrical} type="electricals" />
        </div>
      )}
    </div>
  );
};

export default AuditPage;
