import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import { GiElectric } from 'react-icons/gi';
import { SiEquinixmetal } from 'react-icons/si';
import {
  DatabaseResponse,
  fetchAllRecordsByMonthYear,
} from '../../../data/AmplifyDB';
import {
  Drip,
  ElectricalShock,
  Equipment,
  Form,
  Medication,
  Protocol,
  Vitals,
} from '../../../models';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { DataStore } from 'aws-amplify';
import SearchBar from '../../components/Search/SearchBar';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { set } from 'lodash';
import Status from '../../components/ProgressStatus/ProgressStatus';
import { ProgressStatus } from '../../../API';
import { FaBoltLightning, FaSyringe } from 'react-icons/fa6';

const ArchivePage = (props: any) => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const dbState = useSelector((state: any) => state);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [monthAndYearDate, setMonthAndYearDate] = useState<Date | any>(null);
  const [date, setDate] = useState<Date | any>(null);
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [fetchedResults, setFetchedResults] = useState({});
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.protocol.departmentItem
  );
  const department = database.department;
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [showListView, setShowListView] = useState(false);

  const departmentIDs = [department.id, ...(department.subDepIDs || [])];

  const loadDepartmentData = async () => {
    const allArchivedProtocols = await DataStore.query(Protocol, (p) =>
      p.status.eq('ARCHIVE')
    );
    const protocols = allArchivedProtocols.filter(
      (protocol) => protocol.departmentID === department.id
    );

    const allArchivedMedications = await DataStore.query(Medication, (m) =>
      m.status.eq('ARCHIVE')
    );
    const medications = allArchivedMedications.filter((medication) =>
      departmentIDs.some(
        (id) => medication.departmentID && medication.departmentID.includes(id)
      )
    );

    const allArchivedInfusions = await DataStore.query(Drip, (i) =>
      i.status.eq('ARCHIVE')
    );
    const infusions = allArchivedInfusions.filter((infusion) =>
      departmentIDs.some(
        (id) => infusion.departmentID && infusion.departmentID.includes(id)
      )
    );

    const allArchivedElectricals = await DataStore.query(ElectricalShock, (e) =>
      e.status.eq('ARCHIVE')
    );
    const electricals = allArchivedElectricals.filter((electrical) =>
      departmentIDs.some(
        (id) => electrical.departmentID && electrical.departmentID.includes(id)
      )
    );

    const allArchivedEquipment = await DataStore.query(Equipment, (eq) =>
      eq.status.eq('ARCHIVE')
    );

    const equipment = allArchivedEquipment.filter((equipment) =>
      departmentIDs.some(
        (id) => equipment.departmentID && equipment.departmentID.includes(id)
      )
    );

    const allArchivedVitals = await DataStore.query(Vitals, (v) =>
      v.status.eq('ARCHIVE')
    );
    const vitals = allArchivedVitals.filter((vital) =>
      departmentIDs.some(
        (id) => vital.departmentID && vital.departmentID.includes(id)
      )
    );

    const allArchivedChecklists = await DataStore.query(Form, (f) =>
      f.status.eq('ARCHIVE')
    );
    const checklists = allArchivedChecklists.filter((checklist) =>
      departmentIDs.some(
        (id) => checklist.departmentID && checklist.departmentID.includes(id)
      )
    );

    let l = [
      { name: 'Protocols', data: protocols, icon: <Io5.IoDocuments /> },
      { name: 'Medications', data: medications, icon: <FaSyringe /> },
      { name: 'Infusions', data: infusions, icon: <Io5.IoWater /> },
      { name: 'Electricals', data: electricals, icon: <FaBoltLightning /> },
      { name: 'Equipment', data: equipment, icon: <Io5.IoMedkit /> },
      { name: 'Vitals', data: vitals, icon: <Io5.IoHeart /> },
      { name: 'Checklists', data: checklists, icon: <Io5.IoDocumentText /> },
    ];

    setList(l);
  };

  const filterItems = (searchTerm: string) => {
    if (!searchTerm.trim()) {
      setFilteredItems(list);
      return;
    }

    const filtered = list
      .map((category) => ({
        ...category,
        data: category.data.filter((item: any) => {
          const searchProperty =
            category.name === 'Vitals' ? item.title : item.name;
          return searchProperty
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        }),
      }))
      .filter((category) => category.data.length > 0);

    setFilteredItems(filtered);
  };

  useEffect(() => {
    filterItems(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    loadDepartmentData();
  }, [department]);

  useEffect(() => {
    if (monthAndYearDate) {
      setIsFilterActive(monthAndYearDate !== null);
    } else {
      setIsFilterActive(false);
    }
  }, [monthAndYearDate]);

  const handleArchiveItemClick = (item: any) => {
    navigate(`/archive/list-${item.name.toLocaleLowerCase()}`, {
      state: { department: department, data: item.data, database: database },
    });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        homeScreen={true}
        page={department.name + "'s Archive"}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewActive={true}
        handleReview={() => {
          setReviewSideOutVisible(true);
        }}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <div
        className="date-range-filter"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <SearchBar
          value={searchQuery}
          containerStyle={{ width: '50%' }}
          onChange={setSearchQuery}
          placeholder="Search Archive..."
        />
        {/* <div
          style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}
        >
          {showListView && (
            <button
              className="modern-button"
              onClick={() => {
                setShowListView(false);
                setDate(null);
                setMonthAndYearDate(null);
              }}
            >
              Clear Filter
            </button>
          )}
          <Calendar
            value={monthAndYearDate}
            onChange={(e) => setMonthAndYearDate(e.value)}
            view="month"
            placeholder="mm/yyyy"
            dateFormat="mm/yy"
            style={{ marginRight: '1rem' }}
          />
          <input
            type="text"
            value={date || ''}
            onChange={(e) => {
              let val = e.target.value;
              if (val === '' || /^\d{0,2}$/.test(val)) {
                let day: any = val === '' ? '' : Number(val);
                if (day === '' || (day >= 1 && day <= 31)) {
                  setDate(day);
                }
              }
            }}
            placeholder="dd"
            style={{
              marginRight: '1rem',
              height: '40px',
              width: '60px',
              fontSize: '20px',
            }}
            maxLength={2}
          />
          <Button
            label="Filter"
            className="p-button-success"
            disabled={!isFilterActive}
            onClick={async () => {
              if (date) {
                fetchAllRecordsByMonthYear(
                  monthAndYearDate,
                  departmentIDs,
                  date
                ).then((results) => {
                  setFetchedResults(results);
                  setShowListView(true);
                });
              } else {
                fetchAllRecordsByMonthYear(
                  monthAndYearDate,
                  departmentIDs,
                  date
                ).then((results) => {
                  setFetchedResults(results);
                  setShowListView(true);
                });
              }
            }}
          />
        </div> */}
      </div>
      {searchQuery !== '' && filteredItems.length > 0 ? (
        <div className="search-results">
          {filteredItems.map((category, index) => (
            <div key={index} className="category-results">
              <h3>{category.name}</h3>
              <ul>
                {category.data.map((item: any) => (
                  <li key={item.id}>{item.name || item.title} </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : !showListView ? (
        <div className="grid-container">
          {list
            .filter((item) => item.data.length > 0)
            .map((item, index) => (
              <div
                key={index}
                className="grid-item"
                onClick={() => {
                  handleArchiveItemClick(item);
                }}
              >
                <div className="item-name">{item.name}</div>
                {item.icon}
                <div className="item-count">{item.data.length} items</div>
                <Status status={ProgressStatus.ARCHIVE} />
              </div>
            ))}
        </div>
      ) : (
        <div className="list-view-container">
          {Object.entries(fetchedResults)
            .filter(([_, items]: any) => items.length > 0)
            .map(([categoryName, items]: any, index) => (
              <div key={index} className="list-view-category">
                <h4>{categoryName}</h4>
                {items.map((item: any, itemIndex: number) => (
                  <div key={itemIndex} className="list-view-item">
                    <div className="item-details">
                      <span>
                        {item.name || 'Item name not available'}
                        {/* <span className={`status ${item.status.toLowerCase()}`}>
                          {item.status}
                        </span> */}
                        <span> ({item.version})</span>
                        <span>
                          <Status status={item.status} />
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ArchivePage;
