import { DataStore, graphqlOperation, Storage } from 'aws-amplify';
import {
  ResponseType,
  Response,
  findDepartmentLogo,
  executeQuery,
} from '../AmplifyDB';
import KeychainItem from '../model/KeychainItem';

import { getSoftwarePackage, globals } from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import { DepartmentConfigInput, SoftwareType } from '../../API';
import { uniqueId } from 'lodash';
import {
  Department,
  DepartmentConfig,
  Keychain,
  LazyDepartment,
  User,
} from '../../models';
import { listDepartments } from '../QueryTypes';

export const cloneDepartment = (department: DepartmentItem): DepartmentItem => {
  let dep = new DepartmentItem(department.model);
  dep.keychainID = null;
  dep.isPublic = department.isPublic;
  dep.logoURL = department.logoURL;
  dep.logoVerifiedUrl = department.logoVerifiedUrl;
  dep.parentDep = department.parentDep;
  dep.subDeps = department.subDeps;
  dep.allSubDeps = department.allSubDeps;
  dep.grandParentDep = department.grandParentDep;
  dep.parentDep = department.parentDep;
  dep.activeSubDep = department.activeSubDep;
  dep.config = department.config;
  dep.softwarePlan = department.softwarePlan;
  dep.keychain = department.keychain;
  dep.isNemsisConfig = department.isNemsisConfig;
  dep.isOneWeightEnabled = department.isOneWeightEnabled;
  dep.infusionCalculation = department.infusionCalculation;
  dep.isRealTimeEnabled = department.isRealTimeEnabled;
  dep.isAgeFilterEnabled = department.isAgeFilterEnabled;
  dep.renewalDate = department.renewalDate;
  dep.isPublicSignup = department.isPublicSignup;
  dep.isTopEnabled = department.isTopEnabled;
  dep.gttsCalculations = department.gttsCalculations;
  dep.adminLevel = department.adminLevel;
  return dep;
};

export type DepartmentJSON = {
  name: string;
  neonateCutoff: number;
  pediatricCutoff: number;
  softwarePlan?: SoftwareType | keyof typeof SoftwareType | null;
  activeStatus: boolean;
  infusionCalculation: boolean;
  realtimeUpdating: boolean;
  oneweightEnabled: boolean;
  ageFilterEnabled: boolean;
  agencyNumEMS: string;
  stateIDEMS: string;
  gnisCode: string;
  location: string;
  departmentCode: string;
  hashedPin: string;
  saltedPin: string;
  logoURI: string;
  isPublic?: boolean;
  isPublicSignup?: boolean;
  keychain?: KeychainItem | null;
  renewalDate?: Date | null;
  isTopEnabled?: boolean;
  gttsCalculations?: number[] | null;
};

export type DepartmentSettingsJSON = {
  neonateCutoff?: number;
  pediatricCutoff?: number;
  softwarePlan?: SoftwareType | keyof typeof SoftwareType | null;
  activeStatus?: boolean;
  location?: string;
  realTimeEnabled?: boolean;
  oneweightEnabled?: boolean;
  infusionCalculation?: boolean;
  ageFilterEnabled?: boolean;
  isPublic?: boolean;
  isPublicSignup?: boolean;
  infusionGTTS?: number[] | null;
  logoID?: string;
};

export const getAllDepartments = async (): Promise<DepartmentItem[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      let departments: Department[] = await executeQuery(
        listDepartments,
        {
          filter: {
            _deleted: { ne: true },
          },
        },
        10000,
        true
      );
      resolve(departments.map((dep) => new DepartmentItem(dep)));
    } catch (error) {
      console.error(
        'Error fetching departments ( getAllDepartments )  :',
        error
      );
      reject(error);
    }
  });
};

export const deleteDepartment = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const toDelete = await DataStore.query(Department, department.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Department not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const updateDepartmentAccess = async (
  department: DepartmentItem,
  isPublic?: boolean,
  keychain?: KeychainItem,
  override?: boolean
): Promise<Response> => {
  try {
    const dbDep = await DataStore.query(Department, department.id);
    if (!dbDep) {
      return {
        type: ResponseType.Failure,
        data: 'Department does not exist',
      };
    }

    if (!override && keychain && dbDep.keychainID) {
      return {
        type: ResponseType.Failure,
        data: 'Department already has a keychain',
      };
    }

    let newDep = await DataStore.save(
      Department.copyOf(dbDep as Department, (updated) => {
        updated.keychainID = keychain ? keychain.uid : null;
        updated.isPublic = isPublic ? isPublic : false;
      })
    );

    department.keychain = keychain;
    department.keychainID = keychain ? keychain.uid : null;
    department.model = newDep;
    department.isPublic = isPublic ? isPublic : false;

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

export const updateDepartment = async (
  data: DepartmentItem | DepartmentJSON,
  department: DepartmentItem
): Promise<Response> => {
  try {
    let json: DepartmentJSON;
    if (data instanceof DepartmentItem) {
      json = {
        name: data.name,
        neonateCutoff: data.config?.neonateCutoff
          ? data.config.neonateCutoff
          : 5,
        pediatricCutoff: data.config?.pediatricCutoff
          ? data.config.pediatricCutoff
          : 40,
        softwarePlan: data.model.softwarePlan
          ? data.model.softwarePlan
          : SoftwareType.PREMIUM,
        activeStatus: data.model.activeStatus ? data.model.activeStatus : false,
        agencyNumEMS: data.model.agencyNumEMS ? data.model.agencyNumEMS : '',
        stateIDEMS: data.model.stateIdEMS ? data.model.stateIdEMS : '',
        gnisCode: data.model.gnisCodeEMS ? data.model.gnisCodeEMS : '',
        location: data.location ? data.location : '',
        departmentCode: data.departmentCode,
        hashedPin: data.hashedPin,
        saltedPin: data.salt,
        logoURI: data.model.logoID ? data.model.logoID : '',
        realtimeUpdating: data.isRealTimeEnabled,
        oneweightEnabled: data.isOneWeightEnabled,
        infusionCalculation: data.infusionCalculation,
        ageFilterEnabled: data.isAgeFilterEnabled,
        isPublic: data.isPublic,
        keychain: data.keychain,
        renewalDate: data.renewalDate,
        isPublicSignup:
          data.isPublicSignup != null ? data.isPublicSignup : true,
        isTopEnabled: data.isTopEnabled != null ? data.isTopEnabled : true,
        gttsCalculations: data.gttsCalculations,
      };
    } else {
      json = data;
    }

    const dbDep = await DataStore.query(Department, department.id);
    if (!dbDep) {
      return {
        type: ResponseType.Failure,
        data: 'Department does not exist',
      };
    }

    const config: DepartmentConfig = department.config as any;
    const newConfig: DepartmentConfig = config
      ? {
          isTopEnabled:
            json.isTopEnabled != null ? json.isTopEnabled : config.isTopEnabled,
          neonateCutoff: json.neonateCutoff,
          pediatricCutoff: json.pediatricCutoff,
          calculators: null,
          adultRanges: config.adultRanges ? config.adultRanges : [],
          softwarePlan: json.softwarePlan ? json.softwarePlan : null,
          infusionCalculation: json.infusionCalculation,
          isPublic: config.isPublic,
          realTimeUpdating: json.realtimeUpdating,
          epcrProvider: config.epcrProvider,
          oneweightEnabled: json.oneweightEnabled,
          ageFilterEnabled: json.ageFilterEnabled,
          ageGroupFilterEnabled: config.ageGroupFilterEnabled,
          renewalDate: json.renewalDate?.toISOString() ?? null,
          infusionGTTS: json.gttsCalculations ? json.gttsCalculations : null,
          // maxUsers: config.maxUsers,
        }
      : {
          isTopEnabled: json.isTopEnabled != null ? json.isTopEnabled : true,
          neonateCutoff: json.neonateCutoff,
          pediatricCutoff: json.pediatricCutoff,
          calculators: null,
          adultRanges: [],
          softwarePlan: json.softwarePlan ? json.softwarePlan : null,
          infusionCalculation: json.infusionCalculation,
          isPublic: false,
          realTimeUpdating: json.realtimeUpdating,
          epcrProvider: null,
          oneweightEnabled: json.oneweightEnabled,
          ageFilterEnabled: json.ageFilterEnabled,
          ageGroupFilterEnabled: false,
          renewalDate: json.renewalDate?.toISOString() ?? null,
          infusionGTTS: json.gttsCalculations ? json.gttsCalculations : null,
          // maxUsers: 10,
        };

    console.log('New Config', newConfig);
    let newDep = await DataStore.save(
      Department.copyOf(dbDep as Department, (updated) => {
        updated.name = json.name;
        updated.softwarePlan = json.softwarePlan;
        updated.activeStatus = json.activeStatus;
        updated.agencyNumEMS = json.agencyNumEMS
          ? json.agencyNumEMS
          : uniqueId();
        updated.stateIdEMS = json.stateIDEMS;
        updated.gnisCodeEMS = json.gnisCode;
        updated.location = json.location;
        updated.uniqueCode = json.departmentCode;
        updated.hashedPin = json.hashedPin;
        updated.saltedPin = json.saltedPin;
        updated.logoID = json.logoURI;
        updated.config = newConfig ? newConfig : dbDep.config;
        updated.isPublic = json.isPublic != null ? json.isPublic : false;
        updated.keychainID = json.keychain ? json.keychain.uid : null;
        updated.isPublicSignup =
          json.isPublicSignup != null ? json.isPublicSignup : true;
      })
    );

    department.model = newDep;
    department.name = newDep.name;
    department.location = newDep.location ? newDep.location : json.location;
    department.departmentCode = newDep.uniqueCode
      ? newDep.uniqueCode
      : json.departmentCode;
    department.hashedPin = newDep.hashedPin ? newDep.hashedPin : json.hashedPin;
    department.salt = newDep.saltedPin ? newDep.saltedPin : json.saltedPin;
    department.logoURL = newDep.logoID ? newDep.logoID : json.logoURI;
    department.config = newConfig;
    department.isRealTimeEnabled = newConfig.realTimeUpdating;
    department.isOneWeightEnabled = newConfig.oneweightEnabled;
    department.infusionCalculation =
      newConfig.infusionCalculation === true ? true : false;
    department.isAgeFilterEnabled =
      newConfig.ageFilterEnabled === true ? true : false;
    department.isPublic =
      json.isPublic != null ? json.isPublic : department.isPublic;
    department.keychain =
      json.keychain != null ? json.keychain : department.keychain;
    department.renewalDate =
      json.renewalDate != null ? json.renewalDate : department.renewalDate;
    department.isPublicSignup =
      json.isPublicSignup != null
        ? json.isPublicSignup
        : department.isPublicSignup;
    department.isTopEnabled =
      json.isTopEnabled != null ? json.isTopEnabled : true;
    department.gttsCalculations = json.gttsCalculations
      ? json.gttsCalculations
      : null;
    // let logoResult = await findDepartmentLogo(department);
    // if (logoResult.type === ResponseType.Success)
    //   department.logoVerifiedUrl = logoResult.data;

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

export const updateDepartmentSettings = async (
  data: DepartmentSettingsJSON,
  department: DepartmentItem
): Promise<Response> => {
  try {
    const dbDep = await DataStore.query(Department, department.id);
    if (!dbDep) {
      return {
        type: ResponseType.Failure,
        data: 'Department does not exist',
      };
    }

    let gtts: number[] | null = department.gttsCalculations;
    if (data.infusionGTTS === null) gtts = null;
    else if (data.infusionGTTS !== undefined) {
      gtts = [];
      for (let i = 0; i < data.infusionGTTS.length; i++) {
        let n = Number(data.infusionGTTS[i]);
        if (isNaN(n)) continue;
        gtts.push(n);
      }
    }

    const config = department.config;
    const newConfig: DepartmentConfig = config
      ? {
          isTopEnabled: config.isTopEnabled,
          neonateCutoff: data.neonateCutoff
            ? data.neonateCutoff
            : config.neonateCutoff,
          pediatricCutoff: data.pediatricCutoff
            ? data.pediatricCutoff
            : config.pediatricCutoff,
          calculators: null,
          adultRanges: config.adultRanges ? config.adultRanges : [],
          softwarePlan:
            data.softwarePlan != null ? data.softwarePlan : config.softwarePlan,
          infusionCalculation:
            data.infusionCalculation != null
              ? data.infusionCalculation
              : config.infusionCalculation,
          isPublic: data.isPublic != null ? data.isPublic : config.isPublic,
          realTimeUpdating:
            data.realTimeEnabled != null
              ? data.realTimeEnabled
              : config.realTimeUpdating,
          epcrProvider: config.epcrProvider,
          oneweightEnabled:
            data.oneweightEnabled != null
              ? data.oneweightEnabled
              : config.oneweightEnabled,
          ageFilterEnabled:
            data.ageFilterEnabled != null
              ? data.ageFilterEnabled
              : config.ageFilterEnabled,
          ageGroupFilterEnabled: config.ageGroupFilterEnabled,
          renewalDate: config.renewalDate,
          infusionGTTS: gtts,
        }
      : {
          isTopEnabled: true,
          neonateCutoff: data.neonateCutoff ? data.neonateCutoff : 5,
          pediatricCutoff: data.pediatricCutoff ? data.pediatricCutoff : 40,
          calculators: null,
          adultRanges: [],
          softwarePlan: data.softwarePlan ? data.softwarePlan : null,
          infusionCalculation: data.infusionCalculation === true ? true : false,
          isPublic: false,
          realTimeUpdating: data.realTimeEnabled === true ? true : false,
          epcrProvider: null,
          oneweightEnabled: data.oneweightEnabled === true ? true : false,
          ageFilterEnabled: data.ageFilterEnabled === true ? true : false,
          ageGroupFilterEnabled: false,
          renewalDate: null,
          infusionGTTS: gtts,
          // maxUsers: 10,
        };

    const newDep = await DataStore.save(
      Department.copyOf(dbDep as Department, (updated) => {
        updated.config = newConfig ? newConfig : dbDep.config;
        updated.softwarePlan = newConfig.softwarePlan;
        updated.activeStatus =
          data.activeStatus != null ? data.activeStatus : dbDep.activeStatus;
        updated.location = data.location ? data.location : dbDep.location;
        updated.logoID = data.logoID ? data.logoID : dbDep.logoID;
        updated.isPublic =
          data.isPublic != null ? data.isPublic : dbDep.isPublic;
        updated.isPublicSignup =
          data.isPublicSignup != null
            ? data.isPublicSignup
            : dbDep.isPublicSignup;
      })
    );

    department.location = newDep.location
      ? newDep.location
      : department.location;
    department.logoURL = newDep.logoID ? newDep.logoID : department.logoURL;
    department.isPublic = newDep.isPublic
      ? newDep.isPublic
      : department.isPublic;
    department.isPublicSignup =
      newDep.isPublicSignup != null
        ? newDep.isPublicSignup
        : department.isPublicSignup;
    department.config = newConfig ? newConfig : department.config;
    department.isOneWeightEnabled =
      newConfig.oneweightEnabled === true ? true : false;
    department.isRealTimeEnabled =
      newConfig.realTimeUpdating === true ? true : false;
    department.infusionCalculation =
      newConfig.infusionCalculation === true ? true : false;
    department.gttsCalculations = gtts;
    department.isAgeFilterEnabled =
      newConfig.ageFilterEnabled === true ? true : false;
    department.softwarePlan =
      getSoftwarePackage(newConfig.softwarePlan) ?? SoftwareType.PREMIUM;

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

/**
 * Upload the new PDF to S3 and return the file path
 * @param department The department that owns the protocol
 * @param parent The parent category of the protocol
 * @param protocolName The name of the protocol
 * @returns Success if the File was uploaded or Failure if there was an error
 */
export const uploadFileToS3 = async (
  department: DepartmentItem,
  file: File,
  errorCallback: (error: any) => void
): Promise<Response> => {
  try {
    const extension = file.name.split('.').pop();
    /* First create the file path -> Public / departmentID / categoryID / protocolID / (version)_(protocol name).pdf*/
    // let name = file.name.replace(/[^a-zA-Z0-9-]/g, '_');
    let filePath = department.id + '/' + file.name;

    /* Then take out ay characters that are not allowed in the file path and replace them with an underscore */
    filePath = filePath.replace(/[^a-zA-Z0-9./-_]/g, '_');

    const type = extension === 'pdf' ? 'application/pdf' : 'image/' + extension;

    /* Make sure all the folders exist in the S3 bucket */
    Storage.put(filePath, file, {
      contentType: type,
      level: 'public',
    })
      .then((result) => {
        if (globals.debug) console.log('DepartmentDB File Upload', result);
      })
      .catch((error) => {
        if (globals.debug) console.log('DepartmentDB File Upload', error);
        errorCallback(error);
      });
    return {
      type: ResponseType.Success,
      data: filePath,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};
//  * @param department The department that owns the protocol
//  datastore querry to get the department by id
export const getDepartmentByID = async (
  departmentID: string
): Promise<DepartmentItem | undefined> => {
  try {
    const department = await DataStore.query(Department, departmentID);
    if (department) {
      const depItem = new DepartmentItem(department);
      return depItem;
    }
    return undefined;
  } catch (error: any) {
    return undefined;
  }
};

/* ------------------ GRAPH QL API QUEIRES ------------------ */
