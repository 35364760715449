import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Col, Row } from 'react-grid-system';
import { FaCaretDown, FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User, UserStatus } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import { UserType } from '../../../../models';
import {
  getFormattedDate,
  globals,
  toTitleCase,
} from '../../../_global/common/Utils';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown/MultiSelectDropdown';
import { set } from 'lodash';
import SearchBar from '../../../components/Search/SearchBar';
import Dropdown from '../../../components/Dropdown/Dropdown';
import UserStatusUI from '../../../components/UserStatus/UserStatus';
import {
  fetchUsers,
  unsubscribeUser,
  updateUserStatus,
} from '../../../../data/functions/UserDB';
import { handleGetDepartment } from '../../../../store/actions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Loading from '../../../components/Loading/Loading';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListUsers = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const searchState = state ? state.searchState : undefined;
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const currentUser: User = useSelector((state: any) => state?.user);

  const [allUsers, setAllUsers] = useState<User[]>(
    currentUser.type === 'ADMIN'
      ? database.users
      : database.users.filter((item: User) => item.type !== UserType.ADMIN)
  );

  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.ACTIVE);

  const allUsersByType = useMemo(() => {
    return allUsers.filter((item: User) => {
      if (userStatus === UserStatus.ACTIVE)
        return item.status == null || item.status === UserStatus.ACTIVE;
      return item.status === userStatus;
    });
  }, [allUsers, userStatus]);
  const [isPermanentlyDelete, setIsPermanentlyDelete] = useState(false);
  const [filteredAllUsers, setFilteredAllUsers] =
    useState<User[]>(allUsersByType);

  const [page, setPage] = useState({
    index: 0,
    usersPerPage: 100,
    totalPages: 0,
    total: 0,
  });

  const [departmentOptions, setDepartmentOptions] = useState<DepartmentItem[]>(
    department.subDeps ? department.subDeps : []
  );

  const [sort, setSort] = useState<string>(
    searchState ? searchState.sort : 'modified_desc'
  );
  const [departmentFilters, setDepartmentFilters] = useState<DepartmentItem[]>(
    searchState ? searchState.departmentFilters : []
  );
  const [searchQuery, setSearchQuery] = useState(
    searchState ? searchState.searchQuery : ''
  );
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);
  const [userStats, setUserStats] = useState({
    total: 0,
    createdToday: 0,
    createdThisWeek: 0,
    createdThisMonth: 0,
    admin: 0,
    deptAdmin: 0,
    user: 0,
    dept: 0,
    active: 0,
    disabled: 0,
    deleted: 0,
  });

  const [userStatusTypes, setUserStatusTypes] = useState([
    { label: 'Active', value: UserStatus.ACTIVE },
    { label: 'Disabled', value: UserStatus.SUSPENDED },
    // { label: 'Deleted', value: UserStatus.DELETED },
  ]);
  const [selectedItems, setSelectedItems] = useState<User[]>([]);
  const paginationModel = { page: 0, pageSize: 20 };
  const [isDelete, setIsDelete] = useState(false);

  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [isEnable, setIsEnable] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const [isLoading, setIsLoading] = useState(false);

  const reloadDatabase = async (): Promise<DatabaseResponse | null> => {
    const response = await fetchUsers(department);
    if (response.type === ResponseType.Success) {
      let users =
        currentUser.type === 'ADMIN'
          ? response.data
          : response.data.filter((item: User) => item.type !== UserType.ADMIN);
      setAllUsers(users);

      dispatch<any>(
        handleGetDepartment({
          ...database,
          users: response.data,
        })
      );
      return { ...database, users: users } as DatabaseResponse;
    } else {
      console.error('Error fetching users:', response.data);
      return null;
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  // const reloadDatabase = async () => {
  //   const response = await loadDatabase(department);
  //   if (response.type === ResponseType.Success) {
  //     const database: DatabaseResponse = response.data;
  //     setDatabase(database);
  //     setDepartmentOptions(
  //       database.department.subDeps ? database.department.subDeps : []
  //     );
  //     let users: User[] = database.users;

  //     if (currentUser.type !== UserType.ADMIN)
  //       users = users.filter((item: User) => item.type !== UserType.ADMIN);
  //     setAllUsers(users);
  //     users = users.filter(
  //       (item: User) => item.status == null || item.status === UserStatus.ACTIVE
  //     );
  //     setFilteredAllUsers(users);

  //     setPage({
  //       index: 0,
  //       usersPerPage: 100,
  //       totalPages: Math.ceil(users.length / 100),
  //       total: users.length,
  //     });
  //     handleSort(users);

  //     //Return page sizes of 100 items each

  //     // users.sort((a, b) => {
  //     //     return a.firstName.localeCompare(b.firstName);
  //     // })
  //     // setList(users);
  //   }
  // };

  // console.log('allUsers', allUsers);
  // useEffect(() => {
  //   reloadDatabase();
  // }, [department]);

  useEffect(() => {
    const totalActive = allUsers.filter(
      (item: User) => item.status == null || item.status === UserStatus.ACTIVE
    ).length;
    const totalDisabled = allUsers.filter(
      (item: User) => item.status === UserStatus.SUSPENDED
    ).length;
    const totalDeleted = allUsers.filter(
      (item: User) => item.status === UserStatus.DELETED
    ).length;
    const totalNotifications = allUsers.filter(
      (item: User) => item.notificationTokens && item.notificationTokens.length
    ).length;
    const total = allUsersByType.length;
    const createdToday = allUsersByType.filter((item: User) => {
      if (!item.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(item.createdAt);
      return (
        createdAt.getDate() === today.getDate() &&
        createdAt.getMonth() === today.getMonth() &&
        createdAt.getFullYear() === today.getFullYear()
      );
    }).length;
    const createdThisWeek = allUsersByType.filter((item: User) => {
      if (!item.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(item.createdAt);
      const diff = today.getTime() - createdAt.getTime();
      return diff <= 7 * 24 * 60 * 60 * 1000;
    }).length;
    const createdThisMonth = allUsersByType.filter((item: User) => {
      if (!item.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(item.createdAt);
      const diff = today.getTime() - createdAt.getTime();
      return diff <= 30 * 24 * 60 * 60 * 1000;
    }).length;
    const admin = allUsersByType.filter((item: User) => {
      return item.type === UserType.ADMIN;
    }).length;
    const deptAdmin = allUsersByType.filter((item: User) => {
      return item.type === UserType.DEPT_ADMIN;
    }).length;
    const user = allUsersByType.filter((item: User) => {
      return item.type === UserType.USER;
    }).length;
    const dept = allUsersByType.filter((item: User) => {
      return item.type === UserType.DEPT;
    }).length;
    setUserStats({
      total,
      createdToday,
      createdThisWeek,
      createdThisMonth,
      admin,
      deptAdmin,
      user,
      dept,
      active: totalActive,
      disabled: totalDisabled,
      deleted: totalDeleted,
    });
    setUserStatusTypes([
      { label: 'Active: ' + totalActive, value: UserStatus.ACTIVE },
      { label: 'Suspended: ' + totalDisabled, value: UserStatus.SUSPENDED },
      // { label: 'Deleted: ' + totalDeleted, value: UserStatus.DELETED },
    ]);
  }, [allUsersByType]);

  useEffect(() => {
    handleSort();
  }, [sort]);

  const handleSort = (sortList?: User[]) => {
    const sortedList = sortList ? [...sortList] : [...allUsersByType];
    if (sort === 'name_desc') {
      sortedList.sort((a, b) => {
        return a.firstName.localeCompare(b.firstName);
      });
    } else if (sort === 'name_asc') {
      sortedList.sort((a, b) => {
        return b.firstName.localeCompare(a.firstName);
      });
    } else if (sort === 'id') {
      sortedList.sort((a, b) => {
        return a.id.localeCompare(b.id);
      });
    } else if (sort === 'type') {
      sortedList.sort((a, b) => {
        return a.type.localeCompare(b.type);
      });
    } else if (sort === 'modified_desc') {
      sortedList.sort((a, b) => {
        let a_date = a.lastActivity ? a.lastActivity : a.updatedAt;
        let b_date = b.lastActivity ? b.lastActivity : b.updatedAt;
        if (!a_date || !b_date) return 0;
        const aDate = new Date(a_date);
        const bDate = new Date(b_date);
        return bDate.getTime() - aDate.getTime();
      });
    } else if (sort === 'modified_asc') {
      sortedList.sort((a, b) => {
        let a_date = a.lastActivity ? a.lastActivity : a.updatedAt;
        let b_date = b.lastActivity ? b.lastActivity : b.updatedAt;
        if (!a_date || !b_date) return 0;
        const aDate = new Date(a_date);
        const bDate = new Date(b_date);
        return aDate.getTime() - bDate.getTime();
      });
    } else if (sort === 'version_desc') {
      sortedList.sort((a, b) => {
        if (!a.oneDoseVersion && !b.oneDoseVersion) return 0;
        else if (!a.oneDoseVersion) return 1;
        else if (!b.oneDoseVersion) return -1;
        return compareVersions(b.oneDoseVersion, a.oneDoseVersion);
      });
    } else if (sort === 'version_asc') {
      sortedList.sort((a, b) => {
        if (!a.oneDoseVersion || !b.oneDoseVersion) return 0;
        return compareVersions(a.oneDoseVersion, b.oneDoseVersion);
      });
    }
    setFilteredAllUsers([...sortedList]);
  };

  const compareVersions = (a: string, b: string) => {
    const aVersion = a.split('.');
    const bVersion = b.split('.');
    for (let i = 0; i < aVersion.length; i++) {
      if (parseInt(aVersion[i]) > parseInt(bVersion[i])) return 1;
      if (parseInt(aVersion[i]) < parseInt(bVersion[i])) return -1;
    }
    return 0;
  };

  const handleBack = () => {
    navigate(`/database`);
  };

  const handleDepartmentClick = (item: any) => {
    navigate(`/database/edit/user`, {
      state: {
        department: department,
        value: item,
        database: database,
        searchState: { searchQuery, departmentFilters, sort },
      },
    });
  };

  const getUserType = (
    type: UserType | 'ADMIN' | 'DEPT_ADMIN' | 'DEPT' | 'USER'
  ) => {
    switch (type) {
      case UserType.ADMIN:
        return 'Super Admin';
      case UserType.DEPT_ADMIN:
        return 'Admin';
      case UserType.USER:
        return 'User';
      case UserType.DEPT:
        return 'Department';
      default:
        return '';
    }
  };

  const updateDisplayedUsers = () => {
    // Calculate filtered list based on searchQuery and departmentFilters
    let filteredList = allUsersByType.filter((item: User) => {
      if (!searchQuery) return true;
      return (
        item.firstName.toLowerCase().includes(searchQuery) ||
        item.lastName.toLowerCase().includes(searchQuery) ||
        item.cognitoID.toLowerCase().includes(searchQuery)
      );
    });

    if (departmentFilters.length !== 0) {
      filteredList = filteredList.filter((item: User) => {
        if (!item.pairedDepIDs) return true;
        return item.pairedDepIDs.some((id: string) => {
          return departmentFilters.some((dep: DepartmentItem) => {
            return dep.id === id;
          });
        });
      });
    }

    setPage({
      ...page,
      totalPages: Math.ceil(filteredList.length / 100),
      total: filteredList.length,
    });
    handleSort(filteredList);
  };

  useEffect(() => {
    updateDisplayedUsers();
  }, [filteredAllUsers, page.index]);

  useEffect(() => {
    setPage({
      ...page,
      index: 0,
    });
    updateDisplayedUsers();
  }, [departmentFilters, searchQuery]);

  const columns: GridColDef[] = [
    // {
    //   field: 'index',
    //   flex: 1,
    //   renderHeader: () => <div style={{ fontWeight: 'bold' }}>Index</div>,
    //   filterable: false,
    // },
    {
      field: 'name',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.value.name}
          {params.value.isMe}
          {params.value.status != null &&
            params.value.status !== UserStatus.ACTIVE && (
              <UserStatusUI
                status={params.value.status}
                style={{ marginLeft: '5px' }}
              />
            )}
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
    },

    {
      field: 'username',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Username</div>,
      filterable: false,
    },
    {
      field: 'last_activity',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Last Activity</div>
      ),
      filterable: false,
    },
    {
      field: 'type',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Type</div>,
      filterable: false,
    },
    {
      field: 'oneDoseVersion',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>OneDose Version</div>
      ),
      filterable: false,
    },
    {
      field: 'pushNotify',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Push Notify</div>,
      renderCell: (params) => (
        <div>
          <span>{params.value.pushNotify}</span>
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.pushNotify.localeCompare(v2.pushNotify),
      filterable: false,
    },
    {
      field: 'department_subs',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Department</div>,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{params.value.count}</span>
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.count - v2.count,
      filterable: false,
    },
  ];

  const rows = useMemo(() => {
    return filteredAllUsers.map((item: User, index: number) => {
      let isMe = currentUser.id === item.id;
      let username = item.cognitoID;
      return {
        id: item.id,
        index: index,
        name: {
          name: item.firstName + ' ' + item.lastName,
          isMe: isMe ? ' (Me)' : '',
          status: item.status ? item.status : UserStatus.ACTIVE,
        },
        username: username,
        last_activity: item.updatedAt
          ? getFormattedDate(
              item.lastActivity ? item.lastActivity : item.updatedAt,
              false
            )
          : '',
        type: getUserType(item.type),
        oneDoseVersion: item.oneDoseVersion ? 'v' + item.oneDoseVersion : '-',
        pushNotify: {
          pushNotify:
            item.notificationTokens && item.notificationTokens.length
              ? 'Active'
              : '-',
        },
        department_subs: {
          count: department.allSubDeps && item.pairedDepIDs?.length,
        },
      };
    });
  }, [filteredAllUsers, currentUser.id, department.allSubDeps]);

  const handleSelectionChange = (newSelectionModel: any) => {
    const selectedItems = newSelectionModel
      .map((id: string) => filteredAllUsers.find((item) => item.id === id))
      .filter((item: User | undefined) => item !== undefined);
    setSelectedItems(selectedItems);
  };

  const handleUpdateUser = async (
    parmUser: User,
    status: UserStatus
  ): Promise<Response> => {
    let promises: Promise<Response>[] = [];
    setIsLoading(true); // Show loading spinner or state

    // Push both API call promises into the array
    promises.push(updateUserStatus(parmUser, status)); // API call 1: Update user status
    promises.push(updateCognitoUser(parmUser.cognitoID, status)); // API call 2: Update Cognito user status

    try {
      // Await the completion of both API calls
      let results = await Promise.all(promises);

      // Check the response of the first API call (user status update)
      if (results[0] && results[0].type === ResponseType.Success) {
        if (globals.debug) console.log('User status updated successfully');
      } else {
        console.error('Error updating user status:', results[0]?.data);
      }

      // Check the response of the second API call (Cognito user update)
      if (results[1] && results[1].type === ResponseType.Success) {
        if (globals.debug) console.log('Cognito user updated successfully');
      } else {
        console.error('Error updating Cognito user:', results[1]?.data);
      }

      // Determine the overall response based on success/failure
      const overallSuccess = results.every(
        (result) => result.type === ResponseType.Success
      );

      setIsLoading(false); // Hide loading spinner

      // Return success or failure response
      return {
        type: overallSuccess ? ResponseType.Success : ResponseType.Failure,
        data: results, // Return all results data
      };
    } catch (error) {
      console.error('Error in handling user update:', error);
      setIsLoading(false); // Hide loading spinner

      // Return an error response if something failed during the API calls
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  const updateCognitoUser = async (
    username: string,
    status: UserStatus
  ): Promise<Response> => {
    try {
      const url =
        process.env.REACT_APP_API_GATEWAY_URL_USER ??
        (function () {
          throw new Error('API URL is not defined');
        })();
      if (globals.debug) console.log('API URL:', url);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          action:
            status === UserStatus.ACTIVE
              ? 'enable'
              : status === UserStatus.SUSPENDED
                ? 'disable'
                : 'delete',
        }),
      });

      if (!response.ok) {
        throw new Error('API call failed with status ' + response.status);
      }
      if (globals.debug) console.log('API call successful', response);
      if (globals.debug) console.log('Response Bdy:', response.body);
      const data = await response.json();
      if (globals.debug) console.log('Response data:', data);
      if (globals.debug) console.log('Response body:', data.body);
      if (globals.debug) console.log('Response body', data.message);
      return {
        type: ResponseType.Success,
        data: data.message,
      };
    } catch (error) {
      console.error('Error calling the API:', error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  // Function to handle individual user updates or deletions
  const updateNextUser = async (enableUser?: UserStatus) => {
    try {
      if (selectedItems.length === 0) {
        setIsDelete(false); // If no items left, close the modal
        return;
      }
      console.log('Enable user is ', enableUser);
      const currentUser = selectedItems[0]; // Get the current user
      const userStatus =
        enableUser ||
        (isPermanentlyDelete ? UserStatus.DELETED : UserStatus.SUSPENDED);
      console.log('user status is ', userStatus);
      let response = await handleUpdateUser(currentUser, userStatus);

      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully updated user: ${currentUser.firstName} ${currentUser.lastName}`
        );
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(
          `Failed to updated user: ${currentUser.firstName + ' ' + currentUser.lastName}`
        );
        setSnackbarSeverity('error');
      }

      setSnackbarOpen(true); // Open snackbar after individual operation

      // Remove the successfully updated user from the list
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter(
          (item) => item.id !== currentUser.id
        );
        if (updatedItems.length === 0) {
          setIsDelete(false); // Close modal if no users are left
        }
        return updatedItems;
      });

      reloadDatabase(); // Reload the database after update
    } catch (error) {
      setSnackbarMessage(`Failed to updated user.`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmUpdateUsers = async (enableUser?: UserStatus) => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;
        console.log('Enable user is ', enableUser);

        for (const parmUser of selectedItems) {
          // Perform update based on whether it's permanent deletion or suspension
          const userStatus =
            enableUser ||
            (isPermanentlyDelete ? UserStatus.DELETED : UserStatus.SUSPENDED);
          console.log('user status is ', userStatus);
          let response = await handleUpdateUser(parmUser, userStatus);

          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(`Successfully updated ${successCount} users.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to updated ${failureCount} users.`);
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all updates
        setSelectedItems([]); // Clear selectedItems after all updates
        reloadDatabase(); // Reload the database after the operations
      } catch (error) {
        setSnackbarMessage(`Failed to updated users.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all operations
    } else {
      enableUser === UserStatus.ACTIVE
        ? updateNextUser(UserStatus.ACTIVE)
        : updateNextUser();
    }
  };

  const handleUnsubscribe = async () => {
    if (selectedItems.length > 0) {
      if (allCheckedBtn) {
        let promises: Promise<Response>[] = [];
        for (const parmUser of selectedItems) {
          promises.push(unsubscribeUser(department, parmUser));
        }
        let results = await Promise.all(promises);
        let successCount = 0;
        let failureCount = 0;
        for (const result of results) {
          if (result.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }
        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(
            `Successfully unsubscribed ${successCount} users.`
          );
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to unsubscribed ${failureCount} users.`);
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true); // Open snackbar after all updates
        setSelectedItems([]); // Clear selectedItems after all updates
        reloadDatabase(); // Reload the database after the operations
      } else {
        updateNextUnsubscribe();
      }
    }
  };

  // Function to handle individual user updates or deletions
  const updateNextUnsubscribe = async () => {
    try {
      if (selectedItems.length === 0) {
        setIsDelete(false); // If no items left, close the modal
        return;
      }
      const currentUser = selectedItems[0];
      let response = await unsubscribeUser(department, currentUser);

      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully updated user: ${currentUser.firstName} ${currentUser.lastName}`
        );
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(
          `Failed to updated user: ${currentUser.firstName + ' ' + currentUser.lastName}`
        );
        setSnackbarSeverity('error');
      }

      setSnackbarOpen(true); // Open snackbar after individual operation

      // Remove the successfully updated user from the list
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter(
          (item) => item.id !== currentUser.id
        );
        if (updatedItems.length === 0) {
          setIsDelete(false); // Close modal if no users are left
        }
        return updatedItems;
      });

      reloadDatabase(); // Reload the database after update
    } catch (error) {
      setSnackbarMessage(`Failed to updated user.`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loading type="bubbles" />}

      {selectedItems.length > 0 && (
        <ConfirmModal
          isVisible={isEnable}
          title={'Re-Enable User?'}
          handleClose={() => {
            setAllCheckedBtn(false);
            setIsEnable(false);
          }}
          handleSubmit={() => {
            handleConfirmUpdateUsers(UserStatus.ACTIVE);
            setAllCheckedBtn(false);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName={'Enable'}
          primaryDescription={
            allCheckedBtn
              ? 'Do you want to re-enable ' + selectedItems.length + ' users?'
              : 'Do you want to re-enable ' +
                selectedItems[0].firstName +
                ' ' +
                selectedItems[0].lastName +
                '?'
          }
          secondaryDescription={
            'This will give this user access again to the OneDose App and OneDose Admin Console.'
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={isPermanentlyDelete ? 'Delete User?' : 'Disable User?'}
          handleClose={() => {
            setIsDelete(false);
            setAllCheckedBtn(false);
            setIsPermanentlyDelete(false);
          }}
          handleSubmit={() => {
            handleConfirmUpdateUsers();
            setAllCheckedBtn(false);
            setIsPermanentlyDelete(false);
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName={isPermanentlyDelete ? 'Delete' : 'Disable'}
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} users?`
              : isPermanentlyDelete
                ? `Are you sure you would like to delete ${selectedItems[0].firstName + ' ' + selectedItems[0].lastName}?`
                : `Are you sure you would like to disable ${selectedItems[0].firstName + ' ' + selectedItems[0].lastName}?`
          }
          secondaryDescription={
            isPermanentlyDelete
              ? 'This is a PERMANENT action and cannot be undone. Deleting user will remove all access to the OneDose App and OneDose Admin Console.'
              : 'Disabling user will make it so he cannot access the OneDose App or OneDose Admin Console. You can re-enable the user in the future.'
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
          isPermanentlyDeleteBtn={true}
          handleCheck={(checked: boolean) => {
            setIsPermanentlyDelete(checked);
          }}
        />
      )}
      {isUnsubscribe && selectedItems.length > 0 && (
        <ConfirmModal
          isVisible={isUnsubscribe}
          title={
            'Unsubscribe ' +
            (allCheckedBtn
              ? selectedItems.length + ' users'
              : selectedItems[0].firstName + ' ' + selectedItems[0].lastName) +
            '?'
          }
          handleClose={() => {
            setIsUnsubscribe(false);
            setAllCheckedBtn(false);
          }}
          handleSubmit={() => {
            handleUnsubscribe();
            setAllCheckedBtn(false);
            setIsUnsubscribe(false);
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName={'Unsubscribe'}
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to unsubscribe ${selectedItems.length} users from ${department.name}?`
              : `Are you sure you would like to unsubscribe ${selectedItems[0].firstName + ' ' + selectedItems[0].lastName} from ${department.name}?`
          }
          secondaryDescription={
            'If they are subscribed to other agencies, they will still have access to those. If this is the last agency on their account, their account will move to suspended.'
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
        />
      )}
      {/* <div className='headerContainer'>
                <Row>
                    <Col sm={10} style={{alignItems: 'center'}}> 
                        <h6 className='backHeader'><span className='hoverable'  onClick={handleBack}><IoArrowBack size="1.25rem" style={{ marginRight: '5px', cursor: 'pointer' }} />{department.name}</span></h6>
                        <h4 className='headerText'>Users - {userStats.total}</h4>
                        <h6 className='departmentItemText' style={{color: 'grey'}}>Admin: {userStats.admin} - Dept Admin: {userStats.deptAdmin} - User: {userStats.user} - Dept: {userStats.dept}</h6>
                        <h6 className='departmentItemText' style={{color: 'grey'}}>Users Created Today: {userStats.createdToday} - Week: {userStats.createdThisWeek} - Month: {userStats.createdThisMonth}</h6>
                    </Col>
                    <Col sm={2}>
                        {/* {isLoggedIn && 
                        <div className='protocolHeaderButton'>
                            <Button className='primary-button-small' onClick={handleCreate}>+ Create</Button>
                        </div>
                        
                    </Col>
                </Row>

            </div>
            <hr style={{margin: 10}}/> */}
      {/* <div className="fixedHeader"> */}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Users: ' + filteredAllUsers.length + ' items'
            : 'Users: ' +
              selectedItems.length +
              ' / ' +
              filteredAllUsers.length +
              ' items'
        }
        page={department.name}
        customDescription={
          <div>
            <h6 className="departmentItemText" style={{ color: 'grey' }}>
              {currentUser.type === UserType.ADMIN
                ? 'Admin: ' + userStats.admin + ' - Dept '
                : ''}
              Admin: {userStats.deptAdmin} - User: {userStats.user} -
              Department: {userStats.dept}
            </h6>
            <h6 className="departmentItemText" style={{ color: 'grey' }}>
              Users Created Today: {userStats.createdToday} - Week:{' '}
              {userStats.createdThisWeek} - Month: {userStats.createdThisMonth}
            </h6>
          </div>
        }
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={
          currentUser.type === UserType.ADMIN ||
          currentUser.type === UserType.DEPT_ADMIN
        }
        isCreateActive={true}
        handleCreate={() => {
          navigate(`/database/create/user`, {
            state: {
              department: department,
              database: database,
              searchState: { searchQuery, departmentFilters, sort },
              list: filteredAllUsers,
            },
          });
        }}
        handleEdit={() => {}}
        isDeleteButton={
          selectedItems.length > 0 && department.parentDep == null
        }
        isUnsubscribeButton={
          selectedItems.length > 0 && department.parentDep != null
        }
        handleUnsubscribe={() => setIsUnsubscribe(true)}
        isUnsubscribeActive={true}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        isEnableButton={
          selectedItems.length > 0 && userStatus === UserStatus.SUSPENDED
        }
        handleEnable={() => {
          setIsEnable(true);
        }}
        type={'protocol'}
      />
      {/* </div> */}
      <Row>
        <Col sm={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyItems: 'flex-start',
              alignContent: 'flex-start',
            }}
          >
            {adminLevel > 2 && department.parentDep == null && (
              <Dropdown
                value={userStatus}
                options={userStatusTypes}
                onChange={(e: any) => {
                  setUserStatus(e);
                }}
                style={{
                  marginTop: '-13px',
                  marginRight: '16px',
                  padding: 0,
                }}
                buttonColor={
                  userStatus === UserStatus.ACTIVE
                    ? '#C3DBB0'
                    : userStatus === UserStatus.SUSPENDED
                      ? '#ADD8E6'
                      : '#FFC7CE'
                }
                textColor={
                  userStatus === UserStatus.ACTIVE
                    ? '#037F02'
                    : userStatus === UserStatus.SUSPENDED
                      ? '#0d4d8e'
                      : '#DC143C'
                }
              />
            )}
            <SearchBar
              containerStyle={{ width: '100%' }}
              value={searchQuery}
              onChange={(searchTerm: string) => {
                setSearchQuery(searchTerm);
              }}
              onSubmit={(searchTerm: string) => {
                updateDisplayedUsers();
              }}
              placeholder={'Search Users...'}
            />
          </div>
        </Col>
        <Col sm={6}>
          {/* Create a next page view */}
          <div
            className="pagination"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0px 10px',
            }}
          >
            {/* <div
              className="paginationText"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
              }}
            >
              <h6 className="departmentItemText">
                Showing {page.index * page.usersPerPage + 1} -{' '}
                {page.index * page.usersPerPage + list.length} of {page.total}{' '}
                Users
              </h6>
            </div>

            <div
              className="paginationButtons"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
              }}
            >
              <Button
                className="primary-button-small"
                style={{
                  marginRight: 10,
                }}
                disabled={page.index === 0}
                onClick={() => {
                  if (page.index > 0) {
                    setPage({
                      ...page,
                      index: page.index - 1,
                    });
                    // updateDisplayedUsers();
                  }
                }}
              >
                Previous
              </Button>
              <Button
                className="primary-button-small"
                disabled={page.index >= page.totalPages - 1}
                onClick={() => {
                  if (page.index < page.totalPages - 1) {
                    setPage({
                      ...page,
                      index: page.index + 1,
                    });
                    // updateDisplayedUsers();
                  }
                }}
              >
                Next
              </Button>
            </div> */}

            {departmentOptions.length > 0 && (
              <MultiSelectDropdown<DepartmentItem>
                title={'Departments'}
                options={departmentOptions}
                initialSelectedItems={departmentFilters}
                labelField={(option: DepartmentItem) => option.name}
                keyField={(option: DepartmentItem) => option.id}
                onSelected={(selected: DepartmentItem[]) => {
                  setDepartmentFilters(selected);
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 30, 50]}
          checkboxSelection
          onRowClick={(params) =>
            handleDepartmentClick(
              filteredAllUsers.find((item) => item.id === params.id)
            )
          }
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedItems.map((item: User) => item.id)}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              // Target the <p> tags
              margin: 0, // Remove margin
            },

            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#E0EADD !important',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none', // Remove blue border on click
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline when cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListUsers;
