import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { globals } from '../../_global/common/Utils';
import { Navbar } from 'react-bootstrap';
import NavbarComponent from '../../components/Navbar/Navbar';
import PublicNavbar from './Navbar/PublicNavbar';
import PublicSidebar from './PublicSidebar';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { handleShowSideBar } from '../../../store/actions';
import { useDispatch } from 'react-redux';

interface DashboardProps {
  children?: ReactNode;
  pageTitle: string;
  component?: ReactNode;
}

/* 09-28-23 Arul: Created the Dashboard layout component to wrapper the navbar, sidebar and main component */
const PublicDashboard: React.FC<DashboardProps> = ({
  children,
  pageTitle,
  component,
}) => {
  const dispatch = useDispatch();
  const isSidebar = useSelector((state: any) => state.sidebar);
  const dbState = useSelector((state: any) => state.protocol);
  const width = window.innerWidth;
  const department = useMemo(() => {
    return dbState.departmentItem.department as DepartmentItem;
  }, [dbState]);

  const topDepartment = useMemo(() => {
    return department?.getTopLevelDep();
  }, [department]);

  const [isMenuBarsVisible, setIsMenuBarsVisible] = useState(false);

  useEffect(() => {
    setIsMenuBarsVisible(
      (topDepartment &&
        topDepartment.subDeps &&
        topDepartment.subDeps.length > 0) ||
        false
    );
  }, [topDepartment]);
  return (
    <div style={{ flex: 1, overflowY: 'auto' }}>
      <PublicNavbar isProtocolPage={isMenuBarsVisible} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          marginTop: '63px',
          marginLeft: '0px',
          // marginRight: '10px',
          minHeight: 'calc(100vh - 63px)',
        }}
      >
        <PublicSidebar show={isSidebar && isMenuBarsVisible} />
        <div
          className={`public-main ${isSidebar && isMenuBarsVisible ? 'with-sidebar' : ''}`}
          style={{
            marginLeft:
              isSidebar && isMenuBarsVisible
                ? width * 0.2 < 300
                  ? '350px'
                  : `${width * 0.2 + 50}px`
                : '0px',
          }}
        >
          {pageTitle}
          {children}
          {component}
        </div>
      </div>
    </div>
  );
};

export default PublicDashboard;
