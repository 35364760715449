import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css';
import { Portal } from '@mui/material';
import { FaTimes } from 'react-icons/fa';

const Loading = (props) => {
  return (
    <Portal>
      <div className="loading-background">
        {/* Add an optional faTimes to remove the loading */}
        {props.remove ? (
          <div className="remove-loading-container">
            <FaTimes className="remove-loading" onClick={props.remove} />
          </div>
        ) : null}
        <ReactLoading
          className="load"
          type={props.type ? props.type : 'bubbles'}
          size={60}
        />
        <h3>{props.message ? props.message : 'Loading...'}</h3>
      </div>
    </Portal>
  );
};

export default Loading;
