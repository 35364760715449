import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import './PublicNavbar.scss';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth, DataStore } from 'aws-amplify';
import { FiSettings } from 'react-icons/fi';
import { IoSettings, IoSettingsOutline } from 'react-icons/io5';
import logo from '../../../../assets/images/OneDose_TM_Logo.png';
import { BiExit } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import { FaCaretDown, FaChevronDown } from 'react-icons/fa6';
import {
  handleGetDepartment,
  handleLogOut,
  handleShowSideBar,
  handleUpdateUser,
} from '../../../../store/actions';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  getUserInformation,
  repeatTimeoutPromise,
} from '../../../../data/AmplifyDB';
import { globals } from '../../../_global/common/Utils';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { GoArrowSwitch } from 'react-icons/go';
import { User } from '../../../../models';
import SwitchDepartmentModal from '../../../components/Modal/SwitchDepartmentModal';

/* 09-28-23 Arul: Created the  Navbar component globally  */
const PublicNavbar = ({ isProtocolPage }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const department: DepartmentItem = database.department;
  const user: User = useSelector((state: any) => state?.user);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);

  const options = [
    // { value: 'Settings', label: 'Settings' },
    { value: 'Log Out', label: 'Log Out', icon: <BiExit size={20} /> },
  ];

  // const availableDepartments = useMemo(() => {
  //   try {
  //     if (user && user.pairedDepIDs) {
  //       let deps = department?.fetchAllDepartments() ?? [];
  //       if (deps.length === 0) return [];
  //       else {
  //         let filtered = deps.filter((dep) =>
  //           user.pairedDepIDs?.includes(dep.id)
  //         );
  //         return filtered;
  //       }
  //     } else return [];
  //   } catch (e) {
  //     console.log(e);
  //     return [];
  //   }
  // }, [
  //   user,
  //   user?.pairedDepIDs,
  //   department,
  //   department?.subDeps,
  //   department?.allSubDeps,
  //   department?.parentDep,
  // ]);

  const handleUserInfo = async () => {
    console.log('Logging out');
    setLoading(true);
    try {
      navigate(`/login`);
      await Auth.signOut();
      dispatch<any>(handleLogOut);
      // dispatch<any>(handleUpdateUser(null));
      await DataStore.clear();
      await localStorage.clear();
      console.log('Successfully logged out');
    } catch (error) {
      console.log('error signing out: ', error);
    }
    setLoading(false);
    // dispatch<any>(handleLogOut);
    // dispatch<any>(handleUpdateUser());
    // navigate(`/protocol`);
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate('/protocol');
  //   }
  // }, [isLoggedIn]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value === 'Log Out') handleUserInfo();
    // else if (value === 'Switch Department') setShowSwitchAccount(true);

    // setAnchorEl(null);
  };

  // useEffect(() => {
  //   const fetchUserInformation = async () => {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const result = await repeatTimeoutPromise(
  //       getUserInformation(user.attributes.sub, user.username, false, false),
  //       globals.maxDatabaseDelayMS,
  //       3
  //     ).catch((error) => {
  //       // return setLoadingError(true);
  //     });

  //     if (result == null) {
  //       console.error('Error fetching user information');
  //       // return setLoadingError(true);
  //     }

  //     let _user = result?.data[0];
  //     let _department = result?.data[1];

  //     let db: DatabaseResponse = {
  //       department: _department,
  //       cprModel: undefined,
  //       categories: [],
  //       protocols: [],
  //       ambulances: [],
  //       users: [],
  //       oneWeights: [],
  //       medications: [],
  //       medicationDoses: [],
  //       infusions: [],
  //       infusionDoses: [],
  //       equipment: [],
  //       electrical: [],
  //       electricalDoses: [],
  //       checklists: [],
  //       vitals: [],
  //       logs: [],
  //       notifications: [],
  //       contacts: [],
  //       weightObjects: [],
  //       keychains: [],
  //       groups: [],
  //     };

  //     dispatch<any>(handleUpdateUser(_user));
  //     dispatch<any>(handleGetDepartment(db));
  //   };

  //   if (isLoggedIn && !department) {
  //     //Load the user and department info
  //     fetchUserInformation();
  //   }
  // }, [isLoggedIn, department, dispatch]);

  const handleShowSidebar = (e: any) => {
    e.stopPropagation();
    setShowSidebar(!showSidebar);
    if (showSidebar) {
      dispatch<any>(handleShowSideBar({ sidebar: false }));
    } else {
      dispatch<any>(handleShowSideBar({ sidebar: true }));
    }
  };

  /* Use this effect to close the dropdown when clicked outside */
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        isOpen &&
        divRef.current &&
        !divRef.current.contains(e.target as Node)
      ) {
        setTimeout(() => {
          if (isOpen) setIsOpen(false);
        }, 150);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [divRef, isOpen]);

  return (
    <div className="fixedNavbar">
      <header className="navBarContainer">
        {/* Controls the menu bar in the top right */}
        <div className="logo-container-left">
          {isProtocolPage && (
            <GiHamburgerMenu
              className="menu-bars"
              size={30}
              color="#00534C"
              onClick={handleShowSidebar}
            />
          )}
          <div
            className="hover-raise-elevation"
            onClick={(e) => {
              if (isLoggedIn) {
                navigate('/');
                window.location.reload();
              } else navigate('/login');
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              padding: '0.25rem',
              cursor: 'pointer',
              gap: '0.5rem',
              borderRadius: '0.5rem',
            }}
          >
            <img
              className="no-select"
              src={logo}
              alt="Logo"
              style={{
                marginLeft: '6px',
                padding: 0,
              }}
            />
          </div>
        </div>
        {/* {department && department.logoVerifiedUrl && (<img src={department.logoVerifiedUrl} alt='Agency Logo' />)} */}
        {/* {department && <div className="DepartmentName">{department.name}</div>} */}
        {/* <img src={logo} alt='Logo' /> The HM logo */}
        <div className="logo-container-right">
          {/* Shows the login button if the user is not logged in*/}
          <div className="logo-container-right">
            {isLoggedIn ? (
              <div
                className="hover-select-text"
                onClick={(e) => {
                  navigate('/');
                  window.location.reload();
                  //Refresh the page
                }}
                style={{
                  marginRight: '16px',
                }}
              >
                <h6
                  className="buttonText"
                  style={{
                    color: '#00534C',
                  }}
                >
                  Home
                </h6>
                {/* <FaChevronDown size={10} /> */}
              </div>
            ) : (
              <div
                className="hover-select-text"
                onClick={(e) => {
                  navigate('/login');
                }}
                style={{
                  marginRight: '16px',
                }}
              >
                <h6
                  className="buttonText"
                  style={{
                    color: '#00534C',
                  }}
                >
                  Log in
                </h6>
                {/* <FaChevronDown size={10} /> */}
              </div>
            )}

            {/* <Accountbar> */}
            {/* <DropItem icon={<BiBell />}>
                <NotificationDropDown />
              </DropItem> */}

            {/* <DropItem
                  icon={
                    
                  }
                >
                  <AccountDropDownMenu onLogOut={handleUserInfo} />
                </DropItem> */}
            {/* </Accountbar> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default PublicNavbar;
