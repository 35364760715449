import { Button } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { BiArrowBack } from 'react-icons/bi';
import ConfirmModal from './ConfirmModal';
import { User } from '../../../models';
import { useSelector } from 'react-redux';
import { Avatar, ListItem, ListItemAvatar, Radio } from '@mui/material';
import SearchBar from '../Search/SearchBar';
import { Sidebar } from 'primereact/sidebar';
import { ViewportList } from 'react-viewport-list';

interface SwitchDepartmentModalProps {
  isVisible: boolean;
  department: DepartmentItem;
  availableDepartments: DepartmentItem[];
  handleClose: () => void;
  handleSubmit: (department: DepartmentItem) => void;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const SwitchDepartmentModal: React.FC<SwitchDepartmentModalProps> = (
  props: SwitchDepartmentModalProps
) => {
  const {
    isVisible,
    handleClose,
    handleSubmit,
    department,
    availableDepartments,
  } = props;
  const user: User = useSelector((state: any) => state?.user);

  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentItem>(department);
  const [search, setSearch] = useState('');
  const list = useMemo(() => {
    if (search === '')
      return availableDepartments.sort((a, b) => {
        //If parent department is null it is index 0 otherwise sort by name
        if (a.parentDepID == null) return -1;
        if (b.parentDepID === null) return 1;
        return a.name.localeCompare(b.name);
      });
    else {
      return availableDepartments
        .filter((dep) => dep.name.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => {
          //If parent department is null it is index 0 otherwise sort by name
          if (a.parentDepID == null) return -1;
          if (b.parentDepID === null) return 1;
          return a.name.localeCompare(b.name);
        });
    }
  }, [availableDepartments, search]);

  const [confirm, setConfirm] = useState({
    title: '',
    description: '',
    errorCode: '',
  });

  const handleButton = () => {
    if (!isVisible) return;
    if (isSaveValid) {
      handleSubmit(selectedDepartment);
    }
  };

  const isSaveValid = useMemo(() => {
    return selectedDepartment.id !== department.id;
  }, [department, selectedDepartment]);

  useEffect(() => {
    if (isVisible) {
      setSelectedDepartment(department);
    }
  }, [isVisible, department]);

  return (
    <div>
      <ConfirmModal
        isVisible={confirm.title !== ''}
        title={confirm.title}
        handleClose={() => {
          setConfirm({ title: '', description: '', errorCode: '' });
        }}
        handleSubmit={() => {
          setConfirm({ title: '', description: '', errorCode: '' });
        }}
        isSingleBtn={true}
        secondaryBtnName="Okay"
        primaryDescription={confirm.description}
        secondaryDescription={confirm.errorCode}
      />
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleClose}
        style={{ width: '30%' }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
      >
        <div className="sidebarContainer">
          <div
            className="buttonContainer contentTitleLarge hoverText"
            onClick={handleClose}
          >
            <span className="">
              <BiArrowBack className="header-icon" style={{}} /> Switch
              Department
            </span>
          </div>
          {/* <h4
            className="keychain-title"
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <span
              style={{
                marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              Switch Department
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={handleClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4> */}
          <form
            className="keychain-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleButton();
            }}
            style={{
              // padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              // alignItems: 'center',
              background: 'transparent',
              border: 'none',
              gap: '1rem',
            }}
          >
            <div
              className="input-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                // marginBottom: '10px',
              }}
            >
              {department.logoVerifiedUrl && (
                <img
                  className="department-logo-public"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    marginRight: '10px',
                  }}
                  src={department.logoVerifiedUrl}
                  alt="Agency Logo"
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // width: '100%',
                }}
              >
                <label
                  className='"ketamine-general-label'
                  htmlFor="password"
                  style={{
                    fontWeight: '600',
                    color: 'black',
                    fontSize: '1rem',
                  }}
                >
                  {department.name}
                </label>
              </div>
            </div>

            <p
              className="departmentItemText"
              style={{
                fontSize: '0.9rem',
                color: 'black',
                // maxWidth: '50%',
              }}
            >
              Search for the department you want to switch to.
            </p>

            <SearchBar
              placeholder="Search Department"
              value={search}
              onChange={(e) => {
                setSearch(e);
              }}
              containerStyle={{ width: '100%' }}
              autoFocus={true}
            />
            {/* <ViewportList items={list}>
              {(dep, index) => {
                <div
                  className="hoverText"
                  style={{
                    flex: 1,
                    width: '100%',
                    borderBottom:
                      index === availableDepartments.length - 1
                        ? 'none'
                        : '1px solid #e0e0e0',
                  }}
                  onClick={() => {
                    setSelectedDepartment(dep);
                  }}
                >
                  <ListItem
                    alignItems="center"
                    sx={{
                      width: '100%',
                      flex: 1,
                      alignContent: 'center',
                      // justifyContent: 'center',
                      justifyItems: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Radio
                      checked={dep.id === selectedDepartment.id}
                      onChange={() => {
                        setSelectedDepartment(dep);
                      }}
                      sx={{
                        color: '#00534C',
                        '&.Mui-checked': {
                          color: '#00534C',
                        },
                        marginLeft: '-10px',
                        marginRight: '10px',
                      }}
                    />
                    <ListItemAvatar>
                      <Avatar alt={dep.name} src={dep.logoVerifiedUrl ?? ''} />
                    </ListItemAvatar>
                    <label
                      style={{
                        color: 'black',
                        fontWeight: '700',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {dep.name}
                    </label>
                  </ListItem>
                </div>;
              }}
            </ViewportList> */}
            <div
              style={{
                border: list.length === 0 ? '0px' : '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px',
                marginTop: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ViewportList items={list}>
                {(dep: DepartmentItem, index) => (
                  <div
                    key={dep.id}
                    style={{
                      display: 'flex',
                      // gridTemplateColumns: '16fr 1fr',s
                      padding: '6px 10px',
                      alignItems: 'center',
                      borderBottom:
                        index === list.length - 1 ? '' : '1px solid #ccc',
                      flex: 1,
                    }}
                    className="listItem "
                    onClick={() => {
                      setSelectedDepartment(dep);
                    }}
                  >
                    <div
                      className="hoverText"
                      style={{
                        width: '100%',
                        flex: 1,
                        alignContent: 'center',
                        // justifyContent: 'center',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ListItem
                        alignItems="center"
                        sx={{
                          width: '100%',
                          flex: 1,
                          alignContent: 'center',
                          // justifyContent: 'center',
                          justifyItems: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Radio
                          checked={dep.id === selectedDepartment.id}
                          onChange={() => {
                            setSelectedDepartment(dep);
                          }}
                          sx={{
                            color: '#00534C',
                            '&.Mui-checked': {
                              color: '#00534C',
                            },
                            marginLeft: '-10px',
                            marginRight: '10px',
                          }}
                        />
                        <ListItemAvatar>
                          <Avatar
                            alt={dep.name}
                            src={dep.logoVerifiedUrl ?? ''}
                          />
                        </ListItemAvatar>
                        <label
                          style={{
                            color: 'black',
                            fontWeight: '700',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            margin: 0,
                            padding: 0,
                            flex: 1,
                          }}
                          className="no-select"
                        >
                          {dep.name}
                        </label>
                        {dep.id === department.id && (
                          <div className={`status-container ARCHIVE`}>
                            <h6 className={`status ARCHIVE`}>Active</h6>
                          </div>
                        )}
                      </ListItem>
                    </div>
                  </div>
                )}
              </ViewportList>
            </div>
            {/* <List
              sx={{
                width: '100%',
                maxWidth: 600,
                bgcolor: 'background.paper',
                border: '1px solid #e0e0e0',
                borderRadius: '5px',
                marginTop: '10px',
                padding: '0.5rem',
              }}
            >
              {availableDepartments.map(
                (dep: DepartmentItem, index: number) => (

                )
              )}
            </List> */}

            <div className="btn_Bottom ">
              <Button
                className="secondary-button btn-rightMargin"
                onClick={() => {
                  handleClose();
                }}
              >
                Dismiss
              </Button>
              <Button
                disabled={!isSaveValid}
                className="primary-button"
                onClick={handleButton}
              >
                Switch
              </Button>
            </div>
          </form>
        </div>
      </Sidebar>
    </div>
  );
};

export default SwitchDepartmentModal;
