import React, { useEffect, useMemo, useState } from 'react';
import { FaAmbulance } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import { IoPeopleSharp } from 'react-icons/io5';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  deleteDepartment,
  loadDatabase,
} from '../../../data/AmplifyDB';
import { EquipmentOption, User, VitalOption } from '../../../models';
import { UserType } from '../../../models';
import ProtocolHeader from '../protocol/ProtocolHeader';
import ReviewSideOut from '../../components/SideOut/reviewSideOut/ReviewSideOut';
import { handleGetDepartment } from '../../../store/actions';
import { useDispatch } from 'react-redux';
import { GiElectric } from 'react-icons/gi';
import { SiEquinixmetal } from 'react-icons/si';
import { RiContactsBook2Fill } from 'react-icons/ri';
import {
  FaBoltLightning,
  FaBuilding,
  FaChevronDown,
  FaChevronRight,
  FaHeartPulse,
  FaKey,
  FaSyringe,
  FaWeightHanging,
} from 'react-icons/fa6';
import {
  getFormattedDateTime,
  globals,
  hasAdminUserAccess,
  mapIndexToRange,
} from '../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import { Tooltip } from '@mui/material';
import * as XLSX from 'xlsx-js-style';
import MedicationItem, {
  getConcentrationsArrayString,
} from '../../../data/model/MedicationItem';
import InfusionItem from '../../../data/model/InfusionItem';
import InfusionSubItem from '../../../data/model/InfusionSubItem';
import ElectricalItem from '../../../data/model/ElectricalItem';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import CategoryItem from '../../../data/model/CategoryItem';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import EquipmentItem, {
  formatForMatchingUnits,
} from '../../../data/model/EquipmentItem';
import VitalItem from '../../../data/model/VitalItem';
import AccordionItem from '../../components/Accordion/AccordionItem';
import Status from '../../components/ProgressStatus/ProgressStatus';
import DepartmentItem from '../../../data/model/DepartmentItem';

const DatabasePage = (props: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const state = JSON.parse(localStorage.getItem('state') || '{}');

  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );

  const department: DepartmentItem = useMemo(() => {
    return dbState.departmentItem.department;
  }, [dbState.departmentItem.department]);

  const user: User = useSelector((state: any) => state?.user);
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);

  const [isCopied, setIsCopied] = useState<string | null>(null);

  const [basicList, setBasicList] = useState<any[]>([]);
  const [advancedList, setAdvancedList] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string[]>(
    state.expanded ? state.expanded : ['basic']
  );
  const [isExportExcel, setIsExportExcel] = useState(false);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, user);
  }, [department, user]);

  useEffect(() => {
    reloadDatabase();
  }, []);

  useEffect(() => {
    setDatabase(dbState.departmentItem);
  }, [dbState.departmentItem]);

  useEffect(() => {
    localStorage.setItem('state', JSON.stringify({ expanded: expanded }));
  }, [expanded]);

  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);

  useEffect(() => {
    let count = 0;
    let db: DatabaseResponse = database;
    for (let i = 0; i < db.medications.length; i++) {
      let subMeds = db.medications[i].subItems;
      for (let j = 0; j < subMeds.length; j++) {
        let dose: MedicationSubItem = subMeds[j];
        if (dose.nemsisRoutes.length > 0) count++;
      }
    }
    if (globals.debug)
      console.log('Count of doses with nemsis routes: ' + count);
  }, []);

  const loadDepartmentData = async () => {
    const db: DatabaseResponse = database;
    let basic: any[] = [
      { name: 'Protocols', data: db.protocols, icon: <Io5.IoDocuments /> },
      { name: 'Medications', data: db.medications, icon: <FaSyringe /> },
      { name: 'Infusions', data: db.infusions, icon: <Io5.IoWater /> },
      { name: 'Electricals', data: db.electrical, icon: <FaBoltLightning /> },
      { name: 'Equipment', data: db.equipment, icon: <Io5.IoMedkit /> },
    ];
    if (db.department.allSubDeps && db.department.allSubDeps.length > 0) {
      basic = [
        {
          name: 'Agencies',
          data: db.department.allSubDeps,
          icon: <FaBuilding />,
        },
        ...basic,
      ];
    }
    let advanced: any[] = [
      { name: 'CPR Assist', data: db.cprModel, icon: <FaHeartPulse /> },
      { name: 'Vitals', data: db.vitals, icon: <Io5.IoHeart /> },
      { name: 'Checklists', data: db.checklists, icon: <Io5.IoDocumentText /> },
      { name: 'Contacts', data: db.contacts, icon: <RiContactsBook2Fill /> },
      { name: 'Keychains', data: db.keychains, icon: <FaKey /> },
    ];
    // if (!department.isSoftwareOnly)
    advanced = [
      ...advanced,
      { name: 'Ambulances', data: db.ambulances, icon: <FaAmbulance /> },
      {
        name: 'Subtractable Weights',
        data: db.weightObjects,
        icon: <FaWeightHanging />,
      },
    ];

    /* Force the users as the last item */
    advanced = [
      ...advanced,
      {
        name: 'Users',
        data: db.users,
        icon: <IoPeopleSharp />,
        detail: calculateUserDetails(db.users),
      },
    ];
    setBasicList(basic);
    setAdvancedList(advanced);
  };

  const calculateUserDetails = (users: any) => {
    const adminUsers = users.filter(
      (user: any) => user.type === UserType.ADMIN
    ).length;
    const userUsers = users.filter(
      (user: any) => user.type === UserType.USER
    ).length;
    const deptUsers = users.filter(
      (user: any) => user.type === UserType.DEPT
    ).length;
    const depAdminUsers = users.filter(
      (user: any) => user.type === UserType.DEPT_ADMIN
    ).length;
    return { adminUsers, userUsers, deptUsers, depAdminUsers };
  };

  useEffect(() => {
    loadDepartmentData();
  }, [department, database]);

  const handleBack = () => {
    navigate('/database');
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm(
      `Are you want to delete department ${department.name}?`
    );

    if (isConfirmed) {
      // Execute the action
      const response = await deleteDepartment(department);
      if (response.type === ResponseType.Success) navigate('/database');
      else {
        let error = response.data;
        const isConfirmed = window.confirm(
          `Department ${department.name} deletion failed:\n${error}`
        );
      }
    } else {
      if (globals.debug) console.log('Action cancelled.');
    }
  };

  const handleDepartmentClick = (item: any) => {
    let name = (item.name as string).replaceAll(' ', '-').toLowerCase();
    if (item.name === 'CPR Assist')
      navigate(`/database/cpr-assist`, {
        state: { department: department, data: item.data, database: database },
      });
    else
      navigate(`/database/list-${name}`, {
        state: { department: department, data: item.data, database: database },
      });
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const reloadDatabase = async () => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    if (globals.debug) console.log('loading database');
    loadDatabase(database.department, undefined, true).then((resp) => {
      if (resp.type === ResponseType.Success) {
        const newDB: DatabaseResponse = resp.data;
        setDatabase(newDB);
        dispatch<any>(handleGetDepartment(newDB));
        return newDB;
      }
    });
  };

  // Apply styles to the generated worksheet
  const applyStylesToWorksheet = (
    worksheet: any,
    grayColor: boolean = false,
    departmentWorksheet: boolean = false
  ) => {
    if (departmentWorksheet) {
      const firstColumnStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: 'E0EADD' } },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      // Apply the style to the first column and set column widths
      const range = XLSX.utils.decode_range(worksheet['!ref' as any]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        const cellAddress = { c: 0, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        worksheet[cellRef].s = firstColumnStyle;
      }
      // Set the width of all columns
      const columnWidth = 25; // Adjust the width as needed
      worksheet['!cols'] = Array(range.e.c + 1).fill({ wch: columnWidth });
    } else {
      const headerStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '00534C' } },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const LightGreenStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: 'E0EADD' } },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const grayStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: 'e0e0e0' } },
      };

      // Ensure the worksheet has a valid reference range
      if (!worksheet['!ref']) {
        throw new Error("Worksheet has no reference range ('!ref').");
      }

      const range = XLSX.utils.decode_range(worksheet['!ref']);

      for (let R = range.s.r; R <= range.e.r; ++R) {
        // Get the row and ensure it exists
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
          const cell = worksheet[cellAddress];

          if (cell) {
            if (R === 0) {
              // Apply header style to the first row (green)
              cell.s = headerStyle;
            } else if (
              worksheet[`A${R + 1}`] &&
              worksheet[`A${R + 1}`].v !== ''
            ) {
              cell.s = LightGreenStyle;
            } else if (
              worksheet[`D${R + 1}`] &&
              worksheet[`D${R + 1}`].v !== '' &&
              grayColor
            ) {
              cell.s = grayStyle;
            }
          }
        }
      }
      // Set the width of all columns
      const columnWidth = 25; // Adjust the width as needed
      worksheet['!cols'] = Array(range.e.c + 1).fill({ wch: columnWidth });
    }
  };

  const getDepartmentData = () => {
    return [
      ['Name', department.name],
      ['Location', department.location],
      ['Neonate Cutoff (kg)', department.config?.neonateCutoff],
      ['Pediatric Cutoff (kg)', department.config?.pediatricCutoff],
      ['Software Plan', department.softwarePlan],
      ['Generated By', user.firstName + ' ' + user.lastName],
      ['Generated At', getFormattedDateTime(new Date(), false, true)],
    ];
  };

  const getProtocolData = () => {
    const categories: CategoryItem[] = database.categories;

    const data: any = [];

    categories.forEach((category: CategoryItem) => {
      // Add a header for the medication item
      data.push({
        'Category Index': category.index + 1,
        'Category Name': category.name,
        Nickname: '',
        'Full Name': '',
        Index: '',
        Type: '',
        Name: '',
        Dose: '',
        'Administration Route': '',
        'Min Single Dose': '',
        'Max Single Dose': '',
        'Max Total Dose': '',
        'Repeat Time(Sec)': '',
        'Weight Low(kg)': '',
        'Weight High(kg)': '',
        'Age Low(Months)': '',
        'Age High(Months)': '',
        Title: '',
        Warning: '',
        Instructions: '',
        Notes: '',
      });
      category.protocols.forEach((protocol: ProtocolItem) => {
        data.push({
          'Category Index': '',
          'Category Name': '',
          Nickname: protocol?.nickname,
          'Full Name': protocol?.name,
          Index: '',
          Type: '',
          Name: '',
          Dose: '',
          'Administration Route': '',
          'Min Single Dose': '',
          'Max Single Dose': '',
          'Max Total Dose': '',
          'Repeat Time(Sec)': '',
          'Weight Low(kg)': '',
          'Weight High(kg)': '',
          'Age Low(Months)': '',
          'Age High(Months)': '',
          Title: '',
          Warning: '',
          Instructions: '',
          Notes: '',
        });

        // Add rows for each medication dose (subItem)
        protocol.medications
          .sort((a: MedicationSubItem, b: MedicationSubItem) => {
            // Sort by Protocol Name (assuming `parentProtocol` is defined)
            const protocolNameA = a.parentProtocol.name.toLowerCase();
            const protocolNameB = b.parentProtocol.name.toLowerCase();
            if (protocolNameA < protocolNameB) return -1;
            if (protocolNameA > protocolNameB) return 1;
            return 0; // They are equal
          })
          .forEach((dose: MedicationSubItem) => {
            data.push({
              'Category Index': '',
              'Category Name': '',
              Nickname: '',
              'Full Name': '',
              Index: dose?.index + 1,
              Type: 'Medication',
              Name: dose?.name,
              Dose: dose?.fullBasis,
              'Administration Route': dose?.routes.join(', '),
              'Min Single Dose':
                dose?.fullMinDose === '0' ? '' : dose?.fullMinDose,
              'Max Single Dose':
                dose?.fullMaxDose === '2147483647' ? '' : dose?.fullMaxDose,
              'Max Total Dose':
                dose?.fullMaxTotalDose === '2147483647'
                  ? ''
                  : dose?.fullMaxTotalDose,
              'Repeat Time(Sec)':
                dose?.repeatTimeSec <= 0 ? '' : dose?.repeatTimeSec,
              'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
              'Weight High(kg)':
                dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
              'Age Low(Months)':
                dose?.ageLow?.ageValue === 0 ? '' : dose?.ageLow?.ageValue,
              'Age High(Months)':
                dose?.ageHigh?.ageValue === 2147483647
                  ? ''
                  : dose?.ageHigh?.ageValue,
              Title: dose?.title,
              Warning: dose?.warning,
              Instructions: dose?.instruction,
              Notes: dose?.note,
            });
          });

        protocol.infusions
          .sort((a: InfusionSubItem, b: InfusionSubItem) => {
            // Sort by Protocol Name (assuming `parentProtocol` is defined)
            const protocolNameA = a.parentProtocol.name.toLowerCase();
            const protocolNameB = b.parentProtocol.name.toLowerCase();
            if (protocolNameA < protocolNameB) return -1;
            if (protocolNameA > protocolNameB) return 1;
            return 0; // They are equal
          })
          .forEach((dose: InfusionSubItem) => {
            data.push({
              'Category Index': '',
              'Category Name': '',
              Nickname: '',
              'Full Name': '',
              Index: dose?.index + 1,
              Type: 'Infusion',
              Name: dose?.name,
              Dose: dose?.fullBasis,
              'Administration Route': dose?.routes.join(', '),
              'Min Single Dose':
                dose?.fullMinDose === '0' ? '' : dose?.fullMinDose,
              'Max Single Dose':
                dose?.fullMaxDose === '2147483647' ? '' : dose?.fullMaxDose,
              'Max Total Dose':
                dose?.fullMaxTotalDose === '2147483647'
                  ? ''
                  : dose?.fullMaxTotalDose,
              'Repeat Time(Sec)':
                dose?.repeatTimeSec <= 0 ? '' : dose?.repeatTimeSec,
              'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
              'Weight High(kg)':
                dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
              'Age Low(Months)':
                dose?.ageLow?.ageValue === 0 ? '' : dose?.ageLow?.ageValue,
              'Age High(Months)':
                dose?.ageHigh?.ageValue === 2147483647
                  ? ''
                  : dose?.ageHigh?.ageValue,
              Title: dose?.title,
              Warning: dose?.warning,
              Instructions: dose?.instruction,
              Notes: dose?.note,
            });
          });

        protocol.electrical
          .sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
            // Sort by Protocol Name (assuming `parentProtocol` is defined)
            const protocolNameA = a.parentProtocol.name.toLowerCase();
            const protocolNameB = b.parentProtocol.name.toLowerCase();
            if (protocolNameA < protocolNameB) return -1;
            if (protocolNameA > protocolNameB) return 1;
            return 0; // They are equal
          })
          .forEach((dose: ElectricalSubItem) => {
            data.push({
              'Category Index': '',
              'Category Name': '',
              Nickname: '',
              'Full Name': '',
              Index: dose?.index + 1,
              Type: 'Electrical',
              Name: dose?.name,
              Dose: dose?.fullBasis,
              'Administration Route': '',
              'Min Single Dose':
                dose?.fullMinDose === '0' ? '' : dose?.fullMinDose,
              'Max Single Dose':
                dose?.fullMaxDose === '2147483647' ? '' : dose?.fullMaxDose,
              'Max Total Dose':
                dose?.fullMaxTotalDose === '2147483647'
                  ? ''
                  : dose?.fullMaxTotalDose,
              'Repeat Time(Sec)':
                dose?.repeatTimeSec <= 0 ? '' : dose?.repeatTimeSec,
              'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
              'Weight High(kg)':
                dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
              'Age Low(Months)':
                dose?.ageLow?.ageValue === 0 ? '' : dose?.ageLow?.ageValue,
              'Age High(Months)':
                dose?.ageHigh?.ageValue === 2147483647
                  ? ''
                  : dose?.ageHigh?.ageValue,
              Title: dose?.title,
              Warning: dose?.warning,
              Instructions: dose?.instruction,
              Notes: dose?.note,
            });
          });
      });
      // Add a blank row for spacing between different medication items
      data.push({});
    });

    return data;
  };

  const getMedicationData = () => {
    const medications: MedicationItem[] = database.medications;

    const data: any = [];

    medications.forEach((medication: MedicationItem) => {
      // Add a header for the medication item
      data.push({
        'Medication Name': medication.name,
        Concentration: getConcentrationsArrayString(medication.concentrations),
        'Folder Name': '',
        'Protocol Name': '',
        Index: '',
        Dose: '',
        'Administration Route': '',
        'Min Single Dose': '',
        'Max Single Dose': '',
        'Max Total Dose': '',
        'Repeat Time(Sec)': '',
        'Weight Low(kg)': '',
        'Weight High(kg)': '',
        'Age Low(Months)': '',
        'Age High(Months)': '',
        Title: '',
        Warning: '',
        Instructions: '',
        Notes: '',
      });

      // Add rows for each medication dose (subItem)
      medication.subItems
        .sort((a: MedicationSubItem, b: MedicationSubItem) => {
          // Sort by Protocol Name (assuming `parentProtocol` is defined)
          const protocolNameA = a.parentProtocol.name.toLowerCase();
          const protocolNameB = b.parentProtocol.name.toLowerCase();
          if (protocolNameA < protocolNameB) return -1;
          if (protocolNameA > protocolNameB) return 1;
          return 0; // They are equal
        })
        .forEach((dose: MedicationSubItem) => {
          data.push({
            'Medication Name': '',
            Concentration: '',
            'Folder Name': dose?.parentProtocol.parent.name,
            'Protocol Name': dose?.parentProtocol.name,
            Index: dose?.index + 1,
            Dose: dose?.fullBasis,
            'Administration Route': dose?.routes.join(', '),
            'Min Single Dose':
              dose?.fullMinDose === '0' ? '' : dose?.fullMinDose,
            'Max Single Dose':
              dose?.fullMaxDose === '2147483647' ? '' : dose?.fullMaxDose,
            'Max Total Dose':
              dose?.fullMaxTotalDose === '2147483647'
                ? ''
                : dose?.fullMaxTotalDose,
            'Repeat Time(Sec)':
              dose?.repeatTimeSec <= 0 ? '' : dose?.repeatTimeSec,
            'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
            'Weight High(kg)':
              dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
            'Age Low(Months)':
              dose?.ageLow?.ageValue === 0 ? '' : dose?.ageLow?.ageValue,
            'Age High(Months)':
              dose?.ageHigh?.ageValue === 2147483647
                ? ''
                : dose?.ageHigh?.ageValue,
            Title: dose?.title,
            Warning: dose?.warning,
            Instructions: dose?.instruction,
            Notes: dose?.note,
          });
        });

      // Add a blank row for spacing between different medication items
      data.push({});
    });

    return data;
  };

  const getInfusionData = () => {
    const infusions: InfusionItem[] = database.infusions;

    const data: any = [];

    infusions.forEach((medication: InfusionItem) => {
      // Add a header for the medication item
      data.push({
        'Infusion Name': medication.name,
        Concentration: getConcentrationsArrayString(medication.concentrations),
        'Folder Name': '',
        'Protocol Name': '',
        Index: '',
        Dose: '',
        'Administration Route': '',
        'Min Single Dose': '',
        'Max Single Dose': '',
        'Max Total Dose': '',
        'Repeat Time(Sec)': '',
        'Weight Low(kg)': '',
        'Weight High(kg)': '',
        'Age Low(Months)': '',
        'Age High(Months)': '',
        Title: '',
        Warning: '',
        Instructions: '',
        Notes: '',
      });

      // Add rows for each medication dose (subItem)
      medication.subItems
        .sort((a: InfusionSubItem, b: InfusionSubItem) => {
          // Sort by Protocol Name (assuming `parentProtocol` is defined)
          const protocolNameA = a.parentProtocol.name.toLowerCase();
          const protocolNameB = b.parentProtocol.name.toLowerCase();
          if (protocolNameA < protocolNameB) return -1;
          if (protocolNameA > protocolNameB) return 1;
          return 0; // They are equal
        })
        .forEach((dose: InfusionSubItem) => {
          data.push({
            'Infusion Name': '',
            Concentration: '',
            'Folder Name': dose?.parentProtocol.parent.name,
            'Protocol Name': dose?.parentProtocol.name,
            Index: dose?.index + 1,
            Dose: dose?.fullBasis,
            'Administration Route': dose?.routes.join(', '),
            'Min Single Dose':
              dose?.fullMinDose === '0' ? '' : dose?.fullMinDose,
            'Max Single Dose':
              dose?.fullMaxDose === '2147483647' ? '' : dose?.fullMaxDose,
            'Max Total Dose':
              dose?.fullMaxTotalDose === '2147483647'
                ? ''
                : dose?.fullMaxTotalDose,
            'Repeat Time(Sec)':
              dose?.repeatTimeSec <= 0 ? '' : dose?.repeatTimeSec,
            'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
            'Weight High(kg)':
              dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
            'Age Low(Months)':
              dose?.ageLow?.ageValue === 0 ? '' : dose?.ageLow?.ageValue,
            'Age High(Months)':
              dose?.ageHigh?.ageValue === 2147483647
                ? ''
                : dose?.ageHigh?.ageValue,
            Title: dose?.title,
            Warning: dose?.warning,
            Instructions: dose?.instruction,
            Notes: dose?.note,
          });
        });

      // Add a blank row for spacing between different medication items
      data.push({});
    });

    return data;
  };

  const getElectricalData = () => {
    const electricals: ElectricalItem[] = database.electrical;

    const data: any = [];

    electricals.forEach((medication: ElectricalItem) => {
      // Add a header for the medication item
      data.push({
        'Electrical Name': medication.name,
        'Folder Name': '',
        'Protocol Name': '',
        Index: '',
        Shock: '',
        'Min Single Dose': '',
        'Max Single Dose': '',
        'Max Total Dose': '',
        'Repeat Time(Sec)': '',
        'Weight Low(kg)': '',
        'Weight High(kg)': '',
        'Age Low(Months)': '',
        'Age High(Months)': '',
        Title: '',
        Warning: '',
        Instructions: '',
        Notes: '',
      });

      // Add rows for each medication dose (subItem)
      medication.subItems
        .sort((a: ElectricalSubItem, b: ElectricalSubItem) => {
          // Sort by Protocol Name (assuming `parentProtocol` is defined)
          const protocolNameA = a.parentProtocol.name.toLowerCase();
          const protocolNameB = b.parentProtocol.name.toLowerCase();
          if (protocolNameA < protocolNameB) return -1;
          if (protocolNameA > protocolNameB) return 1;
          return 0; // They are equal
        })
        .forEach((dose: ElectricalSubItem) => {
          data.push({
            'Electrical Name': '',
            'Folder Name': dose?.parentProtocol.parent.name,
            'Protocol Name': dose?.parentProtocol.name,
            Index: dose?.index + 1,
            Shock: dose?.fullBasis,
            'Min Single Dose':
              dose?.fullMinDose === '0' ? '' : dose?.fullMinDose,
            'Max Single Dose':
              dose?.fullMaxDose === '2147483647' ? '' : dose?.fullMaxDose,
            'Max Total Dose':
              dose?.fullMaxTotalDose === '2147483647'
                ? ''
                : dose?.fullMaxTotalDose,
            'Repeat Time(Sec)':
              dose?.repeatTimeSec <= 0 ? '' : dose?.repeatTimeSec,
            'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
            'Weight High(kg)':
              dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
            'Age Low(Months)':
              dose?.ageLow?.ageValue === 0 ? '' : dose?.ageLow?.ageValue,
            'Age High(Months)':
              dose?.ageHigh?.ageValue === 2147483647
                ? ''
                : dose?.ageHigh?.ageValue,
            Title: dose?.title,
            Warning: dose?.warning,
            Instructions: dose?.instruction,
            Notes: dose?.note,
          });
        });

      // Add a blank row for spacing between different medication items
      data.push({});
    });

    return data;
  };

  const getEquipmentData = () => {
    const equipments: EquipmentItem[] = database.equipment;

    const data: any = [];

    equipments.forEach((medication: EquipmentItem) => {
      // Add a header for the medication item
      data.push({
        'Equipment Name': medication.name,
        Index: '',
        Value: '',
        'Weight Low(kg)': '',
        'Weight High(kg)': '',
        Title: medication.title,
        Warning: medication.warning,
        Instructions: medication.instruction,
        Notes: medication.note,
        'Paired Protocols': medication.parents
          .map((p: ProtocolItem) => p.name)
          .join(', '),
      });

      // Add rows for each medication dose (subItem)
      medication.options.forEach((dose: EquipmentOption, index) => {
        data.push({
          'Equipment Name': '',
          Index: index + 1,
          Value: dose?.amnt,
          'Weight Low(kg)': dose?.rangeLow === 0 ? '' : dose?.rangeLow,
          'Weight High(kg)':
            dose?.rangeHigh === 2147483647 ? '' : dose?.rangeHigh,
          Title: '',
          Warning: '',
          Instructions: '',
          Notes: '',
          'Paired Protocols': '',
        });
      });

      // Add a blank row for spacing between different medication items
      data.push({});
    });

    return data;
  };

  const getVitalData = () => {
    const vitals: VitalItem[] = database.vitals;

    const data: any = [];

    vitals.forEach((medication: VitalItem) => {
      // Add a header for the medication item
      data.push({
        'Vital Name': medication.name,
        Index: '',
        Value: '',
        'Weight Low(kg)': '',
        'Weight High(kg)': '',
      });

      // Add rows for each medication dose (subItem)
      medication.options.forEach((dose: VitalOption, index) => {
        data.push({
          'Vital Name': '',
          Index: index + 1,
          Value: formatForMatchingUnits(dose?.amntLow, dose?.amntHigh),
          'Weight Low(kg)': mapIndexToRange(dose?.rangeLow),
          'Weight High(kg)': mapIndexToRange(dose?.rangeHigh),
        });
      });

      // Add a blank row for spacing between different medication items
      data.push({});
    });

    return data;
  };

  const handleExportDatabase = () => {
    const departmentData = getDepartmentData();
    const protocolData = getProtocolData();
    const medicationData = getMedicationData();
    const infusionData = getInfusionData();
    const electricalData = getElectricalData();
    const equipmentData = getEquipmentData();
    const vitalData = getVitalData();

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create worksheet for department data
    const departmentWorksheet = XLSX.utils.aoa_to_sheet(departmentData);
    applyStylesToWorksheet(departmentWorksheet, false, true);
    XLSX.utils.book_append_sheet(workbook, departmentWorksheet, 'Department');

    // Create worksheet for protocol data
    const protocolWorksheet = XLSX.utils.json_to_sheet(protocolData);
    applyStylesToWorksheet(protocolWorksheet, true, false);
    XLSX.utils.book_append_sheet(workbook, protocolWorksheet, 'Protocol');

    // Create worksheet for medication data
    const medicationWorksheet = XLSX.utils.json_to_sheet(medicationData);
    applyStylesToWorksheet(medicationWorksheet);
    XLSX.utils.book_append_sheet(workbook, medicationWorksheet, 'Medications');

    const infusionWorksheet = XLSX.utils.json_to_sheet(infusionData);
    applyStylesToWorksheet(infusionWorksheet);
    XLSX.utils.book_append_sheet(workbook, infusionWorksheet, 'Infusions');

    const electricalWorksheet = XLSX.utils.json_to_sheet(electricalData);
    applyStylesToWorksheet(electricalWorksheet);
    XLSX.utils.book_append_sheet(workbook, electricalWorksheet, 'Electricals');

    const equipmentWorksheet = XLSX.utils.json_to_sheet(equipmentData);
    applyStylesToWorksheet(equipmentWorksheet);
    XLSX.utils.book_append_sheet(workbook, equipmentWorksheet, 'Equipment');

    const vitalWorksheet = XLSX.utils.json_to_sheet(vitalData);
    applyStylesToWorksheet(vitalWorksheet);
    XLSX.utils.book_append_sheet(workbook, vitalWorksheet, 'Vitals');

    // Generate the Excel buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true,
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${department.name}_OneDose_Data_${getFormattedDateTime(new Date(), false, true)}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const getLockedMessage = (item: any) => {
    switch (item.name) {
      case 'Keychains':
        return 'Keychains are disabled. Please make your agency public or protected in the "Settings" tab to enable this feature.';
      case 'Subtractable Weights':
        return 'Subtractable Weights are disabled for your agency. Please enable One Weight in the "Settings" tab to use this feature.';
      case 'Ambulances':
        return 'Ambulances are disabled for your agency. Please contact Hinckley Medical to upgrade your account to enable this feature.';
    }
  };

  const Badge = ({ text, type }: any) => {
    const badgeStyle = {
      display: 'inline-block',
      backgroundColor:
        type === 'admin'
          ? '#F2A7B6'
          : type === 'dept'
            ? '#CADAE9'
            : type === 'deptAdmin'
              ? '#FDCDB9'
              : '#C3DBB0',
      color: '#000',
      fontWeight: 600,
      padding: '3px 8px',
      fontSize: '12px',
      borderRadius: '15px',
      margin: '2px',
    };

    return <div style={badgeStyle}>{text}</div>;
  };

  return (
    <div className="screen-container">
      {reviewSideOutVisible && (
        <ReviewSideOut
          database={database}
          isVisible={reviewSideOutVisible} // Change 'visible' to 'isVisible'
          handleClose={() => {
            setReviewSideOutVisible(false);
          }}
          setVisible={setReviewSideOutVisible}
          handleAdd={() => {
            setReviewSideOutVisible(false);
          }}
          onPublish={() => {
            setReviewSideOutVisible(false);
            reloadDatabase();
            // handleCancel();
          }}
        />
      )}

      <ProtocolHeader
        name={department?.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={adminLevel > 1}
        isReviewActive={adminAccess}
        handleReview={() => {
          setReviewSideOutVisible(true);
        }}
        isExportButton={adminAccess}
        handleExport={() => setIsExportExcel(true)}
        exportActive={adminAccess}
        type={'protocol'}
        descriptionTitle={user.type === 'ADMIN' ? 'Department ID: ' : ''}
        description={user.type === 'ADMIN' ? '' + department?.id : ''}
        isCopyDescription={true}
        customDescription={
          user.type === 'ADMIN' ? (
            <h6
              className={`headerTextLight`}
              style={{
                fontWeight: '400',
                color: '#616161',
                marginRight: '2rem',
              }}
            >
              User ID:
              {user.id}
              <span>
                {isCopied && isCopied === user.id ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size="1rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(user.id, e)}
                  />
                )}
              </span>
            </h6>
          ) : undefined
        }
      />
      {isExportExcel && (
        <ConfirmModal
          isVisible={isExportExcel}
          title="Export to Excel"
          handleClose={() => {
            setIsExportExcel(false);
          }}
          handleSubmit={() => {
            handleExportDatabase();
            setIsExportExcel(false);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName="Extract"
          primaryDescription="Are you sure you want to export the Database as Excel?"
          secondaryDescription={
            'This will exctract all the Folders, Protocols, Medications, Infusions, Electricals, Equipment and Vitals in the Database into an Excel file.'
          }
        />
      )}
      {/* <div className="grid-container">
        {basicList.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleDepartmentClick(item)}
            >
              <div className="item-name">{item.name}</div>
              {item.icon}
              {item.name === 'Users' ? (
                <div className="user-details">
                  {user.type === 'ADMIN' && (
                    <Badge
                      text={`${item.detail.adminUsers} Admin`}
                      type="admin"
                    />
                  )}
                  {/* <Badge text={`${item.detail.deptUsers} Dept`} type="dept" /> 
                  <Badge
                    text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                    type="deptAdmin"
                  />
                  <Badge text={`${item.detail.deptUsers} Dept`} type="dept" />
                  <Badge text={`${item.detail.userUsers} Users`} type="user" />
                </div>
              ) : (
                <div className="item-count">{item.data.length} items</div>
              )}
            </div>
          );
        })}
      </div> */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // flex: 1,
            width: '100%',
            // padding: '10px',
            // marginTop: '.25rem',
            // marginBottom: '.25rem',
          }}
        >
          <AccordionItem
            title="Basic Items"
            rightTitle={basicList.length + ' items'}
            expanded={expanded.includes('basic')}
            expandedColorFlag
            onChange={(val) => {
              if (val) setExpanded([...expanded, 'basic']);
              else setExpanded(expanded.filter((item) => item !== 'basic'));
            }}
          >
            <div className="grid-container">
              {basicList.map((item, index) => {
                let locked = false;
                return (
                  <div
                    key={index}
                    className={`grid-item ${locked ? 'disabled' : ''}`}
                    onClick={() => handleDepartmentClick(item)}
                  >
                    <div className="item-name">{item.name}</div>
                    {item.icon}
                    {item.name === 'Users' ? (
                      <div className="user-details">
                        {user.type === 'ADMIN' && (
                          <Badge
                            text={`${item.detail.adminUsers} Admin`}
                            type="admin"
                          />
                        )}
                        <Badge
                          text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                          type="deptAdmin"
                        />
                        <Badge
                          text={`${item.detail.deptUsers} Dept`}
                          type="dept"
                        />
                        <Badge
                          text={`${item.detail.userUsers} Users`}
                          type="user"
                        />
                      </div>
                    ) : item.name === 'CPR Assist' ? (
                      <div className="item-count">
                        {database.cprModel?.activeID} items
                      </div>
                    ) : (
                      <div className="item-count">{item.data.length} items</div>
                    )}
                  </div>
                );
              })}
            </div>
          </AccordionItem>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // flex: 1,
            width: '100%',
            // padding: '10px',
            // marginTop: '.25rem',
            // marginBottom: '.25rem',
          }}
        >
          <AccordionItem
            title="Advanced Items"
            rightTitle={advancedList.length + ' items'}
            expanded={expanded.includes('advanced')}
            expandedColorFlag
            onChange={(val) => {
              if (val) setExpanded([...expanded, 'advanced']);
              else setExpanded(expanded.filter((item) => item !== 'advanced'));
            }}
          >
            <div className="grid-container">
              {advancedList.map((item, index) => {
                let locked =
                  (department.isPublic === false &&
                    department.keychainID == null &&
                    item.name === 'Keychains') ||
                  (!department.isOneWeightEnabled &&
                    item.name === 'Subtractable Weights') ||
                  (item.name === 'Ambulances' &&
                    department.softwarePlan !== 'PREMIUM') ||
                  (item.name === 'CPR Assist' &&
                    department.softwarePlan !== 'PREMIUM');
                return locked ? (
                  <Tooltip
                    title={getLockedMessage(item)}
                    arrow
                    open={locked !== true ? false : undefined}
                  >
                    <div
                      key={index}
                      className={`grid-item ${locked ? 'disabled' : ''}`}
                      onClick={() => {
                        if (!locked) handleDepartmentClick(item);
                      }}
                    >
                      <div className="item-name">{item.name}</div>
                      {item.icon}
                      {item.name === 'Users' ? (
                        <div className="user-details">
                          {user.type === 'ADMIN' && (
                            <Badge
                              text={`${item.detail.adminUsers} Admin`}
                              type="admin"
                            />
                          )}
                          {/* <Badge text={`${item.detail.deptUsers} Dept`} type="dept" /> */}
                          <Badge
                            text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                            type="deptAdmin"
                          />
                          <Badge
                            text={`${item.detail.deptUsers} Dept`}
                            type="dept"
                          />
                          <Badge
                            text={`${item.detail.userUsers} Users`}
                            type="user"
                          />
                        </div>
                      ) : item.name === 'CPR Assist' ? (
                        <></>
                      ) : (
                        <div className="item-count">
                          {item.data.length} items
                        </div>
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    key={index}
                    className={`grid-item ${locked ? 'disabled' : ''}`}
                    onClick={() => {
                      handleDepartmentClick(item);
                    }}
                  >
                    <div className="item-name">{item.name}</div>
                    {item.icon}
                    {item.name === 'Users' ? (
                      <div className="user-details">
                        {user.type === 'ADMIN' && (
                          <Badge
                            text={`${item.detail.adminUsers} Admin`}
                            type="admin"
                          />
                        )}
                        {/* <Badge text={`${item.detail.deptUsers} Dept`} type="dept" /> */}
                        <Badge
                          text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                          type="deptAdmin"
                        />
                        <Badge
                          text={`${item.detail.deptUsers} Dept`}
                          type="dept"
                        />
                        <Badge
                          text={`${item.detail.userUsers} Users`}
                          type="user"
                        />
                      </div>
                    ) : item.name === 'CPR Assist' ? (
                      <div className="item-count">
                        <Status
                          status={
                            database.cprModel
                              ? database.cprModel.activeID == null &&
                                database.cprModel.status === 'DRAFT'
                                ? 'DRAFT'
                                : 'ACTIVE'
                              : 'Inactive'
                          }
                        />
                      </div>
                    ) : (
                      <div className="item-count">{item.data.length} items</div>
                    )}
                  </div>
                );
              })}
            </div>
          </AccordionItem>
        </div>
      </div>
    </div>
  );
};

export default DatabasePage;
