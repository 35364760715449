// Note: EditDB for MedicationItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';

import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  mapModelSubItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import {
  MedicationConcentrationInput,
  MedicationRouteInput,
  ProgressStatus,
} from '../../API';
import {
  getActiveID,
  globals,
  isObjectEqual,
  removeTypename,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import MedicationItem, { cloneMedication } from '../model/MedicationItem';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../model/MedicationSubItem';
import ProtocolItem from '../model/ProtocolItem';
import {
  ProtocolJSON,
  createProtocol,
  getProtocolByID,
  validatePointerID,
} from './ProtocolDB';
import {
  getMedication,
  getMedicationDose,
  listMedications,
  medicationDosesByMedicationID,
} from '../../graphql/queries';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import {
  Concentration,
  Medication,
  MedicationDose,
  MedicationProtocol,
  MedicationRoute,
  ModelMetaData,
  PatientAgeGroup,
  User,
} from '../../models';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import {
  DraftChangeJSON,
  getDraftChangesByChangeID,
  updateDraftChangeItem,
} from './ReviewalDB';
import CPRItem from '../model/CPRItem';
import { getCPRByID } from './CprDB';

export type MedicationJSON = {
  name: string;
  concentration: Concentration[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  routes: string[];
  protocolOptions: MedicationProtocol[];
  taggedProtocols?: ProtocolItem[];

  rxNormCode?: string | null | undefined;
  nemsisRoutes?: MedicationRoute[] | null | undefined;
  minDose?: string | null | undefined;
  maxDose?: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;

  contraindication: string | null | undefined;
  note: string | null | undefined;
  warning: string | null | undefined;
  medClass: string | null | undefined;
  action: string | null | undefined;
  indication: string | null | undefined;
  interaction: string | null | undefined;
  onset: string | null | undefined;
  duration: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | keyof typeof ProgressStatus;
  overrideID?: string | null;
  activeID: string | null | undefined;
  version: string | null | undefined;
};

export type MedicationDoseJSON = {
  departmentID: string;
  medication: MedicationItem;
  cpr?: CPRItem;
  protocol?: ProtocolItem;

  index: number;
  basis: string;
  route: string[];
  nemsisRoutes?: MedicationRouteInput[];

  rangeLow: number;
  rangeHigh: number;
  ageLow?: number | null | undefined;
  ageHigh?: number | null | undefined;
  ageGroup?: PatientAgeGroup | null | undefined;
  repeatTime?: number | null | undefined;

  title?: string;
  warning?: string;
  instruction?: string;
  note?: string;

  maxDose?: string | null | undefined;
  minDose?: string | null | undefined;
  maxTotalDose?: string | null | undefined;
  calcMax?: string | null | undefined;
  calcMin?: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  overrideID?: string | null;
  activeID: string | null | undefined;
  version: string | null | undefined;
  createdAt: Date;
};

/**
 * Create a new PARENT medication in the database and choose the version
 * @param medication MedicationJSON JSON format or Model MedicationItem
 * @returns The successful MedicationItem or the error
 */
export const createParentMedication = async (
  department: DepartmentItem,
  medication: MedicationJSON | MedicationItem,
  previousItem?: MedicationItem
): Promise<Response> => {
  try {
    let json: MedicationJSON;
    if (medication instanceof MedicationItem) {
      let medItem = medication as MedicationItem;
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < medItem.subItems.length; i++) {
        if (!protocols.includes(medItem.subItems[i].parentProtocol))
          protocols.push(medItem.subItems[i].parentProtocol);
      }

      json = {
        name: medItem.name,
        concentration: medItem.concentrations,
        rangeLow: medItem.rangeLow ? medItem.rangeLow : 0,
        rangeHigh: medItem.rangeHigh ? medItem.rangeHigh : globals.MAX_VALUE,
        departmentID: department.id,
        routes: medItem.routes,
        protocolOptions: medItem.protocolOptions,
        status: medItem.status,
        activeID: medItem.activeID,
        overrideID: medItem.overrideID,
        version: medItem.version,
        taggedProtocols: protocols,

        createdBy: medItem.model.createdBy ? medItem.model.createdBy : '',
        modifiedBy: medItem.modifiedBy ? medItem.modifiedBy.id : undefined,

        contraindication: medItem.model.contraindication,
        note: medItem.model.note,
        warning: medItem.model.warning,
        medClass: medItem.model.medClass,
        action: medItem.model.action,
        indication: medItem.model.indication,
        interaction: medItem.model.interaction,
        onset: medItem.model.onset,
        duration: medItem.model.duration,

        rxNormCode: medItem.model.rxNormCode,
        nemsisRoutes: medItem.model.nemsisRoutes,
        minDose: medItem.model.minDose,
        maxDose: medItem.model.maxDose,
        metaData: medItem.model.metaData,
      };
    } else json = medication as MedicationJSON;

    /* Validate the routes are of string array or convert to string array */
    if (json.routes == null) json.routes = [];
    else if (typeof json.routes === 'string') json.routes = [json.routes];

    /* 
        1. Creating a DRAFT the first time
        2. Creating a DRAFT from an ACTIVE version
        3. Updating a DRAFT from a DRAFT version
        4. Creating a ARCHIVE from an ACTIVE version
    */

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    /* WARNING this only updates the old MedicationConcentrations */
    let oldConcentrations: MedicationConcentrationInput[] = [];
    if (json.concentration != null) {
      for (let i = 0; i < json.concentration.length; i++) {
        let con = json.concentration[i];
        let newConcentration: MedicationConcentrationInput = {
          firstAmnt: con.firstAmnt,
          secAmnt: con.secAmnt,
          firstUnit: con.firstUnit,
          secUnit: con.secUnit,
        };
        oldConcentrations.push(newConcentration);
      }
    }

    let m: Medication;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbMed = await DataStore.query(Medication, previousItem.uid);
      if (dbMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Medication does not exist could not update',
        };
      }

      m = await DataStore.save(
        Medication.copyOf(dbMed, (updated) => {
          updated.name = json.name;
          updated.concentration = removeTypename(oldConcentrations);
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.route = json.routes;
          updated.protocolOptions = json.protocolOptions;

          updated.contraindication = json.contraindication;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.medClass = json.medClass;
          updated.action = json.action;
          updated.indication = json.indication;
          updated.interaction = json.interaction;
          updated.onset = json.onset;
          updated.duration = json.duration;

          updated.modifiedBy = json.modifiedBy;
          updated.nemsisRoutes = removeTypename(nemsisRoutes);
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      // m = await executeQuery(
      //   graphqlOperation(createMedication, {
      //     name: json.name,
      //     concentration: removeTypename(json.concentration),
      //     rangeLow: json.rangeLow,
      //     rangeHigh: json.rangeHigh,
      //     departmentID: json.departmentID,
      //     route: json.routes,
      //     protocolOptions: json.protocolOptions,

      //     contraindication: json.contraindication,
      //     note: json.note,
      //     warning: json.warning,
      //     medClass: json.medClass,
      //     action: json.action,
      //     indication: json.indication,
      //     interaction: json.interaction,
      //     onset: json.onset,
      //     duration: json.duration,

      //     createdBy: json.createdBy,
      //     modifiedBy: json.modifiedBy,

      //     status: json.status,
      //     activeID: json.activeID,
      //     version: json.version,

      //     rxNormCode: json.rxNormCode,
      //     nemsisRoutes: removeTypename(json.nemsisRoutes),
      //     minDose: json.minDose,
      //     maxDose: json.maxDose,
      //     metaData: removeTypename(json.metaData),
      //   } as CreateMedicationInput)
      // );
      m = await DataStore.save(
        new Medication({
          name: json.name,
          concentration: removeTypename(oldConcentrations),
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          departmentID: json.departmentID,
          route: json.routes,
          protocolOptions: json.protocolOptions,

          contraindication: json.contraindication,
          note: json.note,
          warning: json.warning,
          medClass: json.medClass,
          action: json.action,
          indication: json.indication,
          interaction: json.interaction,
          onset: json.onset,
          duration: json.duration,

          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,

          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,

          rxNormCode: json.rxNormCode,
          nemsisRoutes: removeTypename(json.nemsisRoutes),
          minDose: json.minDose,
          maxDose: json.maxDose,
          metaData: removeTypename(json.metaData),
        })
      );
    }

    let medItem = new MedicationItem(m);

    let concenResp = await updateConcentrations(
      department,
      medItem,
      json.concentration
    );
    medItem.concentrations = concenResp.data as Concentration[];

    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        /* ACTIVE - pass ID, DRAFT and activeID is null (HAS NEVER BEEN PUBLISHED) - pass UID, DRAFT with previous publish - pass activeID */
        let medID: string | null = getActiveID(medItem);

        if (medID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The medication does not have a proper version ID 1 ' +
              medItem.uid +
              ' ' +
              medItem.activeID +
              ' ' +
              medItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          medID,
          json.modifiedBy,
          'Medication'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING MEDICATION:', e, medication);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchMedications = async (
  dep: DepartmentItem,
  db?: DatabaseResponse,
  useDataStore: boolean = true,
  waitForUsers: boolean = false,
  waitForConcentrations: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let medicationList: Medication[];
    if (useDataStore) {
      medicationList = await DataStore.query(Medication, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      medicationList = await executeQuery(listMedications, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    let meds: MedicationItem[] = [];
    let promises: Promise<User | null>[] = [];
    for (let i = 0; i < medicationList.length; i++) {
      let medication = new MedicationItem(medicationList[i]);
      medication.loadingConcentrations = true;
      promises.push(medication.findUser());
      /* Take out the active version if there is one */
      mapModelItems(medication, meds, medication.status, dep);
    }
    if (waitForUsers) await Promise.all(promises);

    /* TODO - dteermine multiple queries or one large query and mapping is better */
    let concentrationPromises: Promise<Concentration[]>[] = [];
    for (let i = 0; i < meds.length; i++) {
      let med = meds[i];
      concentrationPromises.push(
        DataStore.query(Concentration, (c) =>
          c.and((c) => [c.medicationID.eq(med.uid), c.departmentID.eq(dep.id)])
        ).then((concentrations: Concentration[]) => {
          med.concentrations = concentrations.sort((a, b) => {
            let aVal = a.secAmnt ? a.firstAmnt / a.secAmnt : a.firstAmnt;
            let bVal = b.secAmnt ? b.firstAmnt / b.secAmnt : b.firstAmnt;
            return aVal - bVal;
          });
          med.loadingConcentrations = false;
          return concentrations;
        })
      );
    }
    if (waitForConcentrations) await Promise.all(concentrationPromises);

    meds.sort((a, b) => a.getName().localeCompare(b.getName()));

    if (db) {
      let promises: any[] = [];
      for (let i = 0; i < meds.length; i++)
        promises.push(fetchMedicationDosesForMedication(db, dep, meds[i]));
      await Promise.all(promises);
    }

    return {
      type: ResponseType.Success,
      data: meds,
    };
  } catch (error) {
    console.error('Error fetching medications:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchMedicationDoses = async (
  dep: DepartmentItem,
  useDataStore: boolean = true,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let medicationList = await DataStore.query(MedicationDose, (m) =>
      m.and((m) => [
        m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
        m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
      ])
    );

    for (let i = 0; i < medicationList.length; i++) {
      let med = medicationList[i];
      if (med.status.includes('DRAFT') && med.departmentID !== dep.id)
        medicationList.splice(i, 1);
    }

    return {
      type: ResponseType.Success,
      data: medicationList,
    };
  } catch (error) {
    console.error('Error fetching medications:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchMedicationDosesForMedication = async (
  db: DatabaseResponse,
  dep: DepartmentItem,
  electrical: MedicationItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let electricalID = getActiveID(electrical);
    if (electricalID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The medication does not have a proper version ID 4' +
          electrical.uid +
          ' ' +
          electrical.activeID +
          ' ' +
          electrical.status,
      };
    }

    let electricalList;
    if (useDataStore) {
      electricalList = await DataStore.query(MedicationDose, (m) =>
        m.and((m) => [
          m.medicationID.eq(electricalID as string),
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      electricalList = await executeQuery(medicationDosesByMedicationID, {
        electricalID: electricalID,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }

    let electricalDoses: MedicationSubItem[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      let electricalDose = electricalList[i];
      if (
        electricalDose.status.includes('DRAFT') &&
        electricalDose.departmentID !== dep.id
      )
        continue;
      else {
        let protocol = db.protocols.find(
          (p) =>
            p.uid === electricalDose.protocolID ||
            p.activeID === electricalDose.protocolID
        );
        if (protocol) {
          let elec = new MedicationSubItem(
            electrical,
            protocol,
            electricalDose
          );
          // electricalDoses.push(elec);
          mapModelSubItems(elec, electricalDoses, elec.status, dep);
        }
      }
    }

    electricalDoses.sort((a, b) => a.index - b.index);
    electrical.subItems = electricalDoses;

    return {
      type: ResponseType.Success,
      data: electricalDoses,
    };
  } catch (error) {
    console.error('Error fetching electricalList:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Replace the existing concentrations with the new ones and add new ones
 * 1. Exisiting medication has more concentrations than the new one
 *    - Delete the extra concentrations
 * 2. Exisiting medication has less concentrations than the new one
 *    - Create the new concentrations
 * 3. Exisiting medication has the same number of concentrations as the new one
 *    - Update the existing concentrations
 * @param med The medication to update the concentrations
 * @param concentrations The new concentrations to update
 * @returns Success if the concentrations were updated or Failure if there was an error
 */
export const updateConcentrations = async (
  department: DepartmentItem,
  med: MedicationItem,
  concentrations: Concentration[],
  isDelete: boolean = false
): Promise<Response> => {
  try {
    /* First query for existing concentrations */
    let existingConcentrations = await DataStore.query(Concentration, (c) =>
      c.and((c) => [
        c.medicationID.eq(med.uid),
        c.departmentID.eq(department.id),
      ])
    );

    if (isDelete) {
      for (let i = 0; i < existingConcentrations.length; i++) {
        let con = existingConcentrations[i];
        let result = await DataStore.delete(Concentration, con.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not delete correctly',
          };
        }
      }

      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    } else {
      let concens: Concentration[] = [...concentrations];
      let oldConcens: Concentration[] = [...existingConcentrations];
      let n = 0;
      for (
        let i = 0;
        i < Math.min(concentrations.length, existingConcentrations.length);
        i++
      ) {
        let newCon = concentrations[i];
        let oldCon = existingConcentrations[i];
        /* Update the existing concentration */
        let result = await DataStore.save(
          Concentration.copyOf(oldCon, (updated) => {
            updated.firstAmnt = newCon.firstAmnt;
            updated.secAmnt = newCon.secAmnt;
            updated.firstUnit = newCon.firstUnit;
            updated.secUnit = newCon.secUnit;
            updated.status = med.status;
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not update correctly',
          };
        }
        /* Remove the concentration from the list */
        concens = concens.filter((c) => !isObjectEqual(c, newCon));
        oldConcens = oldConcens.filter((c) => !isObjectEqual(c, oldCon));
        n++;
      }

      /* Create any new concentrations */
      for (let i = 0; i < concens.length; i++) {
        let con = concens[i];
        let result = await DataStore.save(
          new Concentration({
            departmentID: department.id,
            medicationID: med.uid,
            firstAmnt: con.firstAmnt,
            secAmnt: con.secAmnt,
            firstUnit: con.firstUnit,
            secUnit: con.secUnit,
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not create correctly',
          };
        }
      }

      /* Delete any extra concentrations */
      for (let i = 0; i < oldConcens.length; i++) {
        let con = oldConcens[i];
        let result = await DataStore.delete(Concentration, con.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not delete correctly',
          };
        }
      }

      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    }
  } catch (e) {
    if (globals.debug) console.log('ERROR UPDATING CONCENTRATIONS:', e, med);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Propagate the concentrations to all the medications that are associated with the department
 *  - This should only be called when creating a new medication
 * @param department The curren department to propagate the concentrations from
 * @param med The medication to update the concentrations
 * @param concentrations The new concentrations to update
 * @returns Success if the concentrations were updated or Failure if there was an error
 */
export const propagateMedicationConcentrations = async (
  department: DepartmentItem,
  med: MedicationItem,
  concentrations: Concentration[]
): Promise<Response> => {
  try {
    let deps = department.allSubDeps;
    if (deps == null || deps.length === 0)
      return {
        type: ResponseType.Success,
        data: concentrations,
      };
    let concens: Concentration[] = [...concentrations];
    let medID: string | null = getActiveID(med);
    if (medID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The medication does not have a proper version ID 2' +
          med.uid +
          ' ' +
          med.activeID +
          ' ' +
          med.status,
      };
    }
    for (let i = 0; i < deps.length; i++) {
      let dep = deps[i];
      /* Create any new concentrations */
      for (let j = 0; j < concens.length; j++) {
        let con = concens[j];
        let result = await DataStore.save(
          new Concentration({
            departmentID: dep.id,
            medicationID: medID,
            firstAmnt: con.firstAmnt,
            secAmnt: con.secAmnt,
            firstUnit: con.firstUnit,
            secUnit: con.secUnit,
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The concentration did not create correctly',
          };
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: concentrations,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR UPDATING CONCENTRATIONS:', e, med);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new medication DOSE in the database and choose the version
 * @param medication MedicationDoseJSON JSON format or Model MedicationSubItem
 * @returns The successful MedicationSubItem or the error
 */
export const createMedicationDoseItem = async (
  medication: MedicationDoseJSON | MedicationSubItem,
  previousItem?: MedicationSubItem
): Promise<Response> => {
  try {
    let json: MedicationDoseJSON;
    if (medication instanceof MedicationSubItem) {
      let medItem = medication as MedicationSubItem;
      json = {
        departmentID: medItem.departmentID,
        medication: medItem.parent,
        protocol: medItem.parentProtocol,
        cpr: medItem.parentCPR,
        index: medItem.index,
        basis: medItem.fullBasis,
        route: medItem.routes,
        nemsisRoutes: medItem.nemsisRoutes,
        rangeLow: medItem.rangeLow,
        rangeHigh: medItem.rangeHigh,
        ageLow: medItem.ageLow ? medItem.ageLow.ageValue : null,
        ageHigh: medItem.ageHigh ? medItem.ageHigh.ageValue : null,
        ageGroup: medItem.ageGroup,
        repeatTime: medItem.repeatTimeSec,
        title: medItem.title,
        warning: medItem.warning,
        instruction: medItem.instruction,
        note: medItem.note,
        maxDose: medItem.fullMaxDose,
        minDose: medItem.fullMinDose,
        maxTotalDose: medItem.fullMaxTotalDose,
        calcMax: medItem.model.calcMax,
        calcMin: medItem.model.calcMin,
        createdBy: medItem.model.createdBy,
        modifiedBy: medItem.modifiedBy ? medItem.modifiedBy.id : undefined,
        status: medItem.status,
        activeID: medItem.activeID,
        overrideID: medItem.overrideID,
        version: medItem.version,
        createdAt: new Date(medItem.model.createdAt),
      };
    } else json = medication as MedicationDoseJSON;

    /* Validate the routes are of string array or convert to string array */
    if (json.route == null) json.route = [];
    else if (typeof json.route === 'string') json.route = [json.route];

    /* 
        1. Creating a DRAFT the first time
        2. Creating a DRAFT from an ACTIVE version
        3. Updating a DRAFT from a DRAFT version
        4. Creating a ARCHIVE from an ACTIVE version
    */

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    let m: MedicationDose;

    let cprID: string | null = json.cpr ? getActiveID(json.cpr) : null;
    let protID: string | null = cprID
      ? null
      : json.protocol
        ? getActiveID(json.protocol)
        : null;
    if (protID == null && cprID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol or CPR does not have a proper version ID ' +
          json.protocol,
      };
    }
    let medID: string | null = getActiveID(json.medication);
    if (medID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The medication does not have a proper version ID 3' +
          json.medication,
      };
    }

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbMed = await DataStore.query(MedicationDose, previousItem.uid);
      if (dbMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Medication Dose does not exist could not update',
        };
      }

      m = await DataStore.save(
        MedicationDose.copyOf(dbMed, (updated) => {
          updated.index = json.index;
          updated.basis = json.basis;
          updated.route = json.route;
          updated.nemsisRoutes = json.nemsisRoutes;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.ageLow = json.ageLow;
          updated.ageHigh = json.ageHigh;
          updated.ageGroup = json.ageGroup;
          updated.repeatTime = json.repeatTime
            ? json.repeatTime + ''
            : undefined;
          updated.title = json.title;
          updated.warning = json.warning;
          updated.instruction = json.instruction;
          updated.note = json.note;

          updated.maxDose = json.maxDose;
          updated.minDose = json.minDose;
          updated.maxTotalDose = json.maxTotalDose;
          updated.calcMax = json.calcMax;
          updated.calcMin = json.calcMin;

          updated.status = json.status;
          updated.activeID = json.activeID;
          updated.version = json.version ? json.version : 'v1.0.0';
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */

      m = await DataStore.save(
        new MedicationDose({
          departmentID: json.departmentID,
          medicationID: medID,
          cprAssistID: cprID,
          protocolID: protID,
          index: json.index,
          basis: json.basis,
          route: json.route,
          nemsisRoutes: json.nemsisRoutes,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          ageLow: json.ageLow,
          ageHigh: json.ageHigh,
          ageGroup: json.ageGroup,
          repeatTime: json.repeatTime ? json.repeatTime + '' : undefined,
          title: json.title,
          warning: json.warning,
          instruction: json.instruction,
          note: json.note,
          maxDose: json.maxDose,
          minDose: json.minDose,
          maxTotalDose: json.maxTotalDose,
          calcMax: json.calcMax,
          calcMin: json.calcMin,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID ? json.overrideID : undefined,
          version: json.version ? json.version : 'v1.0.0',
          createdAt: new Date().toISOString(),
        })
      );
    }
    let parent = json.protocol ? json.protocol : json.cpr;
    let medItem = new MedicationSubItem(json.medication, parent, m);
    if (json.cpr) medItem.parentCPR = json.cpr;

    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    if (globals.debug)
      console.log('ERROR CREATING MEDICATION DOSE:', e, medication);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Duplicate the medication doses from the one protocol to another protocol
 * @param protocol The new protocol that has just been created
 * @param previousProtocol The protocol that was duplicated
 * @param isDraft True if the protocol is a draft version, otherwise will update the active version
 * @returns An array of the medications that were duplicated
 */
export const duplicateMedicationDose = async (
  department: DepartmentItem,
  dose: MedicationSubItem,
  user: User,
  protocolItem?: ProtocolItem
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID = protocolItem
      ? protocolItem.status === ProgressStatus.DRAFT && protocolItem.activeID
        ? protocolItem.activeID
        : protocolItem.uid
      : dose.parentProtocol.status === ProgressStatus.DRAFT &&
          dose.parentProtocol.activeID != null
        ? dose.parentProtocol.activeID
        : dose.parentProtocol.uid;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          dose.parentProtocol +
          ' ' +
          protID,
      };
    }

    let clone = cloneMedicationSubItem(dose);
    clone.parentProtocol = protocolItem ? protocolItem : dose.parentProtocol;
    clone.departmentID = department.id;
    clone.status = ProgressStatus.DRAFT;
    clone.activeID = null;
    clone.version = 'v1.0.0';
    clone.modifiedBy = user;

    let result = await createMedicationDoseItem(clone);
    if (result.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: result.data,
      };
    }

    if (globals.debug)
      console.log('Successfully duplicated medication Dose', result.data);
    return {
      type: ResponseType.Success,
      data: result.data as MedicationSubItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the medications that are associated with the protocol
 * @param protocol The protocol to delete the medication from
 * @param modifiedBy The user that modified the medication
 * @returns Success: data is the number of medications deleted or Failure if there was an error
 */
export const deleteMedicationsForProtocol = async (
  department: DepartmentItem,
  protocol: ProtocolItem,
  modifiedBy: User,
  isSoft: boolean = true
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      protocol.status === ProgressStatus.ACTIVE ||
      (protocol.status === ProgressStatus.DRAFT && protocol.activeID == null)
        ? protocol.uid
        : protocol.activeID;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          protocol.uid +
          ' ' +
          protocol.activeID +
          ' ' +
          protocol.status,
      };
    }

    /* Get all the Medications that the department has */
    let doses = await DataStore.query(MedicationDose, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
          c.status.eq(ProgressStatus.DRAFT_DELETE),
        ]),
        c.protocolID.eq(protID as string),
      ])
    );

    let n = 0;
    for (let i = 0; i < doses.length; i++) {
      let med = doses[i];
      if (med.status === 'ACTIVE' && isSoft) {
        let result = await DataStore.save(
          MedicationDose.copyOf(med, (updated) => {
            updated.status = ProgressStatus.DRAFT_DELETE;
            updated.modifiedBy = modifiedBy.id;
          })
        );
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The medication did not delete correctly',
          };
        }
      } else {
        console.log('Deleting medication:', med);
        let result = await DataStore.delete(MedicationDose, med.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The medication did not delete correctly',
          };
        }
      }
      n++;
    }

    n = 0;

    /* Get all the Medications that the department has */
    let medicationList = await DataStore.query(Medication, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
        ]),
      ])
    );

    let medications: MedicationItem[] = [];
    for (let i = 0; i < medicationList.length; i++) {
      medications.push(new MedicationItem(medicationList[i]));
    }

    for (let i = 0; i < medications.length; i++) {
      let med = medications[i];

      let protocolOptions = med.protocolOptions;

      let medProtocol: MedicationProtocol | undefined = undefined;
      for (let i = 0; i < protocolOptions.length; i++) {
        let po = protocolOptions[i];
        if (po.protocolID === protID) {
          medProtocol = po;
          break;
        }
      }
      /* If there is no medication protocol then there is a problem */
      if (!medProtocol) continue;

      /* Remove the medication protocol from the medication */
      let newOptions = protocolOptions.filter(
        (p) => p.protocolID !== (medProtocol as MedicationProtocol).protocolID
      );

      let newMed = cloneMedication(med);
      newMed.protocolOptions = newOptions;
      newMed.status = ProgressStatus.DRAFT;
      newMed.activeID =
        med.status === ProgressStatus.ACTIVE ? med.uid : med.activeID;
      newMed.version = upgradeVersion(
        med.version == null ? 'v1.0.0' : med.version
      );
      newMed.modifiedBy = modifiedBy;

      //Increment the count modified
      n++;

      createParentMedication(department, newMed, med)
        .then((result) => {
          if (result.type === ResponseType.Failure) {
            console.error('ERROR DELETING MEDICATION:', result.data);
          }
        })
        .catch((e) => {
          console.error('ERROR DELETING MEDICATION:', e);
        });
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Check if the protocol is already pointing at the medication
 *    - Otherwise add the medication to the protocol and create a new protocol draft
 * @param medication The medication o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the medication
 * @param modifiedBy The user that modified the medication
 * @returns Success if the medication is paired with the protocol and returns a MedicationProtocol or Failure if there was an error
 */
export const validateMedicationProtocolPairing = async (
  medication: MedicationItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    let medicationProtocol: MedicationProtocol;
    let medID: string | null = getActiveID(medication);
    let pairedID = protocol.model.medicationIDs?.find(
      (id: string) => id === medID
    );

    if (medID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The medication does not have a proper version ID 4' +
          medication.uid +
          ' ' +
          medication.activeID +
          ' ' +
          medication.status,
      };
    }

    /* Create a new protocol draft if the medication is not paired with the protocol */
    if (pairedID == null) {
      let medIds = protocol.model.medicationIDs
        ? protocol.model.medicationIDs
        : [];
      medIds.push(medID);

      let draftProtocol: ProtocolJSON = {
        departmentID: protocol.parent.departmentID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDepsIDs: protocol.pairedDeps
          ? protocol.pairedDeps.map((d) => d.id)
          : (protocol.pairedDepIDs ?? []),
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: medIds,
        infusions: protocol.infusions.map((i) => i.uid),
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: protocol.electrical.map((e) => e.parent.uid),

        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        isRestrictive: protocol.isRestrictive,
        keychainID: protocol.keychainID,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      medicationProtocol = new MedicationProtocol({
        protocolID: protocol.uid,
        options: [],
      });
    } else {
      /* Find the medication protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let mo = medication.model.protocolOptions.find(
        (p) => p.protocolID === protID
      );
      if (mo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication protocol does not exist',
        };
      }
      medicationProtocol = mo;
    }

    /* Otherwise we just need to update the MedicationProtocol */
    return {
      type: ResponseType.Success,
      data: medicationProtocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the medication to the database
 *    1. Create a new ARCHEIVED medication based on the current ACTIVE medication
 *    2. Update the ACTIVE medication with the new information
 *    3. Delete the DRAFT medication
 * @param draftMedicationItem The medication to publish
 */
export const publishMedication = async (
  department: DepartmentItem,
  draftMedicationItem: MedicationItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the medication is configured correctly as a draft version */
    if (draftMedicationItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The medication is not a draft version',
      };
    }

    let dbMed = await DataStore.query(Medication, draftMedicationItem.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The medication does not exist',
      };
    }

    let activeMedication: Medication;

    /* Use case 1: Creating the FIRST active version */
    if (draftMedicationItem.activeID == null) {
      /* Update the draft medication to be active */
      activeMedication = await DataStore.save(
        Medication.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
      draftMedicationItem.status = ProgressStatus.ACTIVE;
      updateConcentrations(
        department,
        draftMedicationItem,
        draftMedicationItem.concentrations
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active medication item */
      let id: string = draftMedicationItem.activeID;
      let curMedication = await DataStore.query(Medication, id);

      /* Base Case 3 -- check if the active medication exists */
      if (curMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active medication does not exist',
        };
      }

      let archeived = new MedicationItem(curMedication);
      archeived.status = ProgressStatus.ARCHIVE;
      archeived.activeID = curMedication.id;
      let currentConcentrations = await DataStore.query(Concentration, (c) =>
        c.and((c) => [
          c.medicationID.eq(archeived.uid),
          c.departmentID.eq(department.id),
        ])
      );

      // 2. Create a new ARCHEIVED medication based on the current ACTIVE medication
      let archiveResult: Response = await createParentMedication(
        department,
        archeived
      );
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archeived = archiveResult.data as MedicationItem;

      //2.5 Update the Concentrations for the medication
      let concentrationResult: Response = await updateConcentrations(
        department,
        archeived,
        currentConcentrations
      );
      if (concentrationResult.type === ResponseType.Failure)
        return concentrationResult;
      else if (globals.debug)
        console.log('Concentration Updated:', concentrationResult.data);

      // 2. Update the ACTIVE medication with the new information
      activeMedication = await DataStore.save(
        Medication.copyOf(curMedication, (updated) => {
          updated.name = draftMedicationItem.name;
          updated.concentration = draftMedicationItem.model.concentration
            ? draftMedicationItem.model.concentration
            : [];
          updated.rangeLow = draftMedicationItem.rangeLow;
          updated.rangeHigh = draftMedicationItem.rangeHigh;
          updated.route = draftMedicationItem.model.route;
          updated.protocolOptions = draftMedicationItem.protocolOptions;

          updated.contraindication = draftMedicationItem.contraindication;
          updated.note = draftMedicationItem.note;
          updated.warning = draftMedicationItem.warning;
          updated.medClass = draftMedicationItem.medClass;
          updated.action = draftMedicationItem.action;
          updated.indication = draftMedicationItem.indication;
          updated.interaction = draftMedicationItem.interaction;
          updated.onset = draftMedicationItem.onset;
          updated.duration = draftMedicationItem.duration;

          updated.createdBy = draftMedicationItem.model.createdBy;
          updated.modifiedBy = draftMedicationItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftMedicationItem.overrideID;
          updated.version = draftMedicationItem.version;

          updated.rxNormCode = draftMedicationItem.rxNormCode;
          updated.nemsisRoutes = draftMedicationItem.model.nemsisRoutes;
          updated.minDose = draftMedicationItem.model.minDose;
          updated.maxDose = draftMedicationItem.model.maxDose;
          updated.metaData = draftMedicationItem.model.metaData;
        })
      );

      // //3 Update the Concentrations for the medication
      concentrationResult = await updateConcentrations(
        department,
        new MedicationItem(activeMedication),
        draftMedicationItem.concentrations
      );
      if (concentrationResult.type === ResponseType.Failure)
        return concentrationResult;
      else console.log('Concentration Updated:', concentrationResult.data);

      // 4. Delete the DRAFT medication
      let draftMedication = await DataStore.delete(
        Medication,
        draftMedicationItem.uid
      );
      if (draftMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft medication does not exist',
        };
      }

      // 5. Delete the Concentrations for the medication
      updateConcentrations(
        department,
        draftMedicationItem,
        draftMedicationItem.concentrations,
        true
      );

      // 6. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeMedication.id, archeived.uid);

      // 7. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeMedication.id,
          changeType: draftChangeItem.changeType,
          previousItem: archeived.uid,
          isClosed: true,
        };

        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let medItem = new MedicationItem(activeMedication);
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the medication to the database
 *    1. Create a new ARCHEIVED medication based on the current ACTIVE medication
 *    2. Update the ACTIVE medication with the new information
 *    3. Delete the DRAFT medication
 * @param draftMedicationItem The medication to publish
 */
export const publishMedicationDoses = async (
  draftMedicationItem: MedicationSubItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the medication is configured correctly as a draft version */
    if (
      draftMedicationItem.status !== ProgressStatus.DRAFT &&
      draftMedicationItem.status !== ProgressStatus.DRAFT_DELETE
    ) {
      return {
        type: ResponseType.Failure,
        data: 'The medication is not a draft version',
      };
    }

    let protocol = draftMedicationItem.parentProtocol;
    let cpr = draftMedicationItem.parentCPR;
    let cprID: string | null = cpr ? getActiveID(cpr) : null;
    let protID: string | null = cprID
      ? null
      : protocol
        ? getActiveID(protocol)
        : null;

    let dbMed = await DataStore.query(MedicationDose, draftMedicationItem.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The medication dose does not exist',
      };
    }

    /* Base Case 1: Fully Deleting a Item */
    if (draftMedicationItem.status === ProgressStatus.DRAFT_DELETE) {
      /* Remove the delete draft medication and switch the activeID version to DELETED */
      let med = await DataStore.delete(MedicationDose, draftMedicationItem.uid);
      if (med == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft medication does not exist',
        };
      }

      if (draftMedicationItem.activeID == null) {
        return {
          type: ResponseType.Success,
          data: 'The medication dose was deleted',
        };
      }

      /* Update the active medication to be DELETED */
      let activeMed = await DataStore.query(
        MedicationDose,
        draftMedicationItem.activeID
      );
      if (activeMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active medication does not exist',
        };
      }

      let deletedMed = await DataStore.save(
        MedicationDose.copyOf(activeMed, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      let medItem = new MedicationSubItem(
        draftMedicationItem.parent,
        draftMedicationItem.parentProtocol,
        deletedMed
      );

      return {
        type: ResponseType.Success,
        data: medItem,
      };
    }

    let activeMedication: MedicationDose;

    /* Use case 1: Creating the FIRST active version */
    if (draftMedicationItem.activeID == null) {
      /* Update the draft medication to be active */
      activeMedication = await DataStore.save(
        MedicationDose.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active medication item */
      let id: string = draftMedicationItem.activeID;
      let curMedication = await DataStore.query(MedicationDose, id);

      /* Base Case 3 -- check if the active medication exists */
      if (curMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active medication does not exist by ID',
        };
      }

      let activeItem =
        draftMedicationItem.activeItem as MedicationSubItem | null;
      if (activeItem == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active medication does not exist by Mapping',
        };
      }

      activeItem.status = ProgressStatus.ARCHIVE;
      activeItem.activeID = curMedication.id;

      // 2. Create a new ARCHEIVED medication based on the current ACTIVE medication
      let archiveResult: Response = await createMedicationDoseItem(activeItem);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      activeItem = archiveResult.data as MedicationSubItem;

      // 2. Update the ACTIVE medication with the new information

      activeMedication = await DataStore.save(
        MedicationDose.copyOf(curMedication, (updated) => {
          updated.protocolID = protID;
          updated.cprAssistID = cprID;
          updated.index = draftMedicationItem.index;
          updated.basis = draftMedicationItem.fullBasis;
          updated.route = draftMedicationItem.routes;
          updated.nemsisRoutes = draftMedicationItem.nemsisRoutes;
          updated.rangeLow = draftMedicationItem.rangeLow;
          updated.rangeHigh = draftMedicationItem.rangeHigh;
          updated.ageLow = draftMedicationItem.ageLow
            ? draftMedicationItem.ageLow.ageValue
            : null;
          updated.ageHigh = draftMedicationItem.ageHigh
            ? draftMedicationItem.ageHigh.ageValue
            : null;
          updated.ageGroup = draftMedicationItem.ageGroup;
          updated.repeatTime = draftMedicationItem.repeatTimeSec
            ? draftMedicationItem.repeatTimeSec + ''
            : undefined;
          updated.title = draftMedicationItem.title;
          updated.warning = draftMedicationItem.warning;
          updated.instruction = draftMedicationItem.instruction;
          updated.note = draftMedicationItem.note;
          updated.maxDose = draftMedicationItem.fullMaxDose;
          updated.minDose = draftMedicationItem.fullMinDose;
          updated.maxTotalDose = draftMedicationItem.fullMaxTotalDose;
          updated.calcMax = draftMedicationItem.model.calcMax;
          updated.calcMin = draftMedicationItem.model.calcMin;
          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.overrideID = draftMedicationItem.overrideID;
          updated.version = draftMedicationItem.version
            ? draftMedicationItem.version
            : 'v1.0.0';
          updated.modifiedBy = draftMedicationItem.modifiedBy
            ? draftMedicationItem.modifiedBy.id
            : undefined;
        })
      );
      if (globals.debug)
        console.log('PUBLISHED MEDICATION DOSE', activeMedication);
      // 3. Delete the DRAFT medication

      let draftMedication = await DataStore.delete(
        MedicationDose,
        draftMedicationItem.uid
      );
      if (draftMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft medication does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeMedication.id, activeItem.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeMedication.id,
          changeType: draftChangeItem.changeType,
          previousItem: activeItem.uid,
          isClosed: true,
        };

        updateDraftChangeItem(new_dc).then((result) => {
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let medItem = new MedicationSubItem(
      draftMedicationItem.parent,
      draftMedicationItem.parentProtocol,
      activeMedication
    );
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will update the medication in the database
 * @param medicationItem The medication to delete from the database
 * @param isSoft True if the medication should be soft deleted, false if it should be hard deleted
 * @returns Success if the medication was deleted or Failure if there was an error
 */
export const deleteMedication = async (
  medicationItem: MedicationItem,
  isSoft: boolean = true
): Promise<Response> => {
  try {
    let id: string = medicationItem.uid;
    if (isSoft) {
      let medication = await DataStore.query(Medication, id);
      if (medication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication does not exist',
        };
      }

      let result = await DataStore.save(
        Medication.copyOf(medication, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication did not update correctly',
        };
      }
    } else {
      let medication = await DataStore.delete(Medication, id);
      if (medication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: medicationItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will create a new medication draft in the database
 * @param department The department to check for medication drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isMedicationDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Medication, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(department.id),
      ])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will create a new medication draft in the database
 * @param department The department to check for medication drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isMedicationDosesDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(MedicationDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(department.id),
      ])
    );

    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get all the medication drafts from the database
 * @param department The department to get the medication drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getMedicationDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = db.medications.filter(
      (m) => m.status.includes('DRAFT') && m.departmentID === db.department.id
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if(model.status === "DELETED")
      // message = `Deleted Medication: ${model.name}`;
      if (model.status === 'DRAFT_DELETE')
        message = `Deleted Medication: ${model.name}`;
      else if (model.activeID == null)
        message = `Created Medication: ${model.name}`;
      else message = `Updated Medication: ${model.name}`;
      updates.push({
        model: model,
        title: 'Medication ' + model.name,
        message: message,
        changeType: DraftChangeType.MEDICATION,
      });
    }

    let doseUpdates = db.medicationDoses.filter(
      (d) => d.status.includes('DRAFT') && d.departmentID === db.department.id
    );
    console.log('DOSE UPDATES', doseUpdates);
    for (let j = 0; j < doseUpdates.length; j++) {
      let dose = doseUpdates[j];
      let doseMessage = '';
      let parentName = dose.model.protocolID
        ? dose.parentProtocol.name
        : 'CPR Assist';
      if (dose.status === 'DRAFT_DELETE')
        doseMessage = `Deleted Medication Dose: ${dose.name} in ${parentName}`;
      else if (dose.activeID == null)
        doseMessage = `Created Medication Dose: ${dose.name} in ${parentName}`;
      else
        doseMessage = `Updated Medication Dose: ${dose.name} in ${parentName}`;
      updates.push({
        model: dose,
        title: 'Medication Dose ' + dose.name,
        message: doseMessage,
        changeType: DraftChangeType.MEDICATION_DOSE,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

function getChangeDescription(draftItem: MedicationItem): string {
  if (draftItem.activeItem == null)
    return `Created Medication: ${draftItem.name}`;
  else return `Updated  Medication: ${draftItem.name}`;
}

export const convertMedicationChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Medication ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as MedicationItem),
      changeType: DraftChangeType.MEDICATION,
    };

    return update;
  } catch (error) {
    return null;
  }
};

/**
 * Remove all the medication drafts from the database - User choose to override the drafts
 * @param department The department to remove the drafts from
 * @returns Success if the drafts were removed or Failure if there was an error
 */
export const removeCurrentMedicationDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftMedications = await DataStore.query(Medication, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(db.department.id),
      ])
    );
    for (let i = 0; i < draftMedications.length; i++) {
      let med: Medication = draftMedications[i];
      await DataStore.delete(med);
      updates.push({
        model: med,
        message: `Removed Medication Draft for: ${med.name}`,
      });
    }

    /* Now remove the medication doses */
    let draftMedicationDoses = await DataStore.query(MedicationDose, (c) =>
      c.and((c) => [
        c.or((c) => [c.status.eq('DRAFT'), c.status.eq('DRAFT_DELETE')]),
        c.departmentID.eq(db.department.id),
      ])
    );
    for (let j = 0; j < draftMedicationDoses.length; j++) {
      let dose: MedicationDose = draftMedicationDoses[j];
      await DataStore.delete(dose);
      let parent = dose.protocolID
        ? await getProtocolByID(db, dose.protocolID || '')
        : dose.cprAssistID
          ? await getCPRByID(db, dose.cprAssistID || '')
          : null;
      let medication = await getMedicationByID(db, dose.medicationID);
      if (parent == null || medication == null) continue;
      updates.push({
        model: dose,
        message: `Removed ${medication.name} dose in ${parent.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getMedicationByID = async (
  db: DatabaseResponse,
  id: string
): Promise<MedicationItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = db.medications.find((c) => c.uid === id);
      if (dbMed != null) return resolve(dbMed);
      else {
        executeSingleQuery(getMedication, { id: id }, 1500)
          .then((medication) => {
            if (medication == null) {
              resolve(null);
            } else {
              let med = new MedicationItem(medication);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(med);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/* GraphQL API Queries */
export const getMedicationDoseByID = async (
  db: DatabaseResponse,
  id: string
): Promise<MedicationSubItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = db.medicationDoses.find((c) => c.uid === id);
      if (dbMed != null) return resolve(dbMed);
      else {
        executeSingleQuery(getMedicationDose, { id: id }, 1500)
          .then((medDose: MedicationDose | null | undefined) => {
            if (medDose == null) {
              resolve(null);
            } else {
              let promises = [
                getProtocolByID(db, medDose.protocolID || ''),
                getMedicationByID(db, medDose.medicationID),
              ];
              Promise.all(promises).then((results) => {
                let protocol = results[0] as ProtocolItem;
                let medication = results[1] as MedicationItem;
                if (protocol == null || medication == null) resolve(null);

                let medicationDose = new MedicationSubItem(
                  medication,
                  protocol,
                  medDose
                );
                resolve(medicationDose);
              });

              // let medication = new MedicationItem(med);
              // /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              // resolve(medication);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

export const checkMediationDoseEquals = (
  dose1: MedicationDose,
  dose2: MedicationDose,
  isFullCheck: boolean = false
): boolean => {
  if (dose1 == null || dose2 == null) return false;

  if (isFullCheck) {
    return isObjectEqual(dose1, dose2);
  } else {
    if (
      dose1.basis !== dose2.basis ||
      dose1.rangeLow !== dose2.rangeLow ||
      dose1.rangeHigh !== dose2.rangeHigh ||
      dose1.route !== dose2.route ||
      dose1.medicationID !== dose2.medicationID ||
      dose1.protocolID !== dose2.protocolID
    ) {
      return false;
    }
  }
  return true;
};

export const deleteMedicationDoseItem = async (
  medicationDose: MedicationSubItem,
  isSoftDelete: boolean = true
): Promise<Response> => {
  try {
    let dbMed = await DataStore.query(MedicationDose, medicationDose.uid);
    if (dbMed == null) {
      return {
        type: ResponseType.Failure,
        data: 'The medication does not exist',
      };
    }
    if (isSoftDelete) {
      let result = await DataStore.save(
        MedicationDose.copyOf(dbMed, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );
      medicationDose.status = ProgressStatus.DELETED;
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication did not update correctly',
        };
      }
    } else {
      console.log('FUNCTION TO DELETE MEDICATION DOSE', dbMed);
      let resp = await DataStore.delete(MedicationDose, dbMed.id);
      if (resp == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication does not exist',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: medicationDose,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
