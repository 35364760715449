import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import './Navbar.scss';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleGetDepartment,
  handleLogOut,
  handleShowSideBar,
} from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { Auth, DataStore, Hub } from 'aws-amplify';
import {
  DatabaseResponse,
  loadDatabase,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';
import logo from '../../../assets/images/OneDose_TM_Logo.png';
import { BiBell, BiExit } from 'react-icons/bi';
import { globals, hasAdminUserAccess } from '../../_global/common/Utils';
import { FaChevronDown, FaUserShield, FaUserSlash } from 'react-icons/fa6';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { GoArrowSwitch } from 'react-icons/go';
import SwitchDepartmentModal from '../Modal/SwitchDepartmentModal';
import { set } from 'lodash';
import { User } from '../../../models';

/* 09-28-23 Arul: Created the  Navbar component globally  */
const NavbarComponent = ({ handleLogin }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [loading, setLoading] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);
  const department: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user: User = useSelector((state: any) => state?.user);
  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, user);
  }, [department, user]);

  // const availableDepartments = useMemo(() => {
  //   try {
  //     if (department?.isSearchedPairedDeps === false) return undefined;
  //     let deps = department?.fetchAllDepartments() ?? [];
  //     if (deps.length === 0) return [];
  //     else {
  //       let filtered = deps.filter((dep) =>
  //         user.pairedDepIDs?.includes(dep.id)
  //       );
  //       return filtered;
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     return [];
  //   }
  // }, [
  //   user,
  //   user?.pairedDepIDs,
  //   department,
  //   department?.isSearchedPairedDeps,
  //   department?.subDeps,
  //   department?.allSubDeps,
  //   department?.parentDep,
  // ]);

  const availableDepartments = useSelector(
    (state: any) => state.userDepartments
  );

  const options = [
    // { value: 'Settings', label: 'Settings' },
    {
      value: 'Switch Department',
      label: 'Switch Department',
      icon: <BiExit size={20} />,
    },
    { value: 'Log Out', label: 'Log Out', icon: <BiExit size={20} /> },
  ];
  const [isNotificationOpen, setIsNotificationOpen] = useState<Boolean>(false);

  const handleNotificationIconClick = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  useEffect(() => {
    const unsubscribe = Hub.listen('datastore', (hubData) => {
      const { event, data } = hubData.payload;
      if (globals.outputDataStoreEvents)
        console.log('DataStore event:', event, data);
      if (event === 'outboxStatus' && data.isEmpty != null) {
        setIsLoaded(!data.isEmpty);
      } else if (event === 'syncQueriesStarted') {
        setIsLoaded(false);
      } else if (event === 'syncQueriesReady') {
      }
    });
    return () => unsubscribe();
  }, []);

  const handleUserInfo = async () => {
    if (globals.debug) console.log('Logging out');
    setLoading('Logging out...');
    try {
      await dispatch<any>(handleLogOut);
      if (globals.debug) console.log('Successfully reste reducers');
      await Auth.signOut();
      if (globals.debug) console.log('Successfully logged out');
      // dispatch<any>(handleUpdateUser(null));
      // await DataStore.stop();
      let promises = [
        DataStore.clear(),
        setTimeout(() => {
          return new Promise((resolve, reject) => resolve('timeout'));
        }, 1000),
      ];
      console.log('Promises: ', promises);
      Promise.race(promises).then((result: any) => {
        if (result === 'timeout') {
          if (globals.debug) console.log('DataStore clear timeout');
        } else {
          if (globals.debug) console.log('DataStore clear success');
        }
      });
      if (globals.debug) console.log('Successfully Cleared DataStore');
      localStorage.clear();
      if (globals.debug) console.log('Successfully cleared local storage');
      navigate(`/login`);

      if (globals.debug) console.log('Successfully logged out');
    } catch (error) {
      if (globals.debug) console.log('error signing out: ', error);
    }
    setLoading(null);
    // dispatch<any>(handleLogOut);
    // dispatch<any>(handleUpdateUser());
    // navigate(`/protocol`);
  };

  const handleShowSidebar = (e: any) => {
    e.stopPropagation();
    setShowSidebar(!showSidebar);
    if (showSidebar) {
      dispatch<any>(handleShowSideBar({ sidebar: false }));
    } else {
      dispatch<any>(handleShowSideBar({ sidebar: true }));
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value === 'Log Out') handleUserInfo();
    else if (value === 'Switch Department') setShowSwitchAccount(true);

    // setAnchorEl(null);
  };

  const switchDepartment = async (dep: DepartmentItem) => {
    try {
      // await department.checkParentDep(useDataStore);
      setLoading('Switching to ' + dep.name + '...');
      const response: Response = await loadDatabase(dep, undefined, true, true);
      dep.calculateAdminLevel(user);
      dep.isSearchedPairedDeps = true;
      if (response.type === ResponseType.Success) {
        localStorage.setItem(
          'loggedInDepartment',
          JSON.stringify({
            id: dep.id,
            parentDepID: dep.parentDepID,
          })
        );
        await dispatch<any>(handleGetDepartment(response.data));
        navigate('/protocol');
        setLoading(null);
      } else {
        console.log('Error switching department: ', response.data);
        alert('Error switching department: ' + response.data);
        setLoading(null);
      }
    } catch (error) {
      console.log('Error switching department: ', error);
      setLoading(null);
    }
  };

  return (
    <div className="fixedNavbar">
      {department && availableDepartments && (
        <SwitchDepartmentModal
          isVisible={showSwitchAccount}
          handleClose={() => setShowSwitchAccount(false)}
          department={department}
          availableDepartments={availableDepartments}
          handleSubmit={(dep) => {
            console.log('Switching to department: ', dep);
            setShowSwitchAccount(false);
            switchDepartment(dep);
          }}
        />
      )}

      <header className="navBarContainer">
        {/* Controls the menu bar in the top right */}
        <div className="logo-container-left">
          <GiHamburgerMenu
            className="menu-bars"
            size={30}
            color="#00534C"
            onClick={handleShowSidebar}
          />
          <div
            className="hover-raise-elevation"
            onClick={(e) => {
              navigate('/protocol');
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              padding: '0.25rem',
              cursor: 'pointer',
              gap: '0.5rem',
              borderRadius: '0.5rem',
            }}
          >
            <img
              className="no-select"
              src={logo}
              alt="Logo"
              style={{
                margin: 0,
                padding: 0,
              }}
            />
          </div>
        </div>
        {/* {department && department.logoVerifiedUrl && (<img src={department.logoVerifiedUrl} alt='Agency Logo' />)} */}
        {/* {department && <div className="DepartmentName">{department.name}</div>} */}
        {/* <img src={logo} alt='Logo' /> The HM logo */}
        <div className="logo-container-right">
          {!isLoggedIn && (
            <Button className="login-button" onClick={handleLogin}>
              Log In
            </Button>
          )}{' '}
          {/* Shows the login button if the user is not logged in*/}
          {isLoggedIn && (
            <div className="logo-container-right">
              {/* <Accountbar> */}
              {/* <DropItem icon={<BiBell />}>
                <NotificationDropDown />
              </DropItem> */}
              {isLoaded && (
                <Tooltip title="Syncing to the Cloud." arrow>
                  <div
                    style={{
                      marginRight: '20px',
                    }}
                  >
                    <ReactLoading
                      className="load"
                      type="spokes"
                      height={24}
                      width={24}
                    />
                  </div>
                </Tooltip>
              )}
              {adminAccess ? (
                <Tooltip
                  title={`${user?.firstName} ${user?.lastName} has admin access to ${department.name}`}
                  arrow
                >
                  <div
                    style={{
                      marginRight: '20px',
                    }}
                  >
                    <FaUserShield size={24} color="#00534C" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${user?.firstName} ${user?.lastName} does NOT have admin access to ${department.name}. Must request admin access to modify departments information.`}
                  arrow
                >
                  <div
                    style={{
                      marginRight: '20px',
                    }}
                  >
                    <FaUserSlash size={24} color="#00534C" />
                  </div>
                </Tooltip>
              )}
              <div
                className="border hover-raise-elevation"
                onClick={(e) => {
                  if (!isOpen) setIsOpen(!isOpen);
                  setAnchorEl(e.currentTarget);
                }}
              >
                {department && department.logoVerifiedUrl && (
                  <img
                    className="department-logo"
                    src={department.logoVerifiedUrl}
                    alt="Agency Logo"
                  />
                )}
                {department && (
                  <div
                    className="departmentName no-select"
                    style={{
                      marginLeft: '6px',
                      marginRight: '6px',
                    }}
                  >
                    {department.name}
                  </div>
                )}
                <FaChevronDown size={12} />
                {!department && (
                  <>
                    <div className="loadingText">Loading</div>
                    <ReactLoading
                      className="load"
                      type="bubbles"
                      height={24}
                      width={24}
                    />
                  </>
                )}
              </div>
              <Menu
                // id="lock-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={() => {
                  setIsOpen(false);
                  setAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                // MenuListProps={{
                //   'aria-labelledby': 'lock-button',
                //   role: 'listbox',
                // }}
              >
                {options.map((option, index) => {
                  return option.value === 'Switch Department' &&
                    availableDepartments &&
                    availableDepartments.length > 1 ? (
                    <Tooltip
                      title="Switch Departments"
                      arrow
                      placement="left"
                      hidden={availableDepartments.length === 0}
                    >
                      <div
                        className="department-menu-container"
                        onClick={(e) => {
                          setIsOpen(false);
                          setAnchorEl(null);

                          handleMenuItemClick(e, option.value);
                        }}
                      >
                        {department && department.logoVerifiedUrl && (
                          <img
                            className="department-logo"
                            src={department.logoVerifiedUrl}
                            alt="Agency Logo"
                          />
                        )}
                        {department && (
                          <div
                            className="departmentName no-select"
                            style={{
                              marginLeft: '6px',
                              marginRight: '6px',
                            }}
                          >
                            {department.name}
                          </div>
                        )}
                        <GoArrowSwitch size={20} />
                      </div>
                    </Tooltip>
                  ) : option.value === 'Switch Department' ? (
                    <div
                      className="department-menu-container disabled"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      {department && department.logoVerifiedUrl && (
                        <img
                          className="department-logo"
                          src={department.logoVerifiedUrl}
                          alt="Agency Logo"
                        />
                      )}
                      {department && (
                        <div
                          className="departmentName no-select"
                          style={{
                            marginLeft: '6px',
                            marginRight: '6px',
                          }}
                        >
                          {department.name}
                        </div>
                      )}
                      {availableDepartments === undefined && (
                        <ReactLoading
                          className="load"
                          type="spokes"
                          height={18}
                          width={18}
                        />
                      )}
                    </div>
                  ) : (
                    <MenuItem
                      key={option.value}
                      // disabled={index === 0}
                      // selected={index === selectedIndex}
                      onClick={(event) =>
                        handleMenuItemClick(event, option.value)
                      }
                      style={{
                        fontFamily: 'Titillium Web, sans-serif',
                        fontWeight: '500',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                      >
                        {option.icon}
                      </div>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Menu>
              {/* {isOpen && (
                <ul className="c-dropdown_list" ref={divRef}>
                  {options.map((option) => {
                    return (
                      <li
                        className="c-multi-select-dropdown__option"
                        key={option.value}
                        onClick={(e) => {
                          if (globals.debug) console.log('Option clicked');
                          e.stopPropagation();
                          handleUserInfo();
                        }}
                      >
                        <div className={`c_dropdown_option`}>
                          {option.icon && option.icon}
                          <div
                            className="option-label no-select"
                            style={{ marginLeft: '10px' }}
                          >
                            {option.label}
                          </div>
                          <span>{labelField(option)}</span> 
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )} */}
              {/* <DropItem
                  icon={
                    
                  }
                >
                  <AccountDropDownMenu onLogOut={handleUserInfo} />
                </DropItem> */}
              {/* </Accountbar> */}
            </div>
          )}
        </div>
        {loading && <Loading type="bubbles" message={loading} />}
      </header>
    </div>
  );
};

export default NavbarComponent;
